import React, { useEffect, useState } from "react"
import { Alert, Card, CardBody, Col, Container, Row, Button } from "reactstrap"
// import pic from "assets/images/home/challenges.png"
import { Link } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import UploadVideoModal from "components/Common/Cards/UploadVideoModal"
import Hero from "pages/Home/components/Hero"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { ResetTopicModal } from "./resetModal"
import { toast } from "react-toastify"
import SubscriptionModal from "components/Common/SubscriptionModal"

export const isWithinLastThreeDaysOfMonth = () => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth() // January is 0

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0)
  const lastDay = lastDayOfMonth.getDate()

  // Determine the range of the last three days
  const startOfRange = lastDay - 2 // 3 days including today
  const endOfRange = lastDay

  // Check if today's date falls within the last three days
  return today.getDate() >= startOfRange && today.getDate() <= endOfRange
}

const DetailChallenges = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { currentUser } = useSelector(state => state.auth)

  const isAdmin = currentUser.userRole == "admin" ? true : false
  const filterFromQuery = queryParams.get("filter")
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [videoData, setVideoData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [rules, setRules] = useState("set")
  const [refresh, setRefresh] = useState(false)
  const [active, setActive] = useState(
    filterFromQuery
      ? filterFromQuery
      : currentUser.userRole == "subscriber"
      ? "all"
      : ""
  )
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const navigate = useNavigate()

  let tabsMenu = [
    { text: "all", link: currentUser.userRole == "subscriber" ? "all" : "" },
    {
      text: "pending",
      link: currentUser.userRole == "subscriber" ? "myPending" : "pending",
    },
    {
      text: "rejected",
      link: currentUser.userRole == "subscriber" ? "myRejected" : "rejected",
    },
    {
      text: "active",
      link: currentUser.userRole == "subscriber" ? "myActive" : "active",
    },
  ]

  useEffect(() => {
    if (filterFromQuery) {
      setActive(filterFromQuery)
      const element = document.getElementById("videos")
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [location.search])
  useEffect(() => {
    navigate(`?filter=${active}`)
  }, [active, navigate])

  const { id } = useParams()
  const isDisabled = isWithinLastThreeDaysOfMonth()
  const getFetch = async (nextPage = 1) => {
    const resp = await get(
      `${
        endpoints.getVideos
      }?page=${nextPage}&limit=4&dataId=${id}&filter=${"topic"}&status=${active}`
    )
    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  useEffect(() => {
    setVideoData([])
    getFetch()
  }, [refresh, active])
  const toggleModal = () => setModalOpen(!modalOpen)
  const toggleModal2 = () => setModalOpen2(!modalOpen2)

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1

    // Update the state
    setLoadMore(prevState => ({
      ...prevState,
      currentPage: nextPage,
    }))

    getFetch(nextPage)
  }
  return (
    <section id="detail-challenge" className="page-content">
      <Container fluid>
        <Row className="row-gap-3">
          <Col sm="12">
            <h2 className="fw-medium font-size-26 text-darkcolor text-uppercase margin-video-vaults">
              Challenge of the month
            </h2>
          </Col>
          <Col xs="12">
            <Card className="rounded-4 p-0">
              <CardBody className="p-0">
                <Row className="px-4 px-md-5 px-xxl-4">
                  <Hero
                    inSection={false}
                    showHeaderText={false}
                    showButton={false}
                    showChallangeText={false}
                    className="  ps-lg-5 rounded-4 "
                    videoClassName=" m-0 top-0 mt-2 mb-0"
                    sketchClassName="m-0 top-0 mt-0 mb-0"
                    rules={rules}
                    setRules={setRules}
                  />
                  <style>{`
                  .mobi{
                  height:97vh;
                  }
                  `}</style>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <div className="d-flex align-items-center justify-content-between text-uppercase">
              <h5>Videos Submitted</h5>
              <div className="d-flex gap-2">
                {isAdmin && (
                  <button
                    className="btn btn-primary-black-outline1 mt-auto text-uppercase  text-darkcolor "
                    onClick={toggleModal2}
                  >
                    reset{" "}
                    <span className="d-none d-md-inline-block">
                      statistics{" "}
                    </span>{" "}
                    <span className="d-none- d-md-inline-block">count</span>
                  </button>
                )}
                <ResetTopicModal isOpen={modalOpen2} toggle={toggleModal2} />
                {!isDisabled && (
                  <div>
                    {true && (
                      <button
                        className="btn btn-primary-green mt-auto text-uppercase pre-color text-white"
                        onClick={toggleModal}
                      >
                        submit{" "}
                        <span className="d-none d-md-inline-block">your </span>{" "}
                        <span className="d-none- d-md-inline-block">video</span>{" "}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex pt-2 pb-2 flex-wrap gap-2 ">
          {tabsMenu.map((el, index) => {
            const buttonClass =
              active === el.link
                ? "text-capitalize py-1 px-3 rounded-pill text-white pre-color"
                : "text-capitalize py-1 px-3 rounded-pill text-dark light-green-bg"

            return (
              <Link
                key={index}
                className={buttonClass}
                onClick={() => setActive(el.link)}
              >
                <span className="font-size-18 barlow-500">{el.text}</span>
              </Link>
            )
          })}
        </div>
        <div className="mt-2 row-gap-3" id="videos">
          {videoData.length > 0 ? (
            <div className="row">
              {videoData.map((card, index) => (
                <Col sm={6} lg={4} xxl={3} className="mb-3" key={index}>
                  <VideoCard data={card} />
                </Col>
              ))}
              {loadMore.lastPage !== loadMore.currentPage && (
                <Row className="justify-content-center mb-5 pb-5">
                  <div className="text-center">
                    <button
                      className="text-uppercase font-size-14 font-weight-400 btn-primary-green btn"
                      onClick={handleLoadMore}
                    >
                      Come on, show us what you’ve got!{" "}
                    </button>
                  </div>
                </Row>
              )}
            </div>
          ) : (
            <Alert className="text-center">No Video Added</Alert>
          )}
        </div>
      </Container>
      {/* Modal */}
      <UploadVideoModal
        isOpen={modalOpen}
        toggle={toggleModal}
        refresh={refresh}
        topicId={id}
        getVideo={setRefresh}
        rules={rules}
      />
      <SubscriptionModal
        show={subscriptionModal}
        onCloseClick={e => {
          setSubscriptionModal(false)
        }}
      />
    </section>
  )
}

export default DetailChallenges
