import React, { useState, useEffect } from "react"
import { StatsCard } from "./components/StatsCard"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { TheDockies } from "./components/TheDockies"
import { Community } from "./components/Community/Community"
import BarChart from "pages/adminDashboard/components/Graphs/BarGraph"
import { UsersCard } from "./components/UsersCard/UsersCard"
import Hero from "pages/Home/components/Hero"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import VideosByDockies from "./components/VideosByDockies/VideosByDockies"
export const AdminDashboard = () => {
  const [data, setData] = useState([])
  const getFetch = async () => {
    try {
      const resp = await get(`${endpoints.usersStats}/stat/admin`)
      if (resp?.data?.success) {
        setData(resp?.data?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    getFetch()
  }, [])
  return (
    <div className="page-content bg-skin">
      <div className="container-fluid">
        <Col xs="12 " className=" pb-3">
          <span className="fw-medium font-size-26 text-darkcolor  ">
            DASHBOARD
          </span>
        </Col>
        <div className="row h-100 ">
          <Col xs="12" className="">
            <Row className="gy-3">
              {/*    <Col xs="12" md="4">
                <StatsCard
                  total={165}
                  done={25}
                  icon={"calendar"}
                  mainText={"TOTAL EVENTS"}
                />
              </Col> */}
              <Col xs="12" md="4">
                <StatsCard
                  total={data?.total?.ACTIVE}
                  done={data?.thisMonth?.ACTIVE}
                  icon={"chart-line"}
                  mainText={"ACTIVE VIDEOS"}
                  status={"active"}
                />
              </Col>
              <Col xs="12" md="4">
                <StatsCard
                  total={data?.total?.REJECTED}
                  done={data?.thisMonth?.REJECTED}
                  icon={"xmark"}
                  mainText={"REJECTED VIDEOS"}
                  status={"rejected"}
                />
              </Col>
              <Col xs="12" md="4">
                <StatsCard
                  total={data?.total?.PENDING}
                  done={data?.thisMonth?.PENDING}
                  icon={"hourglass-half"}
                  mainText={"PENDING VIDEOS"}
                  status={"pending"}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="6" className="mt-3">
            <div className="d-flex flex-column h-100 position-relative">
              <div className="position-absolute">
                <span className="fw-medium font-size-18 text-darkcolor">
                  PLATFORM ACTIVITY
                </span>
              </div>
              <div className="chart-container   h-100 ">
                <BarChart />
              </div>
            </div>
          </Col>

          <Col xs="12" md="6 mt-3">
            <Community />
          </Col>
          <Col xs="12" md="12 mt-3">
            <UsersCard />
          </Col>
          <Col xs="12" md="12 mt-3" className="">
            <Card className="rounded-4 p-0">
              <CardBody className="p-2">
                <Row className=" px-0 py-0">
                  <Hero
                    inSection={false}
                    showHeaderText={false}
                    showButton={true}
                    showChallangeText={false}
                    className="  ps-lg-5 rounded-4 "
                    videoClassName=" m-0 top-0 mt-2 mb-0"
                    sketchClassName=" m-0 top-0 mt-0 mb-0"
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12">
            <VideosByDockies />
          </Col>
          <Col xs="12">
            <TheDockies />
          </Col>
        </div>
      </div>
    </div>
  )
}
