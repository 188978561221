import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import Placeholders from "./Placeholders"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import AnimatedText from "components/animation/AnimatedText/AnimatedText"
import FloatingHeart from "components/animation/FloatingHeart/FloatingHeart"
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown"
import "@leenguyen/react-flip-clock-countdown/dist/index.css"

export default function VideosPostedByDockies() {
  const tabsMenu = [
    "all",
    "dads jokes",
    "new and trending",
    "best seller",
    "independence",
    "heartthrobs",
  ]

  const [videoData, setVideoData] = useState([])
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1780)

  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const getFetch = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}?page=${nextPage}&filter=activeTopic&limit=${
        isWideScreen ? 12 : 8
      }&status=active`
    )

    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1

    setLoadMore(prevState => ({
      ...prevState,
      currentPage: nextPage,
    }))

    getFetch(nextPage)
  }

  useEffect(() => {
    getFetch()
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1780)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const getLastDayAndThreeDaysBefore = () => {
    const date = new Date()
    const currentMonth = date.getMonth()
    const currentYear = date.getFullYear()

    const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate()
    const threeDaysBefore = lastDay - 3

    // Determine if lastDay is 31 and append 'st'
    const lastDayWithSuffix = lastDay === 31 ? `${lastDay}st` : `${lastDay}th`

    // Calculate the target time for three days before the end of the month
    const targetTime =
      new Date(currentYear, currentMonth, lastDay).getTime() -
      3 * 24 * 60 * 60 * 1000 // 3 days in milliseconds

    return {
      lastDay: lastDayWithSuffix,
      threeDaysBefore,
      monthName: monthNames[currentMonth],
      targetTime,
    }
  }

  const { lastDay, threeDaysBefore, monthName, targetTime } =
    getLastDayAndThreeDaysBefore()

  return (
    <Container fluid>
      <Row className="justify-content-center mt-1 mt-md-5">
        <Col xs="12">
          <Row>
            <Col>
              <div className="d-flex flex-column text-center pt-2 pt-md-5 pb-3 px-2">
                <h2 className="text-darkcolor font-size-40 fw-bold ff-barlow text-uppercase mb-0 py-2">
                  <ShuffleText text="voting is open" />
                </h2>

                <h4 className="font-size-15 ff-barlow font-weight-500 text-secondary mt-0 pt-2   ">
                  <AnimatedText
                    className="text-center"
                    el="span"
                    type={"heading2"}
                    text={`Post your joke videos for a chance to win prizes & get featured on Dock Tok @loganlisle Last chance to post is on the ${monthName} ${threeDaysBefore}th! This month’s winners are announced on the ${monthName} ${lastDay}!`}
                  />
                </h4>
                <h4 className="font-size-15 ff-barlow font-weight-600 text-secondary mt-0 pt-2   ">
                  Voting will be close at:
                </h4>
                <FlipClockCountdown
                  to={targetTime}
                  className="justify-content-center flip-clock-responsive pt-0 pt-md-2"
                />
                {/* <h4 className="font-size-15 ff-barlow font-weight-500 text-secondary">
                  Currently cracking up at:
                </h4> */}
                <h2 className="text-darkcolor font-size-40 fw-bold ff-barlow text-uppercase mb-0 py-2">
                  <ShuffleText text=" Currently laughing at:" />
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="row-gap-3 mb-5 pt-2">
            {videoData?.length > 0 ? (
              videoData.map((card, index) => (
                <Col
                  sm={6}
                  lg={3}
                  xxl={isWideScreen ? 2 : 3}
                  className="mb-3"
                  key={`Dockies-${index}`}
                >
                  <VideoCard data={card} />
                </Col>
              ))
            ) : (
              <Col>
                <Placeholders />
              </Col>
            )}
            <span className="text-center fw-medium font-size-20 pt-0 mt-0">
              Share jokes. Swap Smiles. Win Prizes. Get Featured @loganlisle
            </span>
          </Row>

          {loadMore.lastPage !== loadMore.currentPage && (
            <Row className="justify-content-center mb-5 pb-5">
              <div className="text-center">
                <button
                  className="text-uppercase font-size-14 font-weight-400 btn-primary-green btn"
                  onClick={handleLoadMore}
                >
                  Come on, show us what you’ve got!
                </button>
              </div>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  )
}
