import React, { useEffect, useState } from "react"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import { Link } from "react-router-dom"

export const StatsCard = ({ total, done, icon, mainText, status }) => {
  const [data, setData] = useState([])
  const [topic, setTopic] = useState("")
  const contentHandler = async () => {
    const resp = await get(`${endpoints.topic}?page=1&limit=1&filter=featured`)
    if (resp?.data?.success) {
      setTopic(resp?.data?.data[0])
    }
  }
  useEffect(() => {
    contentHandler()
  }, [])
  return (
    <div className="card rounded-4 shadow p-3  h-100">
      <div className="container d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <span className="font-size-56 pre-text fw-medium">{total}</span>
          </div>
          <div className="">
            <Link to={`${topic?`/challenge-detail/${topic?._id}?filter=${status}`:''}`}>
              <button className="btn light-green-bg rounded-circle">
                <i className={`fa fa-${icon} pre-text`} />
              </button>
            </Link>
          </div>
        </div>
        <div>
          <span className="font-size-18 muted-text fw-medium">{mainText}</span>
        </div>
        <div className="d-flex flex-column mt-4">
          <div className="text-end">
            <span className="secondary-white font-size-14">
              {done} This Month
            </span>
          </div>
          <div className={` progress `}>
            <div
              role="progressbar"
              style={{
                backgroundColor: "#737373",
                width: `${(done / total) * 100}%`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
