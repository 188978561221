import React, { useState, useEffect } from "react"
import upload from "assets/images/image upload.png"
import {
  Alert,
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import Switch from "react-switch"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { post, get, put } from "api/axios"
import endpoints from "api/endpoints"
import pic1 from "assets/images/delete.svg"
import { Bounce, toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { stateFromHTML } from "draft-js-import-html"
import TagsInput from "react-tagsinput"

const EditVideo = () => {
  const [videoUrl, setVideoUrl] = useState(null)
  const [newVideoUpload, setNewVideoUpload] = useState(false)
  const [mediaType, setMediaType] = useState(null)
  const navigate = useNavigate()
  const { id = null } = useParams()
  const [content, setContent] = useState(null)
  const [awarded, setAwarded] = useState(null)
  const handleVideoUpload = (setFieldValue, e) => {
    const file = e.target.files[0]
    if (file.type.includes("image")) {
      setMediaType("image")
    } else if (file.type.includes("video")) {
      setMediaType("video")
    } else {
      setMediaType("other")
    }
    setFieldValue("file", file)
    if (file) {
      setVideoUrl(URL.createObjectURL(file))
      setNewVideoUpload(true)
    }
  }
  const handleVideoRemove = () => {
    setVideoUrl(null)
    setNewVideoUpload(false)
  }
  const [video, setVideo] = useState({})
  const getVideoContent = async () => {
    const resp = await get(`${endpoints.getVideos}/${id}`)
    if (resp?.data?.success) {
      setVideo(resp?.data?.data[0])
      setMediaType("video")
      getVideoAward(
        resp?.data?.data[0]?._id,
        resp?.data?.data[0]?.createdBy?._id
      )
      setVideoUrl(
        `${process.env.REACT_APP_S3_BUCKET}${
          resp?.data?.data?.at(0)?.videoDetail
        }`
      )
      const htmlContent = resp?.data?.data?.at(0)?.description
      const contentState = stateFromHTML(htmlContent)
      setContent(EditorState.createWithContent(contentState))
    }
  }
  const getVideoAward = async (videoId, userId) => {
    const resp = await get(`${endpoints.getVideos}/winner/${videoId}/${userId}`)
    if (resp?.data?.success) {
      setAwarded(resp?.data?.data)
    }
  }

  useEffect(() => {
    id != null && getVideoContent()
  }, [id])
  return (
    <div className="page-content container-fluid">
      <Formik
        enableReinitialize
        initialValues={{
          title: video?.title || "",
          description: video?.description || "",
          tags: video?.tags || [],
        }}
        validationSchema={Yup.object({
          title: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const response = await put(`${endpoints.getVideos}/${id}`, values)
          setSubmitting(false)

          if (!values.file && !videoUrl) {
            toast.error("Please Upload Video", {
              position: "bottom-center",
              theme: "colored",
              transition: Bounce,
            })
            setSubmitting(false)
            return
          }
          if (response.status === 200 || response.status === 202) {
            id != null
              ? toast.success("Video Updated Successfully", {
                  position: "bottom-center",
                  theme: "colored",
                  transition: Bounce,
                })
              : toast.success("Video Added Successfully", {
                  position: "bottom-center",
                  theme: "colored",
                  transition: Bounce,
                })
            handleVideoRemove()
            setContent(null)
            resetForm()
            navigate(-1)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          formProps,
          setValues,
          /* and other goodies */
        }) => (
          <Form method="post" className="wysiwyg-custom">
            <div className="card p-3 m-2 rounded-3">
              <div className="d-flex flex-column gap-3">
                <span className="font-size-18 fw-medium text-darkcolor ">
                  VIDEO{" "}
                </span>
                <div>
                  <FormGroup>
                    <Label
                      for="videoUpload"
                      className={`upload-label d-block w-100 text-center  ${
                        videoUrl ? "d-none" : ""
                      }`}
                    >
                      <div className="btn bg-light p-4 rounded-4 w-auto text-center">
                        <div className="d-flex flex-column ">
                          <div className="d-flex justify-content-center">
                            <img
                              src={upload}
                              alt="upload"
                              className="img-fluid w-50"
                            />
                          </div>
                          <span className="font-size-12 secondary-white">
                            Upload Video
                          </span>
                        </div>
                      </div>
                    </Label>
                    <Input
                      type="file"
                      accept="video/*"
                      name="file"
                      id="videoUpload"
                      className="d-none "
                      onChange={e => handleVideoUpload(setFieldValue, e)}
                    />
                    {videoUrl && mediaType === "video" ? (
                      <div className="d-flex align-items-start position-relative  justify-content-center">
                        <video
                          className="uploaded-video rounded-4"
                          controls
                          style={{ width: "100%" }}
                        >
                          <source src={videoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <Button
                          className="delete-button position-absolute rounded-circle bg-white"
                          onClick={handleVideoRemove}
                        >
                          <img src={pic1} alt="" />
                        </Button>
                      </div>
                    ) : (
                      videoUrl && (
                        <div className="d-flex align-items-start position-relative  justify-content-center">
                          <p type="invalid" className="text-danger">
                            Please Upload Video File
                            <Button
                              className="delete-button rounded-circle bg-white"
                              onClick={handleVideoRemove}
                            >
                              <img src={pic1} alt="" />
                            </Button>
                          </p>
                        </div>
                      )
                    )}
                  </FormGroup>
                </div>
                <div>
                  <span className="font-size-18 text-uppercase fw-medium text-darkcolor">
                    other details
                  </span>
                  <FormGroup>
                    <Label for="exampleEmail" className="text-uppercase">
                      Video Title
                    </Label>
                    <Input
                      type="text"
                      name="title"
                      placeholder="Enter Video Title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title || ""}
                      invalid={touched.title && errors.title ? true : false}
                      className="mb-2"
                    />
                    {touched.title && errors.title ? (
                      <FormFeedback type="invalid">{errors.title}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail" className="text-uppercase">
                      Video Tags
                    </Label>
                    <TagsInput
                      className={"form-control mb-2"}
                      value={values.tags || []}
                      onChange={e => {
                        setFieldValue("tags", e)
                      }}
                    />
                    {touched.tagss && errors.tagss ? (
                      <FormFeedback type="invalid">{errors.tagss}</FormFeedback>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleEmail" className="text-uppercase">
                      Description
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="videoDescription"
                      placeholder="Not more than 500 words"
                      style={{ minHeight: "200px" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description || ""}
                      invalid={
                        touched.description && errors.description ? true : false
                      }
                    />{" "}
                    {touched.description && errors.description ? (
                      <FormFeedback type="invalid">
                        {errors.description}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>

                  {awarded?.award == undefined ? (
                    <Button
                      className="btn btn-primary-green"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Alert color="danger">
                      You cannot edit this video because your video has been
                      selected for an award.
                    </Alert>
                  )}
                  {/* <Button
                    className="btn btn-primary-green"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button> */}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditVideo
