import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import { useSelector } from "react-redux"
import WinnerFromLastMonth from "../Home/components/WinnerFromLastMonth"

export default function AlVideosAward() {
  const [active, setActive] = useState("all")
  const location = useLocation()
  const queryString = location.search
  const { currentUser } = useSelector(state => state.auth)

  // You can use URLSearchParams to parse the query string
  const params = new URLSearchParams(queryString)

  const [videoData, setVideoData] = useState([])
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const getFetch = async (nextPage = 1) => {
    const submitted = params.get("myvideos")
    const resp = await get(
      `${endpoints.getVideos}?page=${nextPage}&limit=4${
        submitted == "true" ? `&dataId=${currentUser?._id}&filter=user` : ""
      }`
    )
    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1
    getFetch(nextPage)
  }

  useEffect(() => {
    getFetch()
  }, [])

  return (
    <section id="user-profile" className="page-content bg-light">
      <Container fluid className=""></Container>

      <WinnerFromLastMonth loadmore={true} />
    </section>
  )
}
