import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Card, Col, Modal, ModalBody, Row } from "reactstrap"
import { get, post } from "../../api/axios"
import endpoints from "../../api/endpoints"
import { Bounce, toast } from "react-toastify"
import Loader from "./Loader/Loader"
import CryptoJS from "crypto-js"

const SubscriptionModal = ({ show, onCloseClick }) => {
  const credType = useSelector(state => state.auth.loginType)
  const [subscriptionList, setSubscriptionList] = useState([])
  const navigate = useNavigate()
  const [loader, setLoading] = useState(true)
  const [subscript, setSubscript] = useState(false)
  const [fbSubscriber, setFbSubscriber] = useState([])
  const logoutUserFacebook = () => {
    onCloseClick()
    toast.error(
      "Please subsrcide the facebook page https://www.facebook.com/logandocktok",
      {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      }
    )
    localStorage.clear()
    navigate("/login")
  }
  const logoutUserInstagram = () => {
    onCloseClick()
    toast.error(
      "Please subsrcide the Instagram page https://www.instagram.com/logandocktok",
      {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      }
    )
    localStorage.clear()
    navigate("/login")
  }
  const logoutUserTiktok = () => {
    onCloseClick()
    toast.error("Please subsrcide the Tiktok page", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
    localStorage.clear()
    navigate("/login")
  }
  const getSubscriptionList = async () => {
    let resp
    if (credType == "google") {
      resp = await get(endpoints.youTubeSubscriptionList)
      if (resp.response) {
        setSubscript(true)
        setSubscriptionList(resp?.response?.data?.data?.items)
      } else {
        setSubscriptionList(resp?.data?.data?.items)
      }
      setLoading(false)
    } else if (credType == "facebook") {
      resp = await get(endpoints.facebookLikesList)
      setSubscriptionList(resp?.data?.data)
      const hash = CryptoJS.MD5("docktok@logan_facebook").toString()
      const linkSource = localStorage.getItem("linkSource")
      console.log(
        localStorage.getItem("groupCode") &&
          hash == localStorage.getItem("groupCode") &&
          linkSource &&
          linkSource.includes("facebook") &&
          parseInt(localStorage.getItem("groupTime")) > Date.now(),
        localStorage.getItem("groupCode"),
        "local group code",
        hash == localStorage.getItem("groupCode"),
        "Match Hash group code",
        hash,
        "groupCode Hash",
        linkSource,
        "Link Source",
        linkSource.includes("facebook"),
        "Link Source match face",
        parseInt(localStorage.getItem("groupTime")) > Date.now(),
        "datacheck",
        parseInt(localStorage.getItem("groupTime"))
      )
      if (
        localStorage.getItem("groupCode") &&
        hash == localStorage.getItem("groupCode") &&
        linkSource &&
        linkSource.includes("facebook") &&
        parseInt(localStorage.getItem("groupTime")) > Date.now()
      ) {
        console.log("resp?.data?.data", resp?.data?.data)
        if (resp?.data?.data == null) {
          logoutUserFacebook()
        } else {
          onCloseClick()
        }
        setLoading(false)
      } else if (
        parseInt(localStorage.getItem("groupTime")) < Date.now() ||
        parseInt(localStorage.getItem("groupTime")) == 0 ||
        localStorage.getItem("groupTime") == null
      ) {
        let checkSubscribe = await get(endpoints.facebookBotGroupMember)
        if (checkSubscribe.status === 200) {
          let profiles = checkSubscribe.data.data.bot.data
          const smallestDifferenceIndex = profiles.reduce(
            (minIndex, profile, index, arr) =>
              profile.difference < arr[minIndex].difference ? index : minIndex,
            0
          )

          if (profiles[smallestDifferenceIndex].difference <= 10) {
            onCloseClick()
            setLoading(false)
          } else {
            logoutUserFacebook()
          }
          setFbSubscriber(checkSubscribe.data)
        } else {
          logoutUserFacebook()
          setLoading(false)
          console.error(
            "Failed to check subscription. Status:",
            checkSubscribe.status
          )
        }
        // onCloseClick()
        setLoading(true)
      } else {
        setLoading(false)
        logoutUserFacebook()
      }
    } else if (credType == "instagram") {
      const hash = CryptoJS.MD5("docktok@logan_instagram").toString()
      const linkSource = localStorage.getItem("linkSource")
      if (
        localStorage.getItem("groupCode") &&
        hash == localStorage.getItem("groupCode") &&
        linkSource &&
        linkSource.includes("instagram") &&
        parseInt(localStorage.getItem("groupTime")) > Date.now()
      ) {
        onCloseClick()
      } else {
        logoutUserInstagram()
      }
    } else if (credType == "tiktok") {
      const hash = CryptoJS.MD5("docktok@logan_tiktok").toString()
      const linkSource = localStorage.getItem("linkSource")
      if (
        localStorage.getItem("groupCode") &&
        hash == localStorage.getItem("groupCode") &&
        linkSource &&
        linkSource.includes("tiktok") &&
        parseInt(localStorage.getItem("groupTime")) > Date.now()
      ) {
        onCloseClick()
      } else {
        logoutUserTiktok()
      }
    } else {
      onCloseClick()
    }
  }

  useEffect(() => {
    getSubscriptionList()
  }, [])
  const channelSubscribe = async () => {
    let resp = await post(endpoints.youTubeSubscriptionList)
    if (resp.status == 200) {
      setSubscript(false)
      onCloseClick()
    }
  }
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      backdrop="static"
      keyboard={false}
    >
      <ModalBody className="py-3 px-3">
        <Row>
          <Col lg={12}>
            {!loader &&
              subscriptionList?.length > 0 &&
              credType == "google" &&
              subscriptionList.map((item, index) => {
                return (
                  <div
                    className="d-flex align-items-center justify-content-around"
                    key={index}
                  >
                    <div>
                      <img
                        src={`${item?.snippet?.thumbnails?.default?.url}`}
                        alt={`${item?.snippet.title}`}
                        width="100"
                        height="100"
                        className="rounded-2"
                      />
                    </div>
                    <div>
                      <h3>{item?.snippet.title}</h3>
                      <p>{item?.snippet.description}</p>
                    </div>
                    {subscript && (
                      <>
                        <div>
                          <button
                            type="button"
                            className="btn btn-danger "
                            onClick={channelSubscribe}
                          >
                            Subscribe
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
            {!loader &&
              subscriptionList?.length > 0 &&
              credType == "facebook" &&
              subscriptionList.map((item, index) => {
                return (
                  <div className="d-flex" key={index}>
                    <div>
                      <h3>{item?.id}</h3>
                      <p>{item?.name}</p>
                      <p>
                        <small>{item?.created_time}</small>
                      </p>
                    </div>
                  </div>
                )
              })}

            {!loader && subscriptionList?.length <= 0 && (
              <p>No Subscription Found!</p>
            )}
            {loader && <Loader />}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={e => {
                  onCloseClick()
                  if (subscript) {
                    localStorage.clear()
                    navigate("/login")
                  }
                }}
              >
                Cancel
              </button>
              {/* toast mesg */}
              {/* <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={e => {
                  toast.error("You Must Subscribe to Continue!", {
                    position: "bottom-center",
                    theme: "colored",
                    transition: Bounce,
                  })

                  onCloseClick()
                  if (subscript) {
                    localStorage.clear()
                    navigate("/login")
                  }
                }}
              >
                Cancel
              </button> */}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

SubscriptionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default SubscriptionModal
