import React from "react"
import { Link } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"

const ChallengesCard = ({ data,showView = false }) => {
  return (
    <Card className="bg-skin p-4 rounded-4">
      <Row>
        <Col sm="5">
          <img
            src={data?.pic}
            alt="challenges-card"
            className="h-100 w-100 rounded-4"
          />
        </Col>
        <Col sm="7">
          <div className="d-flex flex-column gap-2 h-100">
            <span>
              <span className="text-primary">Created on: </span>
              May 11, 2024
            </span>
            <span className="font-size-18 text-uppercase text-color-success ">
              Event Title here
            </span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in...
            </span>
            <span>
              <span className="text-primary">Category:</span> Dad’s Jokes
            </span>
            <span>
              <span className="text-primary">Ending on:</span> May 28, 2024
            </span>
            <div className="d-flex gap-2 flex-wrap justify-content-between">
              <span>#dadsjokes</span>
              <span>#dadsjokes</span>
              <span>#dadsjokes</span>
              <span>#dadsjokes</span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Link
                type="button"
                className="btn btn-outline-primary mt-auto text-uppercase"
              >
                submit video
              </Link>
              {showView && (
                <Link
                  type="button"
                  className="btn btn-outline-primary mt-auto text-uppercase"
                  id={`viewtooltip`}
                  to={"/challenge-detail/1"}
                >
                  <i className="fa-light fa-eye font-size-18" />
                </Link>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default ChallengesCard
