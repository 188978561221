import React, { useCallback, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap"
import { debounce } from "lodash"
import CheckImg from "assets/images/check.svg"
import play from "assets/images/home/play.svg"
import { Link } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import TimeAgo from "react-timeago"
import { useSelector } from "react-redux"

export default function AllBlogs() {
  const [blogs, setBlogs] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const { currentUser } = useSelector(state => state.auth)
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  )
  const [searchItem, setSearchItem] = useState(
    localStorage.getItem("searchItem") || ""
  )
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem("filter")) || {
      action: "featured",
      text: "Featured",
    }
  )

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  function getText(html) {
    const divContainer = document.createElement("div")
    divContainer.innerHTML = html
    return divContainer.textContent || divContainer.innerText || ""
  }

  const contentHandler = useCallback(async () => {
    const resp = await get(
      `${endpoints.blog}?page=${page}&limit=12&search=${searchItem}&filter=${filter?.action}`
    )
    if (resp?.data?.success) {
      setBlogs(prev => [...prev, ...resp?.data?.data])
      setTotalPage(resp?.data?.totalPages)
    }
  }, [page, filter, searchItem])

  const debouncedSearch = useCallback(
    debounce(term => {
      setBlogs([])
      setSearchItem(term)
      setPage(1)
      localStorage.setItem("searchItem", term) // Save search term in localStorage
    }, 500), // 500ms delay
    []
  )

  const handleChange = e => {
    const { value } = e.target
    setSearchTerm(value)
    debouncedSearch(value)
    localStorage.setItem("searchTerm", value) // Save search term input in localStorage
  }

  useEffect(() => {
    contentHandler()
  }, [page, filter, searchItem])

  const handleFilterChange = newFilter => {
    setFilter(newFilter)
    setBlogs([])
    setPage(1)
    localStorage.setItem("filter", JSON.stringify(newFilter)) // Save filter in localStorage
  }

  return (
    <>
      <section id="user-profile" className="page-content bg-skin">
        <Container fluid className="margin-video-vaults">
          <h2 className="d-flex flex-column flex-md-row text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase d-flex justify-content-between">
            <div className="d-flex justify-content-start align-items-center">
              How It Works
              <span className="p-2 mx-3 filter-btn d-flex justify-content-between align-items-center">
                <Input
                  type="text"
                  name="search"
                  className="hover-input"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleChange}
                />
              </span>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3 mt-md-0">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                direction={"down"}
              >
                <DropdownToggle
                  caret
                  className="d-flex justify-content-between align-items-center filter-btn"
                >
                  <span>{filter.text}</span>
                  <img src={CheckImg} className="px-2" alt="check icon" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      handleFilterChange({
                        action: "featured",
                        text: "Featured",
                      })
                    }
                  >
                    Featured
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleFilterChange({
                        action: "lastMonth",
                        text: "Last Month",
                      })
                    }
                  >
                    Last Month
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleFilterChange({
                        action: "threeMonth",
                        text: "Last Three Month",
                      })
                    }
                  >
                    Last Three Month
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleFilterChange({
                        action: "lastYear",
                        text: "Last Year",
                      })
                    }
                  >
                    Last Year
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleFilterChange({ action: "active", text: "Older" })
                    }
                  >
                    Older
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {currentUser.userRole === "admin" && (
                <Link to="/add" className="btn btn-primary-green ms-3">
                  ADD
                </Link>
              )}
            </div>
          </h2>

          <Row className="col-sm-center">
            {blogs.length > 0 ? (
              blogs.map((blog, index) => (
                <Col key={index} xs="12" sm="6" md="4" lg="3" className="my-2">
                  <div className="d-flex flex-column gap-2">
                    <Link to={`/how-it-works-details/${blog?._id}`}>
                      <div className="video-container-blog rounded-3 d-flex position-relative">
                        {blog?.videoDetail?.thumbnail?.path ? (
                          <img
                            src={`${process.env.REACT_APP_S3_BUCKET}${blog?.videoDetail?.thumbnail?.path}`}
                            alt="blog thumbnail"
                            className="mb-2 rounded w-100 object-fit-cover"
                          />
                        ) : (
                          <video className="rounded w-100 h-100" />
                        )}
                        {blog?.videoDetail?.video && (
                          <button className="btn position-absolute top-50 start-50 translate-middle">
                            <img src={play} alt="Play" />
                          </button>
                        )}
                      </div>
                    </Link>
                    <div className="d-flex flex-column">
                      <span
                        className="text-uppercase fw-semibold font-size-18 ff-barlow text-darkcolor"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {blog.title}
                      </span>
                      <span className="font-size-16 fw-normal text-secondary">
                        <TimeAgo date={blog?.createdAt} />
                      </span>
                    </div>
                    <span className="font-size-14 text-secondary">
                      {getText(blog.content).slice(0, 30)}...
                    </span>
                    <Link to={`/how-it-works-details/${blog?._id}`}>
                      <span className="cursor-pointer pre-text font-size-14 fw-medium mt-0">
                        VIEW DETAILS
                      </span>
                    </Link>
                  </div>
                </Col>
              ))
            ) : (
              <p className="text-secondary font-size-14 text-center">
                No Results Found!
              </p>
            )}
            {totalPage > page && (
              <div className="text-center mt-5">
                <button
                  className="text-uppercase font-size-14 font-weight-400 btn-primary-green btn"
                  onClick={() => setPage(prev => prev + 1)}
                >
                  Load More
                </button>
              </div>
            )}
          </Row>
        </Container>
      </section>
    </>
  )
}
