import React from "react"

//Social Media Imports
import { useGoogleLogin } from "@react-oauth/google"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import tiktokIcon from "../../../assets/images/tiktok.png"
import { facebook, google, instagram, tiktok } from "../../../config"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setLogin } from "../../../store/slices/authSlice"
import { Bounce, toast } from "react-toastify"
import { get, post } from "../../../api/axios"
import endpoints from "../../../api/endpoints"
import { InstagramLogin } from "@amraneze/react-instagram-login"
import { popup } from "leaflet"

const SocialLink = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl",
    onSuccess: async codeResponse => {
      try {
        const tokens = await post(endpoints.googleAuthentication, {
          code: codeResponse.code,
        })
      } catch (error) {}
    },
    onError: errorResponse => console.error(errorResponse),
  })

  //handleInstagramLoginResponse
  const instagramResponse = async response => {
    const tokens = await post(endpoints.instagramExchangeCode, {
      code: response,
    })
  }

  //handleFacebookLoginResponse
  const facebookResponse = async response => {
    const tokens = await post(endpoints.facebookAuthentication, {
      ...response,
    })
    userData(tokens?.data, "facebook")
  }
  //handleTiktokLoginResponse
  const handleTiktokLogin = async () => {
    const url = await get(endpoints.tiktokAuthentication)
    if (url?.data?.success) {
      window.location.href = url?.data?.data
    }
  }

  const userData = (tokens, credType) => {
    localStorage.setItem("accessToken", tokens?.token)
    dispatch(
      setLogin({
        currentUser: tokens?.data?.user,
        jid: tokens?.token,
        isAuth: true,
        loginType: credType,
      })
    )

    navigate("/dashboard")
    toast.success("Login Successful", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
  }
  return (
    <ul className="list-inline">
      {facebook.APP_ID !== "" && (
        <li className="list-inline-item">
          <FacebookLogin
            appId={facebook.APP_ID}
            autoLoad={false}
            scope="email,user_posts,user_friends, user_birthday"
            callback={facebookResponse}
            fields="id,name,email,picture"
            render={renderProps => (
              <Link
                to="#"
                className="social-list-item bg-facebook text-white border-primary"
                onClick={renderProps.onClick}
              >
                <i className="fa-brands fa-facebook-f" />
              </Link>
            )}
          />
        </li>
      )}
      {instagram.APP_ID !== "" && (
        <li className="list-inline-item">
          <InstagramLogin
            clientId={instagram.APP_ID}
            // autoLoad={false}
            // cssClass="instaIcon"
            redirectUri="https://f8f3-101-53-234-95.ngrok-free.app/"
            scope="user_profile,user_media,instagram_graph_user_profile"
            onSuccess={instagramResponse}
            onFailure={instagramResponse}
            cssClass="m-0 p-0 border-0 bg-transparent"
          >
            <span className="social-list-item bg-instagram">
              <i className="fa-brands fa-instagram text-white" />
            </span>
          </InstagramLogin>
        </li>
      )}
      {tiktok.APP_ID !== "" && (
        <li className="list-inline-item">
          <Link
            to="#"
            className="social-list-item bg-dark text-white border-dark"
            onClick={handleTiktokLogin}
          >
            <i className="fa-brands fa-tiktok" />
          </Link>
        </li>
      )}
      {google.CLIENT_ID !== "" && (
        <li className="list-inline-item">
          <Link
            to="#"
            className="social-list-item bg-danger text-white border-danger"
            onClick={googleLogin}
          >
            <i className="fa-brands fa-youtube" />
          </Link>
        </li>
      )}
    </ul>
  )
}

export default SocialLink
