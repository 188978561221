import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Row,
  Col,
  // CardBody,
  // Card,
  Alert,
  // Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

// Redux
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logo from "../../assets/images/logo-dark.png"
import logoLg from "assets/images/header-logo-lg.svg"

import SocialLink from "./SocialLink/SocialLink"
import { post } from "../../api/axios"
import endpoints from "../../api/endpoints"
import { setLogin } from "../../store/slices/authSlice"
import { useDispatch } from "react-redux"
import { Bounce, toast } from "react-toastify"

const Login = props => {
  document.title = " Login | Dock Tok"

  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const queryString = location.search
  const params = new URLSearchParams(queryString)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      // dispatch(loginUser(values, props.router.navigate))
      const response = await post(endpoints.login, values)
      if (response?.success) {
        const fcmToke = await localStorage.getItem("fcm")
        const fcmData = await post(endpoints.fcm, { fcmToken: fcmToke })

        dispatch(
          setLogin({
            currentUser: response?.data?.data,
            jid: response?.data?.token,
            isAuth: true,
            loginType: "credentials",
          })
        )
        navigate("/dashboard")
        toast.success("Login Successful", {
          position: "bottom-center",
          theme: "colored",
          transition: Bounce,
        })
        localStorage.setItem("accessToken", response?.data?.token)
      }
    },
  })

  const error = null

  useEffect(() => {
    document.body.className = "authentication-bg"

    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  useEffect(() => {
    const referrer = document.referrer
    if (params.get("groupCode")) {
      localStorage.removeItem("groupCode")
      localStorage.setItem("referrer", referrer)
      localStorage.setItem("groupCode", params.get("codeHash"))
      localStorage.setItem("groupTime", Date.now())
    }
  }, [])
  return (
    <Row className="px-md-5">
      <Col lg={12}>
        <div className="text-center">
          <Link to="/" className="mb-sm-3 mt-5 d-block auth-logo">
            <img src={logo} alt="" height="80" className="logo logo-light" />
            <img src={logoLg} alt="" height="80" className="logo logo-dark" />
          </Link>
        </div>
      </Col>
      <Col xs="12" lg="12">
        <div className="text-center mt-2">
          <h5 className="text-primary text-uppercase">Signin with facebook</h5>
          <p className="text-muted">
            Enter the credentials below to get started.
          </p>
        </div>
        <div className="p-2 mt-4 ">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            {error ? <Alert color="danger">{error}</Alert> : null}

            <div className="mb-3">
              {/* <Label className="form-label">Email</Label> */}
              <Input
                name="email"
                className="form-control"
                placeholder="Username/Email"
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              {/* <Label className="form-label">Password</Label> */}
              <Input
                name="password"
                value={validation.values.password || ""}
                type="password"
                placeholder="Enter Your Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="customControlInline"
              />
              <label className="form-check-label" htmlFor="customControlInline">
                Remember me
              </label>
              <div className="float-end">
                <Link to="/forgot-password" className="text-danger">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div className="mt-3">
              <button
                className="btn btn-primary w-100 waves-effect waves-light"
                type="submit"
              >
                Log In
              </button>
            </div>

            <div className="mt-4 text-center">
              <h5 className="font-size-14 mb-3 text-uppercase">
                or continue with
              </h5>

              <SocialLink />
            </div>

            <div className="mt-3 text-center"></div>
          </Form>
        </div>
      </Col>
    </Row>
  )
}

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
