import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="landing-bg footer mt-auto d-lg-block d-none">
        {" "}
        <div className="d-flex align-items-center justify-content-center py-2 landing-copyrights text-uppercase">
          <span className="text-center text-white">
            © 2024 Dock Tok, Inc. All Rights Reserved.
          </span>
        </div>
      </footer>

      {/* 
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Dock Tok.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block text-end">
                Design & Develop <i className="mdi mdi-heart text-danger"></i>{" "}
                by PixarsArt
              </div>
            </Col>
          </Row>
        </Container>
      
      </footer>
     */}
    </React.Fragment>
  )
}

export default Footer
