import {} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Container, Input } from "reactstrap"
import { get, put } from "api/axios"
import endpoints from "api/endpoints"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup" // For validation if needed
import { toast } from "react-toastify"

export const EditStaff = () => {
  const { id } = useParams()
  const [data, setData] = useState()

  const [initialValues, setInitialValues] = useState()
  const [validationSchema, setValidationSchema] = useState()
  const navigate = useNavigate()
  const handleDiscard = () => {
    navigate(-1)
  }
  const handleSubmit = async values => {
    const resp = await put(`${endpoints.staff}/${id}`, values)
    if (resp?.data?.success) {
      toast.success("User Updated Successfully")

      navigate("/staff")
    }
  }

  const getFetch = async () => {
    const resp = await get(`${endpoints.staff}/${id}`)
    if (resp?.data?.success) {
      setData(resp?.data?.data)
      setInitialValues({
        email: resp?.data?.data?.email,
        firstName: resp?.data?.data?.firstName,
        lastName: resp?.data?.data?.lastName,
        userRole: resp?.data?.data?.additionalInfo?.assignRole,
      })
      setValidationSchema(
        Yup.object({
          existingUser: Yup.string(),
          email: Yup.string().email("Invalid email address"),
          firstName: Yup.string().required(),
          lastName: Yup.string().required(),
          role: Yup.string(),
        })
      )
    }
  }

  useEffect(() => {
    if (id) {
      getFetch()
    }
  }, [])

  return (
    <section className="page-content">
      <Container fluid>
        <div>
          <span className="font-size-26 text-darkcolor text-uppercase fw-medium">
            Edit Staff
          </span>
        </div>
        {initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <div className="card p-3 shadow rounded-4">
                  <div className="row">
                    <div className="col-6 g-3">
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values.firstName}
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-6 g-3">
                      <Input
                        type="text"
                        onChange={handleChange}
                        value={values.lastName}
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>

                    <div className="col-6 g-3">
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="User Email"
                        value={values.email}
                      />
                    </div>

                    <div className="col-6 g-3">
                      <Field
                        as="select"
                        name="role"
                        className="form-select"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option defaultValue>User Role</option>
                        <option value="editor">Editor</option>
                        <option value="viewer">Viewer</option>
                        <option value="manager">Manager</option>
                      </Field>
                    </div>
                  </div>
                  <div className="d-flex gap-3 justify-content-end mt-3">
                    <button
                      type="submit"
                      /*  onClick={handleSubmit} */
                      className="btn btn-primary-green text-uppercase"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary text-uppercase"
                      onClick={handleDiscard}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Container>{" "}
    </section>
  )
}
