import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Alert, Col, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
import VideoCard from "components/Common/Cards/VideoCard"

const UserAllVideo = ({ userAllVideo, userId, videoCount }) => {
  const [videoData, setVideoData] = useState([])

  useEffect(() => {
    setVideoData(userAllVideo.data || [])
  }, [userAllVideo])

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-uppercase font-size-26">
          all videos{" "}
          <span className="pre-text font-size-18">({videoCount})</span>
        </h4>
        <Link to={`/all-videos/${userId}?myvideos=true`}>
          <span className="pre-text text-uppercase ">View All</span>
        </Link>
      </div>

      <SimpleBar style={{ maxHeight: "532px" }} className="mt-3 rounded-4">
        <Row className="px-3">
          {videoData.length > 0 ? (
            videoData.map((card, index) => (
              <Col md={4} sm={6} lg={6} xl={4} xxl={4} className="" key={index}>
                <VideoCard
                  data={card}
                  imageHeight={480}
                  showVideoVoted={false}
                />
              </Col>
            ))
          ) : (
            <Alert className="text-center">No Video Added</Alert>
          )}
        </Row>
      </SimpleBar>
    </div>
  )
}

export default UserAllVideo
