import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Label } from "reactstrap"
import { get } from "api/axios"
import endpoints from "api/endpoints"
const BarChart = () => {
  const [data, setData] = useState([])
  const getData = async () => {
    const resp = await get(`${endpoints.usersStats}/weeklylogs`)
    if (resp?.data?.success) {
      setData(resp?.data?.data)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f"],
    grid: {
      borderColor: "#f1f1f1",
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      categories: ["M", "T", "W", "T", "F", "S", "S"],
    },
  }

  return (
    <>
      {data.length > 0 && (
        <ReactApexChart
          options={options}
          series={[
            {
              data: data.map(_ => {
                return _.count
              }),
            },
          ]}
          type="bar"
          width={"100%"}
          height={"100%"}
        />
      )}
    </>
  )
}

export default BarChart
