import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Row, Col, CardBody, Card } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import SocialLinkUpdate from "./SocialLink/SocialLinkUpdate"

import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// import images
import logo from "assets/images/logo-dark.png"
import logolight from "assets/images/logo-light.png"
import logoLg from "assets/images/header-logo-lg.svg"

import SocialLink from "./SocialLink/SocialLink"

const Register = props => {
  document.title = " Register | Dock Tok"
  const navigate = useNavigate()
  const location = useLocation()
  const queryString = location.search
  const params = new URLSearchParams(queryString)
  const handleClick = () => {
    navigate("/admin")
  }

  // const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // dispatch(registerUser(values))
    },
  })
  const user = null
  const registrationError = null

  useEffect(() => {
    // dispatch(apiError(""))
    document.body.className = "authentication-bg"
    // remove classname when component will unmount

    if (params.get("groupCode")) {
      const referrer = document.referrer

      // 66530920651535275445
      localStorage.removeItem("groupCode")
      localStorage.removeItem("groupTime")
      localStorage.setItem("groupCode", params.get("groupCode"))
      localStorage.setItem("linkSource", referrer)
      localStorage.setItem("groupTime", Date.now() + 30 * 60 * 1000)
    }
    return function cleanup() {
      document.body.className = ""
    }
  }, [])

  return (
    <Row className="row-gap-2 row-gap-md-4 mx-md-5 mx-lg-0 px-md-5 my-5 py-0 login-scroll">
      <Col lg={12}>
        <div className="text-center">
          <Link to="/" className="mb-3 d-block auth-logo mt-5 mt-md-5 mt-lg-5 ">
            <img src={logo} alt="" height="80" className="logo logo-light" />
            <img src={logoLg} alt="" height="80" className="logo logo-dark" />
          </Link>
        </div>
      </Col>

      <Col xs="12">
        <div className="d-flex flex-column text-center px-2 justify-content-center">
          <span className="text-uppercase font-size-32 fw-bold lh-1 pText responsive-font-clamp">
            JOIN THE DOCKIES TODAY
          </span>

          <div className="pt-4 gap-0">
            <Row className="justify-content-center">
              <Col
                xs="12"
                sm="6"
                lg="auto"
                className="d-flex justify-content-center"
              >
                <Card
                  className="rounded-4 d-flex flex-column justify-content-center p-0"
                  style={{ height: "82px", width: "300px" }}
                >
                  <CardBody className="d-flex align-items-center p-1 ps-2">
                    <Row className="d-flex align-items-center">
                      <Col xs="auto" className="ps-2">
                        <h2
                          className="d-flex justify-content-center align-items-center fw-medium font-size-26 pre-color m-0 rounded-4 text-white"
                          style={{ height: "70px", width: "70px" }}
                        >
                          01
                        </h2>
                      </Col>
                      <Col>
                        <div className="d-flex flex-column text-start pe-1">
                          <span className="fw-normal font-size-12 text-secondary">
                            Start your subscription on your social media account
                            ($4.99/month)
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col
                xs="12"
                sm="6"
                lg="auto"
                className="d-flex justify-content-center"
              >
                <Card
                  className="rounded-4 d-flex flex-column justify-content-center p-0"
                  style={{ height: "82px", width: "300px" }}
                >
                  <CardBody className="d-flex align-items-center p-1 ps-2">
                    <Row className="d-flex align-items-center">
                      <Col xs="auto" className="ps-2">
                        <h2
                          className="d-flex justify-content-center align-items-center fw-medium font-size-26 pre-color m-0 rounded-4 text-white"
                          style={{ height: "70px", width: "70px" }}
                        >
                          02
                        </h2>
                      </Col>
                      <Col>
                        <div className="d-flex flex-column text-start pe-1">
                          <span className="fw-normal font-size-12 text-secondary">
                            Connect your subscribed social media account and
                            login here.TheDockies.com
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col
                xs="12"
                sm="6"
                md="auto"
                className="d-flex justify-content-center"
              >
                <Card
                  className="rounded-4 d-flex flex-column justify-content-center pre-color p-0 card-size"
                  style={{
                    background:
                      "linear-gradient(to right, rgba(255, 255, 255, 0.0005), #64b98b 35%)",
                  }}
                >
                  <CardBody className="d-flex align-items-center p-1 ps-2">
                    <Row className="d-flex align-items-center">
                      <Col xs="auto" className="ps-2">
                        <h2
                          className="d-flex justify-content-center align-items-center fw-medium font-size-26 pre-color m-0 rounded-4 text-black bg-white"
                          style={{ height: "70px", width: "70px" }}
                        >
                          03
                        </h2>
                      </Col>
                      <Col>
                        <div className="d-flex flex-column text-start pe-1">
                          <span className="fw-normal font-size-12 text-white">
                            Start uploading videos, sharing jokes, and upvoting
                            jokes for your chance to win prizes and get featured
                            on Dock Tok social media.
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <SocialLinkUpdate />

      <div className="d-flex d-md-none justify-content-center">
        <button className="btn btn-primary-green" onClick={handleClick}>
          LOGIN AS ADMIN
        </button>
      </div>
    </Row>
  )
}
export default withRouter(Register)

Register.propTypes = {
  registerUser: PropTypes.func,
  // registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}
