import React, { useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"

const floatingVariants = {
  initial: { opacity: 0, bottom: "0%", left: "0%" },
  animate: {
    opacity: [0, 1, 1, 1, 0, 0],
    bottom: ["0%", "20%", "40%", "60%", "80%", "100%"],
    left: ["0%", "70%", "10%", "50%", "5%", "60%"],
    transition: {
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
    },
  },
}

const FloatingHeart = ({ number = 10, duration = 10 }) => {
  return (
    <AnimatePresence>
      <div id="hearts-alpaca" className="hearts">
        {[...Array(number)].map((_, index) => (
          <motion.div
            key={index}
            className="heart"
            initial={{
              opacity: 1,
              bottom: "0%",
              left: "0%",
            }}
            animate={{
              opacity: [0, 1, 1, 1, 0, 0],
              bottom: ["0%", "20%", "40%", "60%", "80%", "100%"],
              left: ["0%", "70%", "10%", "50%", "5%", "60%"],
            }}
            transition={{
              duration: duration,
              ease: "easeInOut",
              repeat: Infinity,
              delay: index * (Math.floor(Math.random() * 5.5) + 1),
            }}
          />
        ))}
      </div>
    </AnimatePresence>
  )
}

export default FloatingHeart
