import React from "react"
import { Col, Container, Row } from "reactstrap"

const ListVideo = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <div className="border border-danger">ListVideo</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ListVideo
