import React from "react"
import styles from "./Loader.module.css" // Import your styles

const Loader = () => {
  return (
    <div className={`${styles.loadingState}`}>
      <div className={`${styles.loading}`}></div>
      <div> Please wait while we verify your subscription status.</div>
    </div>
  )
}

export default Loader
