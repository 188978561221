import React, { useEffect, useState } from 'react'
import fb from './Facebok.png'
import yt from './Youtube.png'
import ig from './Instagram.png'
import tt from './Tik-Tok.png'
import endpoints from 'api/endpoints'
import { get } from 'api/axios'
export const Community = () => {
  const [community, setCommunity] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState()

  async function userTraffic () {
    try{
      const response = await get(`${endpoints.usersStats}/usertraffic`)
      if(response?.data?.success) {
        const resp = response.data.data
        setCommunity(response.data.data)
        setTotal(resp.tiktok + resp.google + resp.instagram + resp.facebook)
          setLoading(false)
      }
    }catch(e){
      setError(e)
      setLoading(false)
    }
  }
  useEffect(()=>{
    userTraffic()
  }, [])
  return (
    <div className='card p-3 shadow h-100 rounded-20 rounded-4'>
      {loading && <div>loading....</div>}
      {community && <div className='container'>
        <div className='mb-3'>
          <span className='font-size-18 fw-medium text-darkcolor'>COMMUNITY GATHERED FROM</span>

        </div>
        <div className='d-flex gap-5 flex-column'>

          <div className='d-flex gap-3'>
            <img src={fb} alt='facebook'/>
            <div className='flex-grow-1'>
              <span className='secondary-white f14'>{(community.facebook/total)*100}%</span>
              <div className={` progress `}>
                <div
                  role="progressbar"
                  className='pre-color'
                  style={{
                    width: `${(community.facebook/total)*100}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className='d-flex gap-3'>
          <img src={ig} alt='instagram'/>
            <div className='flex-grow-1'>
              <span className='secondary-white f14'>{(community.instagram/total)*100}%</span>
              <div className={` progress `}>
                <div
                  role="progressbar"
                  className='pre-color'
                  style={{
                  
                    width: `${(community.instagram/total)*100}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className='d-flex gap-3'>
          <img src={tt} alt='tiktok'/>
            <div className='flex-grow-1'>
              <span className='secondary-white f14'>{(community.tiktok/total)*100}%</span>
              <div className={` progress `}>
                <div
                  role="progressbar"
                  className='pre-color'
                  style={{
                    width: `${(community.tiktok/total)*100}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center gap-3'>
          <img src={yt} alt='youtube'/>
            <div className='flex-grow-1'>
              <span className='secondary-white f14'>{(community.google/total)*100}%</span>
              <div className={` progress `}>
                <div
                  role="progressbar"
                  className='pre-color'
                  style={{
                    width: `${(community.google/total)*100}%`,
                  }}
                ></div>
              </div> 
            </div>
          </div>
        </div>
      </div>}
      {error && <div>{error.message}</div>}
      

    </div>
  )
}