// Hero.js
import React from "react"
import { Container } from "reactstrap"

const Header = () => (
  <section className="hero header-blue-bg ">
    <Container fluid className="p-0 m-0 py-xxl-5  ps-0 ps-xl-5 ms-0 ms-xl-5">
      <p
        className="ff-cooper font-size-120 fw-normal text-center text-lg-start transparent-text-border d-block m-0 p-0 me-xxl-2 pt-5"
        style={{ lineHeight: "0.3" }} // Reduced line height
      >
        we are the
      </p>
      <p
        className="ff-cooper font-size-224 text-center  primary-green-text d-block m-0 p-0 lh-1 text-uppercase pt-2 d-flex justify-content-center justify-content-lg-start "
        style={{ lineHeight: "0.2" }} // Reduced line height
      >
        Dockies
      </p>
      <p
        className="ff-proxima text-uppercase fw-bolder  text-center text-lg-start text-lg-end "
        style={{ fontStyle: "italic", lineHeight: "0.9", fontSize: "32px" }} // Reduced line height
      >
        Dad joke headquarters
      </p>
    </Container>
  </section>
)

export default Header
