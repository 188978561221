import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import touch from "assets/images/touch.png"
import award from "assets/images/award-01.png"
import upload from "assets/images/upload-square.png"
import gif from "assets/images/gif.png"

const getDynamicDates = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const date = new Date()
  const currentMonth = date.getMonth()
  const currentYear = date.getFullYear()
  const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate()
  const threeDaysBefore = lastDay - 3

  return {
    lastDay,
    threeDaysBefore,
    monthName: monthNames[currentMonth],
  }
}

const VotingItem = ({ icon, label, date }) => (
  <Card className="mb-3 rounded-4 bg-light-skin responsive-card-voting">
    <CardBody className="d-flex justify-content-between align-items-center overflow-mobile">
      <div className="d-flex align-items-center justify-content-between">
        <div className="voting-card d-flex justify-content-center align-items-center rounded-4">
          <img src={icon} alt="icon" />
        </div>
        <span className="gray-700 ps-sm-4 ps-3 ff-proxima font-size-18 fw-normal">
          {label}
        </span>
      </div>
      <span className="ff-proxima fw-bold font-size-18 text-black ps-2">
        {date}
      </span>
    </CardBody>
  </Card>
)

const VotingSection = () => {
  const { lastDay, threeDaysBefore, monthName } = getDynamicDates()

  return (
    <div className="py-5">
      <Row className="justify-content-center align-items-center mt-4">
        {/* Image Column */}
        <Col md={6} className="d-flex justify-content-center">
          <img
            src={gif}
            alt="Voting GIF"
            className="object-fit-cover img-fluid"
          />
        </Col>

        {/* Voting Items Column */}
        <Col md={6}>
          <h2 className="text-center text-sm-start ff-cooper fw-bold font-size-56 pb-4 pt-3 pt-md-0 black-text-color">
            VOTING IS OPEN!
          </h2>
          <VotingItem
            icon={upload}
            label="Submit Videos By"
            date={`${monthName} ${threeDaysBefore}`}
          />
          <VotingItem
            icon={touch}
            label="Vote Until"
            date={`${monthName} ${threeDaysBefore}`}
          />
          <VotingItem
            icon={award}
            label="Winner Announced"
            date={`${monthName} ${lastDay}`}
          />
        </Col>
      </Row>
    </div>
  )
}

export default VotingSection
