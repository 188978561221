import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import pic from "assets/images/home/challenge1.png"
import { get, del } from "api/axios"
import endpoints from "api/endpoints"
import TimeAgo from "react-timeago"
import { Badge, Button } from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"

export const TopicDetails = () => {
  const { id } = useParams()
  const [topic, setTopic] = useState({})
  const contentHandler = async () => {
    const resp = await get(`${endpoints.topic}/${id}`)
    if (resp?.data?.success) {
      setTopic(resp?.data?.data)
    }
  }
  const [deleteModal, setDeleteModal] = useState(false)
  const navigate = useNavigate()
  const contentDeleteHandler = async () => {
    const resp = await del(`${endpoints.topic}/${id}`)
    if (resp?.data?.success) {
      navigate("/header-videos")
    }
  }

  useEffect(() => {
    contentHandler()
  }, [])

  return (
    <div className="page-content bg-skin">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={contentDeleteHandler}
        onCloseClick={e => setDeleteModal(false)}
      />
      <div className="container-fluid">
        <div className="card p-3 ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex  flex-column flex-md-row justify-content-between align-items-md-center">
              <div className="d-flex flex-column">
                <span className="font-size-16 gray-700">
                  <TimeAgo date={topic?.createdAt} />
                </span>
                <h2>Title</h2>
                <span className="font-size-26 fw-medium text-darkcolor">
                  {topic?.title}{" "}
                  {topic?.featured && (
                    <Badge color="primary">Current Selection</Badge>
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-end d-md-inline">
                <Button
                  className="  mx-2 btn-primary-red-outline "
                  onClick={e => setDeleteModal(true)}
                >
                  DELETE HEADER VIDEO
                </Button>
                <Link to={`/edit-header-video/${topic?._id}`}>
                  <button className="btn btn-primary-green-outline ">
                    EDIT HEADER VIDEO
                  </button>
                </Link>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {topic?.mediaType == "image" ? (
                <img
                  src={
                    topic?.videoDetail?.thumbnail?.path
                      ? `${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.thumbnail?.path}`
                      : pic
                  }
                  alt="topic"
                  className="img-fluid w-50 h-50 rounded-4"
                />
              ) : (
                <video
                  src={`${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.video?.Key}`}
                  poster={`${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.thumbnail?.path}`}
                  controls
                  className="img-fluid w-50 h-50 rounded-4"
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
            <div>
              <h3>Rules</h3>
              <span
                className="font-size-16 gray-700"
                dangerouslySetInnerHTML={{ __html: topic.rules }}
              ></span>
            </div>
            <div>
              <h3>Description</h3>
              <span
                className="font-size-16 gray-700"
                dangerouslySetInnerHTML={{ __html: topic.description }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
