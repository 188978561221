import React, { useEffect, useState, useRef } from "react"
import { Container, Row, Col } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import AnimatedText from "components/animation/AnimatedText/AnimatedText"
import mobilesketch from "assets/images/mobile-sketch.png"

const Hero = ({
  inSection = true,
  dashboard = false,
  showHeaderText = true,
  showButton = true,
  showChallangeText = true,
  className = "",
  setRules = () => {},
  rules = "",
  videoClassName = "",
  sketchClassName = "",
}) => {
  const [topic, setTopic] = useState({})
  const videoRef = useRef(null) // Add a ref for the video element

  const contentHandler = async () => {
    const resp = await get(`${endpoints.topic}?page=1&limit=1&filter=featured`)
    if (resp?.data?.success) {
      setTopic(resp?.data?.data[0])
      rules == "set" && setRules(resp?.data?.data?.at(0).rules)
      localStorage.setItem("topic", resp?.data?.data?.at(0)?._id)
    }
  }

  useEffect(() => {
    contentHandler()
  }, [])

  const navigate = useNavigate()

  const handleClick = () => {
    window.scrollTo(0, 0)
    navigate("/")
  }

  // Function to toggle play/pause on video click
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }
  const [volume, setVolume] = useState(0) // Initial volume at 50%

  const handleVolumeChange = e => {
    videoRef.current.muted = false
    const newVolume = e.target.value / 100 // Convert range value (0-100) to 0-1 for volume
    setVolume(newVolume)
    if (!videoRef.current.paused) {
      videoRef.current.volume = parseFloat(newVolume).toFixed(1)
    }
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const getLastDayAndThreeDaysBefore = () => {
    const date = new Date()
    const currentMonth = date.getMonth()
    const currentYear = date.getFullYear()

    const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate()
    const threeDaysBefore = lastDay - 3

    // Determine if lastDay is 31 and append 'st'
    const lastDayWithSuffix = lastDay === 31 ? `${lastDay}st` : `${lastDay}th`

    // Calculate the target time for three days before the end of the month
    const targetTime =
      new Date(currentYear, currentMonth, lastDay).getTime() -
      3 * 24 * 60 * 60 * 1000 // 3 days in milliseconds

    return {
      lastDay: lastDayWithSuffix,
      threeDaysBefore,
      monthName: monthNames[currentMonth],
      targetTime,
    }
  }

  const { lastDay, threeDaysBefore, monthName, targetTime } =
    getLastDayAndThreeDaysBefore()
  const content = (
    <div fluid className={className}>
      <Row className="justify-content-center">
        {showHeaderText && (
          <Col className="col-12  mb-2 mb-lg-5 pb-2 pb-lg-5">
            <div className="text-center p-4">
              <p className="heading-animation text-uppercase fw-bold font-size-56 ff-barlow text-darkcolor mb-0 pb-0 custom-h2 ">
                we are dockies
              </p>
              <h1 className="text-uppercase ff-barlow fw-medium text-secondary font-size-32 mt-0 pt-2 custom-h1">
                <AnimatedText
                  className="text-center"
                  el="span"
                  text={`dad joke headquarters`}
                />
              </h1>
            </div>
          </Col>
        )}
        <Col className={`col-12 ${inSection ? "bg-hero    rounded-4 " : ""}`}>
          <div
            className={`d-flex flex-column h-100 ${
              inSection ? " " : "py-4 px-0"
            }`}
          >
            {topic ? (
              <Row className="">
                {showChallangeText && (
                  <Col
                    xs={12}
                    sm={1}
                    lg={1}
                    xl={1}
                    xxl={1}
                    className={
                      "bg-black text-white text-center  overflow-visible d-none d-lg-flex ${dockieClassName} "
                    }
                    style={{
                      marginLeft: "70px",
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "20px",
                      marginTop: "-20px",
                      marginBottom: "-20px",

                      background:
                        "linear-gradient(to top, rgba(0, 0, 0, 0.8), #64B98B 100%)",
                      overflow: "visible",
                    }}
                  >
                    <p className="vertical-text text-uppercase  mb-0 text-white  pt-5 ps-lg-0  font-size-60 ps-xl-0  d-flex justify-content-center  align-items-center p-0 m-0">
                      The Dockies
                    </p>
                  </Col>
                )}
                <Col
                  xs={12}
                  sm={6}
                  md={5}
                  lg={4}
                  xl={4}
                  xxl={3}
                  className="mobileVideoContainer text-center text-md-start px-0 px-2 px-sm-0 ps-sm-2 ps-lg-0 pt-0 mt-0 mb-0 pb-0 position-relative pt-4 pt-sm-0"
                >
                  <div className="position-relative w-100 ">
                    {/* Video Background */}
                    <div className="volumeContainer">
                      <span>
                        <i className="fa-regular fa-volume-up"></i>
                      </span>
                      <input
                        orient="vertical"
                        type="range"
                        value={volume * 100} // Convert volume back to 0-100 scale for the slider
                        onChange={handleVolumeChange}
                        class="form-range"
                        id="customRange1"
                      />
                    </div>
                    <video
                      ref={videoRef} // Add the ref to the video element
                      className={`video-background  ${videoClassName}`}
                      src={`${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.video?.Key}`}
                      poster={`${process.env.REACT_APP_S3_BUCKET}${topic?.videoDetail?.thumbnail?.path}`}
                      controls={false}
                      playsInline
                      autoPlay
                      loop
                      muted
                      // Add the click handler
                    />
                    {/* Mobile Sketch Image */}
                    <img
                      onClick={togglePlayPause}
                      src={mobilesketch}
                      className={`img-fluid rounded-2 mobile-sketch py-2 px-2 py-sm-2 py-lg-0 px-lg-0 ${sketchClassName}`}
                      alt="Mobile Sketch"
                    />
                  </div>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md={7}
                  lg={6}
                  xl={6}
                  xxl={7}
                  className="px-4 px-sm-4 px-md-5 py-2 py-sm-4 py-md-5 py-lg-0"
                >
                  <div className="d-flex flex-column h-100 justify-content-center">
                    <div className="">
                      {showChallangeText && (
                        <div className="mb-2 mt-2 ">
                          <p className="font-size-32 mb-0 pb-0 fw-bold text-white text-uppercase custom-h2">
                            <ShuffleText text="Share jokes. Swap smiles. Win prizes. Get featured @loganlisle" />
                          </p>
                          <p className="font-size-15 fw-medium text-white pt-1 mt-0">
                            <AnimatedText
                              el="span"
                              text={`The Dockies is THE first online dad joke community where YOU can share your best jokes with the Dock Tok World.`}
                            />
                          </p>
                        </div>
                      )}
                      {dashboard && (
                        <>
                          {" "}
                          <p className="font-size-15 fw-medium  pt-1 mt-0">
                            <strong>Last day to submit:</strong> {monthName}{" "}
                            {threeDaysBefore}th
                          </p>
                          <p className="font-size-15 fw-medium  pt-1 mt-0">
                            <strong>Last day to vote:</strong> {monthName}{" "}
                            {threeDaysBefore}th
                          </p>
                          <p className="font-size-15 fw-medium  pt-1 mt-0">
                            <strong>Winners Announced:</strong> {monthName}{" "}
                            {lastDay}
                          </p>
                        </>
                      )}
                      <span
                        className={`font-size-16 ${
                          inSection ? "text-white" : "gray-700"
                        }`}
                        dangerouslySetInnerHTML={{ __html: topic.description }}
                      ></span>

                      {showButton && dashboard == false && (
                        <Link to={`/challenge-detail/${topic?._id}`}>
                          <button
                            type="button"
                            className="btn btn-primary-green text-uppercase mb-5 mb-md-2"
                            onClick={handleClick}
                          >
                            let's go!
                          </button>
                        </Link>
                      )}
                      {showButton && dashboard && (
                        <Link to={`/challenge-detail/${topic?._id}`}>
                          <button
                            type="button"
                            className="btn btn-primary-green text-uppercase mb-5 mb-md-2"
                            onClick={handleClick}
                          >
                            Submit your dad joke videos
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="d-flex justify-content-center">
                <span className="font-size-26 text-darkcolor text-uppercase fw-medium">
                  no header video
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )

  return inSection ? (
    <Container fluid id="hero-section">
      {content}
    </Container>
  ) : (
    content
  )
}

export default Hero
