import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"
import logoLg from "assets/images/header-logo-lg.svg"
import axios from "axios"
import { toast, Bounce } from "react-toastify"
import endpoints from "api/endpoints"
import { post } from "api/axios"

const ForgetPasswordPage = props => {
  const navigate = useNavigate()
  document.title = " Recover Password | Dock Tok"

  const [apiError, setApiError] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please Enter Your Email"),
    }),
    onSubmit: async values => {
      try {
        const resp = await post(`${endpoints.reset}/password`, values)
        if (resp?.data?.success) {
          toast.success("Instructions have been sent to your Email", {
            position: "bottom-center",
            transition: Bounce,
            theme: "colored",
          })
        } else {
          setApiError("Failed to send email. Please try again.")
        }
      } catch (error) {
        setApiError("An error occurred. Please try again.")
      }
    },
  })

  useEffect(() => {
    document.body.className = "authentication-bg"
    return () => {
      document.body.className = ""
    }
  }, [])

  return (
    <Row className="px-5">
      <Col lg={12}>
        <div className="text-center">
          <Link to="/" className="mb-sm-3 mt-5 d-block auth-logo">
            <img src={logo} alt="" height="80" className="logo logo-light" />
            <img src={logoLg} alt="" height="80" className="logo logo-dark" />
          </Link>
        </div>
      </Col>
      <Col xs="12">
        <div className="text-center mt-2">
          <h5 className="text-primary">Reset Password</h5>
        </div>
        <div className="p-2 mt-4">
          <div
            className="alert pre-color text-white text-center mb-4"
            role="alert"
          >
            Enter your Email and instructions will be sent to you!
          </div>
          {apiError ? (
            <Alert
              color="danger"
              className="text-center mb-4"
              style={{ marginTop: "13px" }}
            >
              {apiError}
            </Alert>
          ) : null}

          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="mb-3">
              <Label className="form-label">Email</Label>
              <Input
                name="email"
                className="form-control"
                placeholder="Enter email"
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="mb-0">
              <Col xs={12} className="text-end">
                <button
                  className="btn btn-primary-green w-md waves-effect waves-light"
                  type="submit"
                >
                  Reset Password
                </button>
              </Col>
            </Row>
            <div className="mt-4 text-center">
              <p className="mb-0">
                Remember It?{" "}
                <Link to="/login" className="fw-medium text-primary">
                  Sign in
                </Link>
              </p>
            </div>
          </Form>
        </div>
      </Col>
      <Col xs="12">
        <div className="mt-3 text-center">
          <p className="text-dark">
            © {new Date().getFullYear()} Dock Tok. Crafted with{" "}
            <i className="mdi mdi-heart text-danger" /> by PixarsArt
          </p>
        </div>
      </Col>
    </Row>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

export default withRouter(ForgetPasswordPage)
