import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Row,
  Col,
  // CardBody,
  // Card,
  Alert,
  // Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

// Redux
import { Link, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logo from "../../assets/images/logo-dark.png"
import logoLg from "assets/images/header-logo-lg.svg"

import { post } from "../../api/axios"
import endpoints from "../../api/endpoints"
import { setLogin } from "../../store/slices/authSlice"
import { useDispatch } from "react-redux"
import { Bounce, toast } from "react-toastify"

const AdminLogin = props => {
  document.title = "Login | Dock Tok"

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [apiError, setApiError] = React.useState(null) // State to store API error

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: async values => {
      try {
        const response = await post(endpoints.login, values)
        if (response?.data?.success) {
          const fcmToke = await localStorage.getItem("fcm")
          const fcmData = await post(endpoints.fcm, { fcmToken: fcmToke })

          navigate("/otp", { state: { email: values.email } })
          toast.success("2FA is sent to your email address.", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
          // localStorage.setItem("accessToken", response?.data?.token)
        } else {
          toast.error(response.response?.data?.errore, {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
          setApiError(
            response.response?.data?.errore || "Login failed. Please try again."
          ) // Handle error message from API
        }
      } catch (error) {
        setApiError("Incorrect Credentials. Please try again.")
      }
    },
  })

  useEffect(() => {
    document.body.className = "authentication-bg"
    return () => {
      document.body.className = ""
    }
  }, [])

  return (
    <div className="d-flex px-md-5 mx-lg-3">
      <Row className="px-md-5">
        <Col lg={12}>
          <div className="text-center">
            <Link to="/" className="mb-sm-3 mt-5 d-block auth-logo">
              <img src={logo} alt="" height="80" className="logo logo-light" />
              <img src={logoLg} alt="" height="80" className="logo logo-dark" />
            </Link>
          </div>
        </Col>
        <Col xs="12" lg="12">
          <div className="text-center mt-2">
            <span className="fw-medium font-size-26">
              SIGN-IN TO YOUR ACCOUNT
            </span>
            <p className="text-muted ">
              Enter the credentials below to get started.
            </p>
          </div>
          <div className="p-2 mt-4">
            <Form
              className="form-horizontal w-100"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              {apiError ? <Alert color="danger">{apiError}</Alert> : null}

              <div className="mb-3">
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Username/Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Input
                  name="password"
                  value={validation.values.password || ""}
                  type="password"
                  placeholder="Enter Your Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customControlInline"
                />
                <label
                  className="form-check-label"
                  htmlFor="customControlInline"
                >
                  Remember me
                </label>
                <div className="float-end">
                  <Link to="/forgot-password" className="text-danger">
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <div className="mt-3 mb-0">
                <button
                  className="btn btn-primary-green w-100 waves-effect text-white waves-light"
                  type="submit"
                >
                  LOGIN TO YOUR ACCOUNT
                </button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(AdminLogin)

AdminLogin.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
