// actionTypes.js
import { createSlice } from "@reduxjs/toolkit"

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: false,
    jid: "",
    currentUser: null,
    userPic: "",
    loginType: "",
    youtubeConnected: false,
  },
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload
    },
    setJid: (state, action) => {
      state.jid = action.payload
    },
    setUser: (state, action) => {
      state.currentUser = action.payload
    },
    setYoutubeConnect: (state, action) => {
      state.currentUser = action.payload.currentUser
      state.youtubeConnected = action.payload.youtubeConnected
    },
    setLogout: state => {
      state.currentUser = null
      state.jid = ""
      state.isAuth = false
      state.userPic = ""
      state.loginType = ""
    },
    setLogin: (state, action) => {
      state.currentUser = action.payload.currentUser
      state.jid = action.payload.jid
      state.isAuth = action.payload.isAuth
      state.loginType = action.payload.loginType
      state.youtubeConnected = action.payload.youtubeConnected
    },
    setUserPic: (state, action) => {
      state.userPic = action.payload
    },
  },
})

export const {
  setAuth,
  setJid,
  setUser,
  setLogout,
  setLogin,
  setUserPic,
  setYoutubeConnect,
} = authSlice.actions

export default authSlice.reducer
