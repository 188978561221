import React, { useEffect } from "react"
import LineChart from "../Graphs/LineGraph"
import axios from "axios"
import { toast } from "react-toastify"
import endpoints from "api/endpoints"
import { get } from "api/axios"
export const UsersCard = () => {
  const [active, setActive] = React.useState("week")
  const [users, setUsers] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  async function fetchUsers() {
    try {
      const response = await get(`${endpoints.usersStats}/graph?type=${active}`)
      if (response?.data?.success) {
        setUsers(response.data.data.dataList)
        setLoading(false)
      } else {
        throw new Error("some error occurred")
      }
    } catch (e) {
      setError(e)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fetchUsers()
  }, [active])
  const catagories = [
    { text: "1W", link: "week" },
    { text: "1M", link: "month" },
    { text: "1Y", link: "year" },
  ]

  return (
    <div className="card shadow px-3 pt-3 pb-0 rounded-4 h-100">
      <div className="container d-flex flex-column gap-3">
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <div>
            <span className="font-size-18 fw-medium text-darkcolor">
              USERS REGISTERD
            </span>
          </div>
          <div className="bg-skin  p-1 rounded-pill">
            {catagories.map(catagory => (
              <button
                onClick={() => {
                  setActive(catagory.link)
                }}
                className={`btn rounded-pill px-4 ${
                  active === catagory.link ? "bg-white" : ""
                }`}
              >
                {catagory.text}
              </button>
            ))}
          </div>
        </div>
        <div className="w-100 h-100">
          {loading && <div>loading...</div>}
          {error && <div>{error.message}</div>}
          {users && <LineChart type={active} data={users} />}
        </div>
      </div>
    </div>
  )
}
