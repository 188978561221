module.exports = {
  google: {
    API_KEY: "AIzaSyDI_BmAQcWO2k4kThmuSoFb7CF8vv9q4hM",
    CLIENT_ID:
      "289168578377-mj0ofmvofhs3a4hvd6f79ivi1vp2al48.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "406752255560808",
  },
  instagram: {
    APP_ID: "1076203853921455",
    APP_SECRET: "d859c3cb6a04eea1399cab37bedd654c",
  },
  tiktok: {
    AAP_ID: "7368404827438745605",
    CLIENT_KEY: "aw2b64unzb6wz669",
  },
}
