import React from "react"
import PrivacyPolicy from "./components/PrivacyPolicy"

export default function Privacy() {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  )
}
