import React from "react"
import { Container, Row, Col } from "reactstrap"
import img from "assets/images/videotip.png"
import video from "assets/images/video.mp4"

import tip1 from "../../../assets/images/tip1.png"
import tip2 from "../../../assets/images/tip2.png"
import tip3 from "../../../assets/images/tip3.png"
import tip4 from "../../../assets/images/tip4.png"
import tip5 from "../../../assets/images/tip5.png"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast, Bounce } from "react-toastify"

import { Link } from "react-router-dom"
import { Hidden } from "@material-ui/core"
export default function VideoTips() {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const topic = localStorage.getItem("topic")
  const handleSubmitClick = () => {
    if (isAuth) {
      navigate(`/challenge-detail/${topic}`)
    } else {
      toast.error("Please Sign in to Submit Your Video.", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login")
    }
  }
  return (
    <section className="py-5 my-5">
      <Container fluid className="py-5 py-lg-5 px-2 header-blue-bg rounded-5">
        <Row>
          <Col md={6} lg={5} className="dad-joke-video ">
            <div className=" ms-0 ps-lg-2 ps-xxl-5 ms-xxl-5 pt-3 pt-lg-0 px-4 px-md-0">
              <video
                src={
                  "https://dkkt6vopmv10m.cloudfront.net/uploads/771cb4ad-8d25-4fdc-bd41-e0391d826658-loginpagesvideo.mp4"
                }
                poster="https://dkkt6vopmv10m.cloudfront.net/uploads/de5cd158-e4d2-4156-90e5-1dd7c53ad573-d4d2f77a51bb4941bd00c5680e2e1031loginpagesvideo.mp4.jpg"
                alt="auth-banner"
                controls
                className="rounded-5 video-max-height w-100 object-fit-cover"
                style={{
                  height: window.innerWidth >= 899 ? "700px" : "500px",
                }}
              />
              {/* <video
                src={video}
                controls
                className="rounded-5 video-max-height w-100 object-fit-cover"
                style={{ maxHeight: "700px" }}
              />*/}
            </div>
          </Col>

          <Col md={6} lg={7} className="text-white py-5 px-2 ps-3  ps-lg-5 ">
            <h2 className="font-size-56 fw-normal ff-cooper text-white ps-md-4 ms-md-2">
              DAD JOKE VIDEO TIPS
            </h2>
            <ul style={{ listStyleType: "none" }} className="ruleUl pb-0">
              <li className="text-white ff-proxima fw-normal font-size-18">
                <img src={tip1} alt={"Video"} className={"ulIcons"} /> Film with
                a family member or friend{" "}
              </li>

              <li className="text-white ff-proxima fw-normal font-size-18">
                <img src={tip2} alt={"Video"} className={"ulIcons"} /> Film in a
                quiet place{" "}
              </li>
              <li className="text-white ff-proxima fw-normal font-size-18">
                <img src={tip3} alt={"Video"} className={"ulIcons"} /> Film
                multiple views{" "}
              </li>

              <li className="text-white ff-proxima fw-normal font-size-18">
                <img src={tip4} alt={"Video"} className={"ulIcons"} />
                Stand out with a cool background{" "}
              </li>
              <li className="text-white ff-proxima fw-normal font-size-18">
                <img src={tip5} alt={"Video"} className={"ulIcons"} />
                Props are encouraged{" "}
              </li>
              <li className="text-white ff-proxima fw-normal font-size-18 mt-3">
                We are all here to share laughs so be yourself and have fun{" "}
              </li>

              <div className=" ">
                {" "}
                {topic && (
                  <button
                    onClick={handleSubmitClick}
                    className="btn btn-primary-yellow font-size-14  text-black bg-yellow text-uppercase text-center  mt-4 ff-proxima "
                  >
                    Submit your dad jokes
                  </button>
                )}
              </div>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
