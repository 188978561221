import React from "react"
import SubmitYourVideo from "./components/SubmitYourVideo"
import WinnerFromLastMonth from "./components/WinnerFromLastMonth"
import Hero from "./components/Hero"
import MonthlyRules from "./components/MonthlyRules"
// import Pomodoro from "./components/Pomodoro"

import Events from "./components/Events"
// import Challenges from "./components/Challenges"
import TawkMessengerReact from "@tawk.to/tawk-messenger-react"

import HowDockTokWorks from "./components/HowDockTokWorks"
import VideosPostedByDockies from "./components/VideosPostedByDockies"

const Home = () => {
  return (
    <>
      <Hero />
      {/* <Challenges /> */}
      {/* <Events /> */}
      <TawkMessengerReact
        propertyId="66e96de0ea492f34bc158cf1"
        widgetId="1i7vtadml"
      />
      <VideosPostedByDockies />
      <HowDockTokWorks />
      <MonthlyRules />
      <WinnerFromLastMonth />
      <SubmitYourVideo />
      {/* <Pomodoro /> */}
    </>
  )
}

export default Home
