import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
export default function AdminVideoVaults() {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selected, setSelected] = useState("Last Month")

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const handleSelect = event => {
    setSelected(event.target.innerText)
  }
  const [videoData, setVideoData] = useState([])
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const getFetch = async (nextPage = 1) => {
    const resp = await get(`${endpoints.getVideos}?page=${nextPage}&limit=4`)
    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1
    getFetch(nextPage)
  }

  useEffect(() => {
    getFetch()
  }, [])

  return (
    <section id="admin-videovaults" className="page-content bg-light">
      <Container fluid>
        <Row className="mb-0">
          <Col sm="6">
            <h2 className="fw-semibold font-size-26 text-uppercase">
              Video Vaults
            </h2>
          </Col>
          <Col sm="6">
            <div className="d-flex justify-content-end align-items-center   ">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="bg-dgray rounded-pill"
              >
                <div onClick={toggle}>
                  <DropdownToggle
                    caret
                    tag="span"
                    className="bg-white rounded-pill ps-2"
                  >
                    {selected}
                  </DropdownToggle>
                  <i className="ps-2 fa-solid fa-angle-down pe-1"></i>
                </div>
                <DropdownMenu>
                  <DropdownItem onClick={handleSelect}>Last Month</DropdownItem>
                  <DropdownItem onClick={handleSelect}>This Month</DropdownItem>
                  <DropdownItem onClick={handleSelect}>Last Year</DropdownItem>
                  <DropdownItem onClick={handleSelect}>This Year</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <Row className=" ">
          <Col xs="12">
            <Row className="mt-2 row-gap-3 mb-5">
              {videoData?.length > 0 &&
                videoData.map(card => (
                  <Col sm={6} lg={4} xxl={3} className="mb-3" key={card.id}>
                    <VideoCard data={card} />
                  </Col>
                ))}
            </Row>
            {loadMore.lastPage !== loadMore.currentPage && (
              <Row className="justify-content-center mb-5 pb-5">
                <div className="text-center">
                  <button
                    className="text-uppercase font-size-14 btn btn-primary-green"
                    onClick={handleLoadMore}
                  >
                    Come on, show us what you’ve got!{" "}
                  </button>
                </div>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
