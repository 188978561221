import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate, useLocation } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoLg from "assets/images/header-logo-lg.svg"
import axios from "axios"
import { toast, Bounce } from "react-toastify"
import endpoints from "api/endpoints"
import { post } from "api/axios"

const ConfirmPasswordPage = props => {
  document.title = " Recover Password | Dock Tok"
  const navigate = useNavigate()
  const location = useLocation()
  const queryString = location.search
  const params = new URLSearchParams(queryString)
  // const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: params.get("token"),
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please Enter Your OTP"),
      password: Yup.string()
        .required("Please Enter Password to Continue")
        .min(6, "Password Must Be 6 Characters Long"),
      confirmPassword: Yup.string()
        .required("Please Confirm Password to Continue")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .min(6, "Confirm Password Must Be 6 Characters Long"),
    }),

    onSubmit: async values => {
      try {
        const resp = await post(`${endpoints.reset}/otp`, {
          resetToken: values.otp,
          password: values.password,
        })

        if (resp?.data?.success) {
          toast.success("Password Changed Successfully")
          navigate("/admin")
        } else {
          // Display an error message from the API response, if available
          toast.error(resp?.data?.message || "Invalid OTP", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
        }
      } catch (error) {
        // Handle unexpected errors
        toast.error("An unexpected error occurred. Please try again.")
      }
    },
  })

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  const forgetError = null
  const forgetSuccessMsg = null
  return (
    <Row className="px-5">
      <Col lg={12}>
        <div className="text-center">
          <Link to="/" className="mb-sm-3 mt-5 d-block auth-logo">
            <img src={logo} alt="" height="80" className="logo logo-light" />
            <img src={logoLg} alt="" height="80" className="logo logo-dark" />
          </Link>
        </div>
      </Col>
      <Col xs="12">
        <div className="text-center mt-2">
          <h5 className="text-primary">Reset Password</h5>
        </div>
        <div className="p-2 mt-4">
          {forgetError && forgetError ? (
            <Alert
              color="danger"
              className="text-center mb-4"
              style={{ marginTop: "13px" }}
            >
              {forgetError}
            </Alert>
          ) : null}
          {forgetSuccessMsg ? (
            <Alert
              color="success"
              className="text-center mb-4"
              style={{ marginTop: "13px" }}
            >
              {forgetSuccessMsg}
            </Alert>
          ) : null}

          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            {/* <div className="mb-3">
              <Label className="form-label text-uppercase">Enter OTP</Label>
              <Input
                name="otp"
                className="form-control"
                placeholder="Enter OTP"
                type="text"
                readOnly="true"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.otp || ""}
                invalid={
                  validation.touched.otp && validation.errors.otp ? true : false
                }
              />
              {validation.touched.otp && validation.errors.otp ? (
                <FormFeedback type="invalid">
                  {validation.errors.otp}
                </FormFeedback>
              ) : null}
            </div> */}

            <div className="mb-3">
              <Label className="form-label text-uppercase">
                Enter New Password
              </Label>
              <Input
                name="password"
                className="form-control"
                placeholder="Enter New Password"
                type="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.password || ""}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label text-uppercase">
                Confirm Password
              </Label>
              <Input
                name="confirmPassword"
                className="form-control"
                placeholder="Confirm Password"
                type="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.confirmPassword || ""}
                invalid={
                  validation.touched.confirmPassword &&
                  validation.errors.confirmPassword
                    ? true
                    : false
                }
              />
              {validation.touched.confirmPassword &&
              validation.errors.confirmPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.confirmPassword}
                </FormFeedback>
              ) : null}
            </div>
            <Row className="mb-0">
              <Col xs={12} className="text-end">
                <button
                  className="btn btn-primary-green w-md waves-effect waves-light"
                  type="submit"
                >
                  Reset
                </button>
              </Col>
            </Row>
            <div className="mt-4 text-center">
              <p className="mb-0">
                Remember It ?{" "}
                <Link to="/login" className="fw-medium text-primary">
                  {" "}
                  Sign in
                </Link>
              </p>
            </div>
          </Form>
        </div>
      </Col>
      <Col xs="12">
        <div className="mt-3 text-center">
          <p className="text-dark">
            © {new Date().getFullYear()} Dock Tok. Crafted with{" "}
            <i className="mdi mdi-heart text-danger" /> by PixarsArt
          </p>
        </div>
      </Col>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div> */}
    </Row>
  )
}

ConfirmPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

export default withRouter(ConfirmPasswordPage)
