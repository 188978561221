import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import withRouter from "../../components/Common/withRouter"

import { logoutUser } from "../../store/actions"
import { useNavigate } from "react-router-dom"
import { setLogout } from "store/slices/authSlice"

const Logout = () => {
  document.title = " Logout | Dock Tok"

  const history = useNavigate()
  const dispatch = useDispatch()
  // const dispatch = null

  useEffect(() => {
    // dispatch(logoutUser(history))
    dispatch(setLogout())
    history("/login")
    localStorage.removeItem("accessToken")
  }, [dispatch, history])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
}

// export default withRouter(connect(null, { logoutUser })(Logout))
export default withRouter(Logout)
