import React from "react"
import { Container, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import place1 from "assets/images/place1.png"
import { Link, useNavigate } from "react-router-dom"
import { Bounce, toast } from "react-toastify"
import { useSelector } from "react-redux"
const Placeholders = ({ type = "" }) => {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const topic = localStorage.getItem("topic")
  const handleSubmitClick = () => {
    if (isAuth) {
      navigate(`/challenge-detail/${topic}`)
    } else {
      toast.error("Please Sign in to Submit Your Video.", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login")
    }
  }
  return (
    <section id="Placeholder" className="py-2 py-md-5 px-2 px-md-5 ">
      <Container fluid className="placeholder-container">
        <Row className="justify-content-center text-center py-4 py-md-5 px-4 px-md-5 light-green-bg rounded-4 ">
          <Col xs="12" md="10">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={place1} alt="Placeholder" className="img-fluid" />
            </div>
            <h2 className="fw-medium font-size-26 text-uppercase text-darkcolor mt-4 mb-0">
              No video found
            </h2>
            {type == "" && (
              <>
                <p className="font-size-16 fow-normal text-secondary">
                  No video is posted by anyone. Be the first one to
                  <br /> submit the video.{" "}
                </p>

                <Link to={`/challenge-detail/${topic}`}>
                  <button
                    className="text-uppercase mt-2 font-size-14 font-weight-400 btn btn-primary-green"
                    onClick={handleSubmitClick}
                  >
                    upload video{" "}
                  </button>
                </Link>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Placeholders
