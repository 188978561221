import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"

import { connect, useDispatch, useSelector } from "react-redux"
import TimeAgo from "react-timeago"
import { Link } from "react-router-dom"
// Redux Store
// import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import { toggleLeftmenu } from "../../store/slices/settingsSlice"
import user4 from "assets/images/users/avatar-4.jpg"
// reactstrap
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "../../assets/images/footer-logo.svg"
import logoLight from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/footer-logo.svg"
import logoLgDark from "../../assets/images/footer-logo.svg"

// import images

import personPlaceholder from "assets/images/personPlaceholder.png"

//
import { get } from "api/axios"
import endpoints from "api/endpoints"
//i18n
import { withTranslation } from "react-i18next"
import SearchDropdown from "../LandingLayout/TopNavBar/SearchVideo"
import { Search } from "feather-icons-react/build/IconComponents"
import SimpleBar from "simplebar-react"
const types = [
  { text: "Videos", action: "video", url: "videodetail" },
  { text: "Profiles", action: "user", url: "userprofile" },
  { text: "How it Works", action: "How it works", url: "how-it-works-details" },
]
const Header = props => {
  const [isSearch, setSearch] = useState(false)
  const [type, setType] = useState(types[0])

  const dispatch = useDispatch()
  const leftMenu = useSelector(state => state.settings.leftMenu)
  const currentUser = useSelector(state => state.auth.currentUser)
  const [show, setShow] = useState(false)
  const [search, setSearchText] = useState("")
  const [search2, setSearchText2] = useState()
  const [isActive, setIsActive] = useState(false)
  const [videoData, setVideoData] = useState()
  const [inside, setInside] = useState(false)

  const getFetch = async text => {
    if (search.trim().length > 0) {
      try {
        const resp = await get(
          `${endpoints.search}?filter=${type.action}&search=${search}`
        )
        if (resp?.data?.success) {
          setVideoData(resp?.data?.data)
        }
      } catch (e) {}
    } else {
      setVideoData([])
    }
  }
  useEffect(() => {
    getFetch()
  }, [type, search])

  const handleFocus = () => {
    setShow(true)
  }

  const handleBlur = () => {
    if (inside) return
    else setShow(false) // Add a slight delay to allow dropdown interactions
  }

  const toggle = e => {
    setSearchText("")
    setType(types[0])
    setShow(!show)
    window.scroll(0, 0)
  }

  return (
    <React.Fragment>
      <div className="navbar-header pt-3">
        <div className="d-flex">
          <div className="navbar-brand-box px-3">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logo} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="50" />
              </span>
            </Link>

            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoLgDark} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoLgDark} alt="" height="50" />
              </span>
            </Link>
          </div>
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-toggle="collapse"
            onClick={() => {
              // props.toggleLeftmenu(!props.leftMenu);
              dispatch(toggleLeftmenu(!leftMenu))
            }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <i className="uil-search"></i>
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" // Added 'dropdown-menu-end'
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <div className="p-3">
                <div className="form-group m-0 mb-2">
                  <div className="input-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("Search") + "..."}
                      aria-label="Recipient's username"
                      value={search}
                      onChange={e => setSearchText(e.target.value)}
                    />
                  </div>
                  <div>
                    <Input
                      type="select"
                      name="category"
                      id="category"
                      className=" gray-700 font-size-14"
                      onChange={e => {
                        setType(JSON.parse(e.target.value))
                        setVideoData([])
                      }}
                    >
                      {types.map((_, index) => (
                        <option key={index} value={JSON.stringify(_)}>
                          {_.text}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="p-3">
                  <div>
                    <SimpleBar style={{ maxHeight: "300px" }}>
                      <Row className=" row-gap-3 mb-5">
                        {videoData?.length > 0 &&
                          videoData.map((card, index) => (
                            <div key={index}>
                              <div className="row d-flex align-items-center">
                                <div
                                  className={`image-container position-relative ${
                                    type.action === "user" ? "col-2" : "col-5"
                                  }`}
                                >
                                  <Link
                                    to={`/${type?.url}/${card?._id}/`}
                                    onClick={toggle}
                                  >
                                    {type.action === "user" && (
                                      <img
                                        className="rounded-circle header-profile-user "
                                        src={
                                          card?.additionalInfo?.profileImage
                                            ? process.env.REACT_APP_S3_BUCKET +
                                              currentUser?.additionalInfo
                                                ?.profileImage
                                            : user4
                                        }
                                        alt="Header Avatar"
                                      />
                                    )}
                                    {type.action !== "user" && (
                                      <img
                                        src={`${process.env.REACT_APP_S3_BUCKET}${card?.thumbnail}`}
                                        alt=""
                                        className="w-75 rounded-3 object-fit-cover"
                                        height={"50px"}
                                      />
                                    )}
                                  </Link>
                                </div>
                                <div
                                  className={`${
                                    type === "user" ? "col-9" : "col-7"
                                  }`}
                                >
                                  <h3
                                    className={` font-size-14 fw-medium text-darkcolor `}
                                  >
                                    <Link
                                      to={`/${type?.url}/${card?._id}/`}
                                      onClick={toggle}
                                    >
                                      {card?.title ||
                                        card?.firstName + " " + card?.lastName}
                                    </Link>
                                  </h3>
                                </div>
                              </div>
                              <hr className="p-0 m-0 mt-1  text-secondary" />
                            </div>
                          ))}
                      </Row>
                    </SimpleBar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form className="app-search d-none d-lg-block ps-4 mt-1">
            <div className="position-relative ">
              <div className="d-flex align-items-center">
                <input
                  id="search"
                  type="text"
                  onChange={event => {
                    setSearchText(event.target.value)
                  }}
                  className="form-control rounded-3 pt-2 font-size-14 fw-normal"
                  style={{ backgroundColor: "#353535", width: "300px" }}
                  placeholder="Search"
                  autoComplete="off"
                  value={search}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onClick={handleFocus}
                />
                {!isActive && <span className="uil-search"></span>}
              </div>

              {true && (
                <div
                  className="position-absolute w-100"
                  onMouseEnter={() => {
                    setInside(true)
                  }}
                  onMouseLeave={() => {
                    setInside(false)
                  }}
                  style={{ top: "100%", zIndex: 1000 }}
                >
                  <Dropdown toggle={toggle} isOpen={show} direction="down">
                    <DropdownMenu
                      className="mt-2 rounded-3"
                      style={{ width: "400px" }}
                    >
                      <div className="p-3">
                        <div>
                          <div className="row mb-2 d-flex justify-content-between ">
                            <div className="col-auto align-items-center pt-2 ">
                              <h3 className="text-uppercase text-darkcolor  font-size-18 fw-medium">
                                Search Result
                              </h3>
                            </div>
                            <div className="col-auto  ">
                              <Input
                                type="select"
                                name="category"
                                id="category"
                                className="gray-700 font-size-14"
                                onChange={e => {
                                  setType(JSON.parse(e.target.value))
                                  setVideoData([])
                                }}
                              >
                                {types.map((_, index) => (
                                  <option key={index} value={JSON.stringify(_)}>
                                    {_.text}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </div>
                          <SimpleBar style={{ maxHeight: "300px" }}>
                            <Row className="row mb-1">
                              {videoData?.length > 0 &&
                                videoData.map((card, index) => (
                                  <div key={index}>
                                    <div className="row d-flex align-items-center pb-2">
                                      <div
                                        className={`image-container position-relative ${
                                          type.action === "user"
                                            ? "col-2"
                                            : "col-5"
                                        }`}
                                      >
                                        <Link
                                          to={`/${type?.url}/${card?._id}/`}
                                          onClick={toggle}
                                        >
                                          {type.action === "user" ? (
                                            <img
                                              className="rounded-circle header-profile-user"
                                              src={
                                                card?.additionalInfo
                                                  ?.profileImage
                                                  ? `${process.env.REACT_APP_S3_BUCKET}${currentUser?.additionalInfo?.profileImage}`
                                                  : personPlaceholder
                                              }
                                              alt="Header Avatar"
                                            />
                                          ) : (
                                            <img
                                              src={`${process.env.REACT_APP_S3_BUCKET}${card?.thumbnail}`}
                                              alt=""
                                              className="w-75 rounded-3 object-fit-cover"
                                              height="70px"
                                            />
                                          )}
                                        </Link>
                                      </div>
                                      <div
                                        className={`${
                                          type.action === "user"
                                            ? "col-9"
                                            : "col-7"
                                        } ps-0 ms-0`}
                                      >
                                        <h3 className="font-size-14 fw-medium text-darkcolor">
                                          <Link
                                            to={`/${type?.url}/${card?._id}/`}
                                            onClick={toggle}
                                          >
                                            {card?.title ||
                                              `${card?.firstName} ${card?.lastName}`}
                                          </Link>
                                        </h3>
                                      </div>
                                    </div>
                                    {/* <hr className="p-0 m-0 mt-1 text-secondary" /> */}
                                  </div>
                                ))}
                            </Row>
                          </SimpleBar>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            </div>
          </form>
        </div>

        <div className="d-flex">
          <NotificationDropdown />

          <ProfileMenu />
        </div>
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  // toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

// export default connect(mapStatetoProps, {
//   showRightSidebarAction,
//   toggleLeftmenu,
// })(withTranslation()(Header));
export default withTranslation()(Header)
