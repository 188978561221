import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import pic1 from "assets/images/home/hero-event.png"
import DeleteModal from "components/Common/DeleteModal"
import axios from "axios"
import { toast } from "react-toastify"
import { del, get, put } from "api/axios"
import endpoints from "api/endpoints"
import personPlaceholder from "assets/images/personPlaceholder.png"
import Switch from "react-switch"
export const TheDockies = () => {
  const [show, setShow] = React.useState(false)
  const [users, setUsers] = React.useState()
  const [disabled, setDisabled] = React.useState(false)

  const getFetch = async () => {
    try {
      const resp = await get(`${endpoints.usersStats}?filter&limit=5`)
      if (resp?.data?.success) {
        setUsers(resp?.data?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    getFetch()
  }, [])
  const toggleModal = () => {
    setShow(!show)
  }
  const handleDelete = async () => {
    const resp = await del(`${endpoints.getVideos}/66a3deb305ef2bcbafd507b9`)

    if (resp?.data?.success) {
      setShow(false)
      toast.success(resp.data)
    }
    if (!resp?.response?.data?.success) {
      setShow(false)
      toast.error(resp.message)
    }
  }
  const handleChange = async ({ action, id }) => {
    const resp = await put(`${endpoints.usersStats}/${action}/${id}`)
    setDisabled(true)
    if (resp?.data?.success) {
      toast.success("Successfull")
      getFetch()
      setDisabled(false)
    } else {
      setDisabled(false)
      toast.error("error")
    }
  }
  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <DeleteModal
        show={show}
        onCloseClick={toggleModal}
        onDeleteClick={handleDelete}
      />
      <div className={`overflow-auto card shadow p-3 rounded-4 `}>
        <div className="d-flex justify-content-between mb-3">
          <span className="font-size-18 fw-medium">USERS</span>
          <Link to="/users" onClick={handleClick}>
            <span className="font-size-14 fw-medium pre-text pe-md-5 pe-lg-0">
              VIEW ALL
            </span>
          </Link>
        </div>
        <table class="table">
          <thead>
            <tr className="my-2">
              <th scope="col" className="font-size-14 text-darkcolor">
                #ID{" "}
              </th>
              <th scope="col" className="font-size-14 text-darkcolor">
                USER
              </th>
              <th scope="col" className="font-size-14 text-darkcolor">
                REGISTERED ON
              </th>
              <th scope="col" className="font-size-14 text-darkcolor">
                REGISTRATION WITH
              </th>
              <th scope="col" className="font-size-14 text-darkcolor">
                SUBSCRIPTION STATUS
              </th>
              <th
                scope="col"
                className="font-size-14 text-darkcolor d-flex justify-content-center"
              >
                ACTION
              </th>
            </tr>
          </thead>
          <tbody className="align-middle">
            {users &&
              users.map((user, index) => (
                <tr key={user.id}>
                  <td className="gray-700 font-size-16">{index + 1}</td>{" "}
                  {/* Start from 1 */}
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        src={
                          user?.additionalInfo?.profileImage
                            ? process.env.REACT_APP_S3_BUCKET +
                              user?.additionalInfo?.profileImage
                            : personPlaceholder
                        }
                        alt="person"
                        className="object-fit-cover rounded-circle"
                        width={"48px"}
                        height={"48px"}
                      />
                      <span className="gray-700 font-size-16 text-uppercase">
                        {user?.firstName} {user?.lastName}
                      </span>
                    </div>
                  </td>
                  <td className="gray-700 font-size-16">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="gray-700 font-size-16">
                    {user?.socialConnect?.google && "Youtube"}
                    {user?.socialConnect?.facebook && "Facebook"}
                  </td>
                  <td className="gray-700 font-size-14 text-center">
                    <span
                      className={`badge p-2 text-secondary font-size-14 fw-normal rounded-pill ${
                        user.dataStatus === "active"
                          ? "light-green-bg"
                          : "primary-light-red"
                      }`}
                    >
                      {user.dataStatus}
                    </span>
                  </td>
                  <td className="gray-700 font-size-16">
                    <div className="d-flex align-items-center">
                      <span className="ms-2 pe-2">
                        {user.dataStatus === "block" ? "Unblock" : "Block"}
                      </span>
                      <Switch
                        disabled={disabled}
                        checked={user.dataStatus !== "active"}
                        onChange={() =>
                          handleChange({
                            action:
                              user.dataStatus === "active"
                                ? "disable"
                                : "enable",
                            id: user._id,
                          })
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#d9534f"
                        offColor="#198754"
                      />
                    </div>
                  </td>
                  <td scope="col" className="align-middle">
                    <div className="d-flex gap-2 justify-content-center">
                      <button className="btn btn-link p-0">
                        <Link to={`/userprofile/${user._id}`}>
                          <i className="fa-light fa-eye gray-700 font-size-16" />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
