import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { Link, useLocation, useParams } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import { useSelector } from "react-redux"
import personPlaceholder from "assets/images/personPlaceholder.png"
import Placeholders from "pages/Home/components/Placeholders"

export default function AlVideos() {
  const { id } = useParams()
  const [active, setActive] = useState("all")
  const location = useLocation()
  const queryString = location.search
  const { currentUser } = useSelector(state => state.auth)

  const params = new URLSearchParams(queryString)

  const [videoData, setVideoData] = useState([])
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const getFetch = async (nextPage = 1) => {
    const submitted = params.get("myvideos")
    let resp
    if (params.has("myvideos")) {
      resp = await get(
        `${endpoints.getVideos}?page=${nextPage}&limit=4${
          submitted == "true" ? `&dataId=${id}&filter=user` : ""
        }`
      )
    } else if (params.has("myaward")) {
      resp = await get(`${endpoints.getVideos}/winner/my/${id}`)
    } else {
      resp = await get(
        `${endpoints.getVideos}?page=${nextPage}&limit=4&filter=active`
      )
    }
    /*  resp = params.has("myvideos")
      ? await get(
          `${endpoints.getVideos}?page=${nextPage}&limit=4${
            submitted == "true" ? `&dataId=${currentUser?._id}&filter=user` : ""
          }`
        )
      : await get(
          `${endpoints.getVideos}/winner/my/${currentUser?._id}?page=${nextPage}&limit=10`
        ) */
    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1
    getFetch(nextPage)
  }

  useEffect(() => {
    getFetch()
  }, [])

  return (
    <section id="user-profile" className="page-content bg-light">
      <Container fluid>
        <div className="d-flex flex-column    position-relative z-1">
          {params.has("myaward") && (
            <h2 className="text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase">
              My Awards
            </h2>
          )}
          {params.has("myvideos") && (
            <h2 className="text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase">
              My Videos
            </h2>
          )}
          {!(params.has("myaward") || params.has("myvideos")) && (
            <h2 className="text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase">
              All Videos
            </h2>
          )}
        </div>

        {!params.has("myaward") && (
          <Row className=" ">
            <Col xs="12">
              <Row className="mt-2 row-gap-3 mb-5">
                {videoData?.length > 0 &&
                  videoData.map(card => (
                    <Col sm={6} lg={4} xxl={3} className="mb-3" key={card.id}>
                      <VideoCard data={card} />
                    </Col>
                  ))}
              </Row>
              {loadMore.lastPage !== loadMore.currentPage && (
                <Row className="justify-content-center mb-5 pb-5">
                  <div className="text-center">
                    <button
                      className="text-uppercase font-size-14 btn btn-primary-green"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </button>
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        )}
        <div className="row">
          {params.has("myaward") && (
            <Col className="col-12">
              <div
                className={`${loadMore ? "" : "scrollContainer"} overflow-auto`}
              >
                <div
                  className={
                    loadMore
                      ? "customGrid"
                      : videoData.length > 8
                      ? "custom-gallery"
                      : videoData.length >= 1
                      ? "customGrid"
                      : "h-25"
                  }
                >
                  {videoData.length >= 1 ? (
                    videoData.map((el, index) => (
                      <Link
                        to={`/videodetail/${el?.videoId?._id}`}
                        key={index}
                        className="position-relative mItem image-container"
                      >
                        {}
                        <img
                          src={`${process.env.REACT_APP_S3_BUCKET}${el?.videoId?.videoDetail?.thumbnail?.path}`}
                          alt="pic"
                          className={`img-fluid w-100 rounded-4 ${
                            (loadMore || videoData.length <= 8) &&
                            "videoCard-thumbnail object-fit-cover"
                          }`}
                        />
                        <div className="position-absolute top-0 start-0 ms-3">
                          <div
                            className="position-absolute top-0 rounded-pill mt-1 ms-2 py-1 icon-container"
                            style={{
                              backgroundColor: "#eaf6efcc",
                            }}
                          >
                            <Link to={`/userprofile/${el?.userId?._id}`}>
                              {el?._id ? (
                                <img
                                  src={
                                    process.env.REACT_APP_S3_BUCKET +
                                      el?.videoId?.createdBy?.additionalInfo
                                        ?.profileImage || personPlaceholder
                                  }
                                  alt="icon"
                                  width={40}
                                  height={40}
                                  className="rounded-circle"
                                />
                              ) : (
                                {
                                  /* <Placeholder
                             style={{ width: 40, height: 40 }}
                             className="rounded-circle"
                           /> */
                                }
                              )}
                            </Link>
                            <span className="text-black mx-1 font-size-15 font-weight-500 text-nowrap icon-title">
                              <Link to={`/userprofile/${el?.userId?._id}`}>
                                {currentUser.firstName} {currentUser.lastName}
                              </Link>
                            </span>
                          </div>
                          <div className="position-absolute mt-5">
                            <div className="icon-text-overlay text-uppercase font-size-14 text-center  mt-3">
                              {el.tag}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <Placeholders type="winner" />
                  )}
                </div>
              </div>
            </Col>
          )}
        </div>
      </Container>
    </section>
  )
}
