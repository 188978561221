import React from "react"

const ShowingCount = ({ currentPage = 0, count = 0, itemsPerPage = 0 }) => {
  // const startItem = (data.currentPage - 1) * itemsPerPage + 1
  const endItem = Math.min(currentPage * itemsPerPage, count)

  return <div>{`Showing ${endItem} of ${count}`}</div>
}

export default ShowingCount
