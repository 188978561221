import React from "react"
import TermCondition from "./components/TermCondition"

export default function Term() {
  return (
    <div>
      <TermCondition />
    </div>
  )
}
