import React, { useEffect, useState } from "react"
import { Badge, Button, Container, Progress, Row } from "reactstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import "bootstrap/dist/css/bootstrap.min.css"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import styles from "./VideoDetailPage.module.css"
import { WinnerModal } from "./WinnerModal/WinnerModal"
import { AwardDetailsModal } from "./WinnerModal/AwardDetailsmodal"

import icon from "assets/images/radius-icon.svg"
import { post, get, put, del } from "api/axios"
import endpoints from "api/endpoints"
import { useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import TimeAgo from "react-timeago"
import VideoCommentListing from "./Commenting/VideoCommentListing"
import VideoVoted from "pages/Home/components/VideoVoted"
import DeleteModal from "components/Common/DeleteModal"
import ShowMoreText from "../../../../components/Common/ShowMoreText"
import { toast } from "react-toastify"
import user4 from "assets/images/users/avatar-4.jpg"
import personPlaceholder from "assets/images/personPlaceholder.png"
import { Spinner } from "reactstrap" // Import spinner for loading

const VideoDetailPage = () => {
  const location = useLocation()
  const queryString = location.search
  const params = new URLSearchParams(queryString)
  const [isLoading, setIsLoading] = useState(true) // Add loading state

  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(params.get("winner") ? true : false)
  const [videoData, setVideoData] = useState([])
  const [awarded, setAwarded] = useState(null)

  const { currentUser } = useSelector(state => state.auth)
  const { id } = useParams()
  const isAdmin = currentUser?.userRole == "admin" ? true : false

  function handleClick() {
    setIsOpen(true)
  }
  function handleClick2() {
    setIsOpen2(true)
  }
  function toggle() {
    setIsOpen(!isOpen)
  }
  function toggle2() {
    setIsOpen2(!isOpen2)
  }
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  const handlePublish = async () => {
    let response = await put(`${endpoints.getVideos}/monitize/`, {
      videoId: videoData[index]?._id,
      userId: videoData[index]?.createdBy?._id,
      dataStatus: "ACTIVE",
    })
    if (response.status === 201) {
      toast.success("VIDEO APPROVED", {
        position: "bottom-center",
        theme: "colored",
      })
      getFetch() // Refetch after publishing
    }
  }
  const getFetch = async () => {
    setIsLoading(true) // Set loading to true when fetching data

    const resp = await get(`${endpoints.getVideos}/${id}`)
    if (resp?.data?.success) {
      setVideoData(resp?.data?.data)
      getVideoAward(
        resp?.data?.data?.at(0)?._id,
        resp?.data?.data?.at(0)?.createdBy?._id
      )
    }
    setIsLoading(false) // Set loading to false after data is fetched
  }
  const [deleteModal, setDeleteModal] = useState(false)
  const navigate = useNavigate()

  const contentDeleteHandler = async () => {
    const resp = await del(`${endpoints.getVideos}/${id}`)
    if (resp?.data?.success) {
      navigate(-1)
    }
  }

  const getVideoAward = async (videoId, userId) => {
    const resp = await get(`${endpoints.getVideos}/winner/${videoId}/${userId}`)
    if (resp?.data?.success) {
      setAwarded(resp?.data?.data)
    }
  }

  useEffect(() => {
    getFetch()
  }, [id, isOpen, isOpen2])

  return (
    <section id="video-detail">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={contentDeleteHandler}
        onCloseClick={() => setDeleteModal(false)}
      />
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
        >
          <Spinner
            color="primary"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          >
            Loading...
          </Spinner>{" "}
        </div>
      ) : (
        <Container fluid>
          <Row className="mb-2">
            <div className="col-md-4 col-sm-6">
              <div className="mt-3 mb-2">
                {isAdmin && (
                  <>
                    {"Submited on: "}
                    <TimeAgo date={videoData[index]?.updatedAt || new Date()} />
                  </>
                )}
                <h2
                  style={{ overflowX: "hidden" }}
                  className={`${styles["custom-text-color"]} mb-0 ff-barlow fw-medium font-size-24 d-flex justify-content-between`}
                >
                  {videoData[index]?.title}
                  {currentUser?._id === videoData[index]?.createdBy?._id &&
                    awarded?.award === undefined && (
                      <span>
                        <Button
                          color="success"
                          outline
                          className="mx-2 btn-primary-green-outline mt-1"
                          onClick={() =>
                            navigate(`/edit-video/${videoData[index]?._id}`)
                          }
                        >
                          <i
                            className="fa-light fa-edit font-size-18"
                            id="deletetooltip-mobile-0"
                          />
                        </Button>
                        <Button
                          color="danger"
                          outline
                          className="mx-2 btn-primary-red-outline mt-1"
                          onClick={() => setDeleteModal(true)}
                        >
                          <i
                            className="fa-light fa-trash font-size-20"
                            id="deletetooltip-mobile-0"
                          />
                        </Button>
                      </span>
                    )}
                </h2>

                <p
                  className={`${styles["text-para"]} mt-1 mb-0 ff-barlow font-size-16 fw-normal py-2`}
                >
                  <ShowMoreText
                    text={`${videoData[index]?.description}`}
                    lineBreak={true}
                  />
                </p>

                <div
                  className={`${styles["custom-border-color"]} w-100 `}
                ></div>
                {/* <span>Video Tags:</span> */}
                {videoData[index]?.tags?.map((tag, index) => (
                  <Badge
                    key={index}
                    className="bg-white fw-medium font-size-16 text-secondary me-1 py-3"
                  >
                    #{tag}
                  </Badge>
                ))}

                <div
                  className={`${styles["custom-border-color"]} w-100 mb-3`}
                ></div>

                <div className="row mt-1  ">
                  <div className="col-3 col-md-3 col-sm-4 col-lg-2 d-flex  justify-content-center">
                    <div className="w-100">
                      {videoData[index]?.createdBy?.additionalInfo
                        ?.profileImage ? (
                        <img
                          className="rounded-circle "
                          width={50}
                          height={50}
                          style={{ objectFit: "cover" }}
                          src={
                            videoData[0]?.createdBy?.additionalInfo
                              ?.profileImage
                              ? process.env.REACT_APP_S3_BUCKET +
                                videoData[index]?.createdBy?.additionalInfo
                                  ?.profileImage
                              : personPlaceholder
                          }
                          alt="Header Avatar"
                        />
                      ) : (
                        <div className="">
                          <img
                            src={personPlaceholder}
                            alt="icon"
                            width={50}
                            height={50}
                            className="rounded-circle object-fit-cover"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-9 col-md-9 col-sm-8 col-lg-10   d-flex align-items-center ">
                    <div className="w-100">
                      <Link
                        to={`/userprofile/${videoData[index]?.createdBy?._id}`}
                      >
                        <h4 className={`mb-0 font-size-15 fw-medium text-dark`}>
                          {videoData[index]?.createdBy?.firstName}{" "}
                          {videoData[index]?.createdBy?.lastName}
                        </h4>
                      </Link>
                      <p
                        className={`${styles["para-text"]} text-secondary font-size-12`}
                      >
                        <TimeAgo
                          date={
                            videoData[index]?.createdBy?.createdAt || new Date()
                          }
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <h3
                  className={`${styles["votes-text"]} font-size-18 fw-medium text-uppercase`}
                ></h3>
                {videoData[index]?.dataStatus === "ACTIVE" && (
                  <div>
                    {isAdmin ? "UPVOTES" : "UPVOTES"}

                    <VideoVoted
                      videoId={videoData[index]?._id}
                      isLiked={videoData[index]?.isLiked}
                      likeCount={videoData[index]?.likeCount}
                      userVoteType={videoData[index]?.userVoteType}
                      voteTypes={videoData[index]?.voteTypes}
                      showShare={true}
                      videoTitle={videoData[index]?.title}
                      videoDescription={videoData[index]?.description}
                      videoTags={videoData[index]?.tags?.map(tag => `#${tag} `)}
                      videoThundnail={`${process.env.REACT_APP_S3_BUCKET}${videoData[index]?.thumbnail}`}
                      downloadButton={
                        <button
                          onClick={() => {
                            const videoUrl = `${process.env.REACT_APP_S3_BUCKET}${videoData[index]?.videoDetail}`

                            if (isIOS) {
                              window.open(videoUrl, "_blank")
                            } else {
                              const link = document.createElement("a")
                              link.href = videoUrl
                              link.setAttribute("download", true)
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)
                            }
                          }}
                          className="mx-2 btn btn-outline-dark"
                          style={{ outline: "none", boxShadow: "none" }}
                          onMouseDown={e => e.preventDefault()}
                        >
                          <i
                            className="fa-light fa-download font-size-16"
                            id="deletetooltip-mobile-0"
                          />
                        </button>
                      }
                    />

                    {isAdmin && !awarded && (
                      <div className="mt-4">
                        <button
                          className="btn btn-primary-green"
                          onClick={() => setIsOpen(true)}
                        >
                          ANNOUNCE AS WINNER
                        </button>
                        <WinnerModal
                          isOpen={isOpen}
                          toggle={() => setIsOpen(!isOpen)}
                          videoDetail={videoData[index]}
                        />
                      </div>
                    )}
                    <WinnerModal
                      isOpen={isOpen}
                      toggle={toggle}
                      videoDetail={videoData[index]}
                    />
                    {awarded &&
                      (isAdmin ||
                        currentUser?._id ===
                          videoData[index]?.createdBy?._id) && (
                        <div className="d-flex flex-column mt-4 gap-2">
                          <span className="font-size-18 fw-medium text-darkcolor">
                            AWARD DETAILS
                          </span>
                          <div className="d-flex flex-column gap-0">
                            <span className="font-size-16">AWARDED WITH</span>
                            <span className="font-size-26 pre-text">
                              {awarded?.awardType}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <hr className="gray-700 m-0" />
                            <span
                              className="cursor-pointer font-size-14 pre-text fw-medium"
                              onClick={() => setIsOpen2(true)}
                            >
                              VIEW MORE DETAILS
                            </span>
                          </div>
                          <AwardDetailsModal
                            isOpen={isOpen2}
                            toggle={() => setIsOpen2(!isOpen2)}
                            awardDetail={awarded}
                          />
                        </div>
                      )}
                  </div>
                )}

                {videoData[index]?.dataStatus === "PENDING" && (
                  <div>
                    {isAdmin && (
                      <div className=" mt-4 d-flex gap-3">
                        <button
                          className="btn btn-primary-green text-uppercase"
                          onClick={handlePublish}
                        >
                          publish video
                        </button>
                        <button
                          className="btn btn-primary-red-outline text-uppercase"
                          onClick={handleClick}
                        >
                          reject video
                        </button>
                        <WinnerModal
                          isOpen={isOpen}
                          toggle={toggle}
                          videoDetail={videoData[index]}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4   col-sm-6">
              <div className="mt-1 ">
                <div className="image-container position-relative">
                  <div className="h-100 w-100 d-flex justify-content-center">
                    <video
                      src={`${process.env.REACT_APP_S3_BUCKET}${videoData[index]?.videoDetail}`}
                      poster={`${process.env.REACT_APP_S3_BUCKET}${videoData[index]?.thumbnail}`}
                      controls
                      playsInline={true}
                      autoPlay={true}
                      muted={true}
                      className={`img-fluid rounded-5 w-75 h-50 object-fit-cover`}
                      style={{ width: "50%" }}
                    >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div
                    className={`position-absolute start-50 translate-middle-x d-flex top-0 justify-content-center align-items-center ${styles["icon-container"]}`}
                  >
                    <div
                      className={`${styles["icon-wrapper"]} position-relative d-flex justify-content-center align-item-center`}
                    >
                      <img
                        className={`${styles["icon-position"]} mb-3`}
                        src={icon}
                        alt="icon"
                      />
                      <i
                        className={`${styles["icon-style"]} fw-normal mb-4 font-size-12 position-absolute`}
                      >
                        {videoData.at(index)?.createdBy?.firstName}{" "}
                        {videoData.at(index)?.createdBy?.lastName}
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <VideoCommentListing
              videoId={id}
              status={videoData[index]?.dataStatus}
              info={videoData[index]?.additionalInfo}
              rules={videoData[index]?.topicId?.rules}
            />
          </Row>
        </Container>
      )}
    </section>
  )
}

export default VideoDetailPage
