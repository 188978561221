import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import AnimatedText from "components/animation/AnimatedText/AnimatedText"
import { Bounce, toast } from "react-toastify"
import { useSelector } from "react-redux"
import FooterImg from "../../../assets/images/dockIcon/footer.png"
import ThedockiesImg from "../../../assets/images/dockIcon/thedockies.svg"

const SubmitYourVideo = () => {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const topic = localStorage.getItem("topic")

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const getLastDayAndThreeDaysBefore = () => {
    const date = new Date()
    const currentMonth = date.getMonth()
    const currentYear = date.getFullYear()

    const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate()

    const threeDaysBefore = lastDay - 3
    const lastDayWithSuffix = lastDay === 31 ? `${lastDay}st` : `${lastDay}th`

    return {
      lastDay: lastDayWithSuffix,
      threeDaysBefore,
      monthName: monthNames[currentMonth],
    }
  }

  const { lastDay, threeDaysBefore, monthName } = getLastDayAndThreeDaysBefore()

  const handleSubmitClick = () => {
    if (isAuth) {
      navigate(`/challenge-detail/${topic}`)
    } else {
      toast.error("Please Sign in to Submit Your Video.", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login")
    }
  }

  return (
    <section id="submit-your-video" className=" bg-light">
      <Container fluid className="py-5 position-relative">
        <img
          src={ThedockiesImg}
          alt={"Footer Image"}
          className="img-fluid floatingBottomImg"
        />
        <Row className=" align-items-center">
          <Col xs="12" lg={7} className="text-left d-flex  px-5">
            <div className="d-flex flex-column ">
              <h2 className="fw-bold font-size-56 text-uppercase text-darkcolor">
                <ShuffleText text="You could be featured next month!" />
              </h2>
              <p className=" text-secondary font-size-15 font-weight-500 py-3 m-0 ">
                <AnimatedText
                  // text={[
                  //   `Post and Vote till ${monthName} ${threeDaysBefore}th! Winners Announced ${monthName} ${lastDay}!`,
                  // ]}
                  text={[`Join The Dockies`]}
                  el="span"
                />
                <br />
                The fist online dad joke community where YOU can share your best
                dad jokes with the Dock Tok world.
                <br />
                Connect with other dad joke enthusiasts, get your daily dose of
                laughter, and compete in monthly joke competitions to win
                awesome prizes.
                <br />
                ALL winners are featured on the Dock Tok social media
                <b>
                  <i>@loganlisle</i>
                </b>
                !
                <br />
                Submit by:
                <b>
                  {monthName} {threeDaysBefore}th
                </b>
                <br />
                Voting closes:
                <b>
                  {monthName} {threeDaysBefore}th
                </b>
                <br />
                Winner's Announced:
                <b>
                  {monthName} {lastDay}
                </b>
              </p>
              {topic && (
                <Link to={`/challenge-detail/${topic}`}>
                  <button
                    className="text-uppercase mt-3 font-size-14 font-weight-400 btn btn-primary-green"
                    onClick={handleSubmitClick}
                  >
                    Submit your video now
                  </button>
                </Link>
              )}
            </div>
          </Col>
          <Col xs="12" lg={4} className="text-left">
            <img src={FooterImg} alt={"Footer Image"} className="img-fluid" />
          </Col>
          <Col
            md={1}
            className={
              "d-flex justify-content-center align-items-center d-sm-none d-md-none d-none  blog-text-container"
            }
          >
            <p className={" text-uppercase  font-size-140 vert_text_90"}>
              Dockies
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SubmitYourVideo
