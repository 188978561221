import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import play1 from "assets/images/home/play.svg"
import play from "assets/images/circle-bg.png"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import { Link } from "react-router-dom"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import AnimatedText from "components/animation/AnimatedText/AnimatedText"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast, Bounce } from "react-toastify"

const HowDockTokkWorks = () => {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()

  const handleJoinClick = () => {
    if (isAuth) {
      navigate("/dashboard") // Redirect to dashboard
    } else {
      toast.error("Please sign in to continue", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login") // Redirect to homepage
    }
  }
  const [blogs, setBlogs] = useState([])

  const contentHandler = async () => {
    const resp = await get(`${endpoints.blog}?page=1&limit=3&filter=featured`)
    if (resp?.data?.success) {
      setBlogs(resp?.data?.data)
    }
  }

  function getText(html) {
    var divContainer = document.createElement("div")
    divContainer.innerHTML = html
    return divContainer.textContent || divContainer.innerText || ""
  }

  useEffect(() => {
    contentHandler()
  }, [])

  const buttonLabels = [
    "SUBSCRIBE ON SOCIAL MEDIA",
    "CONNECT YOUR ACCOUNT",
    "SIGN IN TO THE DOCKIES",
    "I’M READY TO POST MY JOKES",
  ]

  return (
    <section id="how-dock-tok-work" className="py-5  p-sm-0 ">
      <Container fluid className="py-0 py-lg-5  px-sm-2">
        <Row className="justify-content-center">
          <Col xs="12">
            <Row className="justify-content-start">
              <Col md={12}>
                <h2 className="ff-cooper fw-normal font-size-56  text-uppercase pb-3 black-text-color">
                  how dockies works
                </h2>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            md={1}
            className={
              "d-flex justify-content-center align-items-center d-sm-none d-md-none d-none  blog-text-container"
            }
          >
            <p
              className={
                " ff-cooper fw-normal font-size-80 text-uppercase   vert_text"
              }
              style={{ color: "#AFDBC3", opacity: "50%" }}
            >
              We Are Dockies
            </p>
          </Col>
          <Col md={11} sm={12}>
            <Row className="pt-2 pb-2 w-100 ps-4">
              {blogs.length > 0 ? (
                blogs.map((key, index) => {
                  let temp = index + 1
                  return (
                    <Col
                      lg={12}
                      md={12}
                      sm={6}
                      key={index}
                      className="d-flex flex-column mb-4"
                    >
                      <Row className="align-items-center">
                        {temp % 2 == 0 && (
                          <Col
                            lg={3}
                            md={4}
                            className={" d-none d-sm-none d-md-block"}
                          ></Col>
                        )}
                        <Col lg={3} md={4} sm={6} xs={12}>
                          <Link>
                            <div className="position-relative  w-100 ms-2 ms-md-0">
                              {key?.videoDetail?.thumbnail?.path ? (
                                <img
                                  src={`${process.env.REACT_APP_S3_BUCKET}${key?.videoDetail?.thumbnail?.path}`}
                                  alt="blog"
                                  className="rounded-4 img-fluid w-100 object-fit-cover blog-Img"
                                  style={{
                                    maxHeight: "280px",
                                    maxWidth: "220px",
                                  }}
                                />
                              ) : (
                                <video className="rounded w-100 h-100"></video>
                              )}
                              {/* Border Animation */}
                              <div
                                className="border-animation "
                                style={{
                                  maxHeight: "310px",
                                  maxWidth: "240px",
                                }}
                              >
                                {" "}
                                <div
                                  className="circle"
                                  style={{
                                    maxHeight: "310px",
                                    maxWidth: "240px",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={6} md={temp % 2 != 0 ? 6 : 4} sm={12} xs={12}>
                          <Link
                            to={`/how-it-works-details/${key?._id}`}
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="  w-100">
                              <div>
                                <p
                                  style={{ color: "#AFDBC3" }}
                                  className={
                                    "font-size-56 ff-proxima fw-bold mt-2 mt-lg-0"
                                  }
                                >
                                  0{temp}
                                </p>
                                <h3 className="ff-proxima font-size-32 fw-bolder text-uppercase  black-text-color mb-2">
                                  {getText(key.title).length > 80
                                    ? getText(key.title).slice(0, 80) + "..."
                                    : getText(key.title).slice(0, 80)}
                                </h3>
                                <p className="  font-size-16 gray-700">
                                  {getText(key.content).length > 200
                                    ? getText(key.content).slice(0, 200) + "..."
                                    : getText(key.content).slice(0, 200)}
                                </p>
                              </div>
                              {/* <button className="btn btn-primary-black-outline text-uppercase font-size-14 mb-3">
                                {buttonLabels[index]}
                              </button> */}
                            </div>
                          </Link>
                        </Col>
                        {temp % 2 != 0 && (
                          <Col
                            lg={3}
                            md={2}
                            className={" d-none d-sm-none d-md-block"}
                          ></Col>
                        )}
                      </Row>
                    </Col>
                  )
                })
              ) : (
                <h3 className="text-center">No Content Found!</h3>
              )}
              <Col xs={12} className={"text-center "}>
                {" "}
                <button
                  className="text-uppercase font-size-14 btn btn-primary-yellow ff-proxima fw-bolder"
                  onClick={handleJoinClick}
                >
                  I want to be a Dockie
                </button>
              </Col>
            </Row>{" "}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HowDockTokkWorks
