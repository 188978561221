import React, { useEffect, useState } from "react"
import { Alert, Col, Container, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"

export default function VideosByDockies() {
  const tabsMenu = [
    "all",
    "dads jokes",
    "new and trending",
    "best seller",
    "independence",
    "heartthrobs",
  ]

  const [videoData, setVideoData] = useState([])
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1780)

  const getFetch = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}?page=${nextPage}&filter=activeTopic&limit=${
        isWideScreen ? 8 : 4
      }&status=active`
    )

    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1
    getFetch(nextPage)
  }

  useEffect(() => {
    setIsWideScreen()
    getFetch()
  }, [])

  return (
    <div className="card rounded-4 p-3 shadow">
      <Row className=" justify-content-center">
        <Col xs="12">
          <Row>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-2">
              <div className="d-flex gap-2 flex-column flex-md-row align-items-center">
                <div>
                  <span className="fw-medium font-size-18 text-darkcolor">
                    VIDEOS POSTED BY DOCKIES
                  </span>
                </div>
                <div>
                  <select
                    className="custom-select rounded-pill gray-700 font-size-14 bg-skin p-2 mb-2 mb-md-0"
                    id="customSelect"
                    style={{ border: "none" }}
                  >
                    <option defaultValue>Filter By Catagories</option>
                    <option value="1">Last Month</option>
                    <option value="2">Last Three Month</option>
                    <option value="3">Last Year</option>
                    <option value="4">Older</option>
                  </select>
                </div>
              </div>
              <div>
                {videoData.length > 0 && (
                  <Link
                    to={"/videovaults"}
                    className="text-uppercase pre-text fw-medium font-size-14 ff-barlow"
                  >
                    view all
                  </Link>
                )}
              </div>
            </div>
          </Row>
          <Row className="mt-2 row-gap-3 mb-5">
            {videoData?.length > 0 ? (
              videoData.map(card => (
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  xxl={3}
                  className="mb-3"
                  key={card.id}
                >
                  <VideoCard data={card} />
                </Col>
              ))
            ) : (
              <Col>
                <Alert className="text-center">No Content Found!</Alert>{" "}
              </Col>
            )}
          </Row>

          {loadMore.lastPage !== loadMore.currentPage && (
            <Row className="justify-content-center mb-5 pb-5">
              <div className="text-center">
                <button
                  className="btn btn-primary-green text-uppercase"
                  onClick={handleLoadMore}
                >
                  Come on, show us what you’ve got!{" "}
                </button>
              </div>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  )
}
