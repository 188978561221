import React, { useState, useEffect } from "react"
import pic1 from "assets/images/home/hero-event.png"
import { Card, CardBody, Col, Table, Alert } from "reactstrap"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"

export default function ProfileRewards({
  userAwardVideo = {},
  isAdmin = false,
  myVideosFlag = false,
  userId,
}) {
  const [videos, setVideos] = useState([])
  const [totalAwards, setTotalAwards] = useState(0)

  useEffect(() => {
    setVideos(userAwardVideo?.data || [])
    setTotalAwards(userAwardVideo?.count || 0)
  }, [userAwardVideo])
  const handleClick = () => {
    window.scroll(0, 0)
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-uppercase font-size-26">
          Awards <span className="pre-text font-size-18">({totalAwards})</span>
        </h4>
        <Link to={`/all-videos/${userId}?myaward=true`} onClick={handleClick}>
          <span className="pre-text text-uppercase ">View All</span>
        </Link>
      </div>
      <Card className="mt-3 rounded-4">
        <CardBody>
          <SimpleBar style={{ maxHeight: "494px" }}>
            <div className="table-responsive">
              <Table className="table-borderless table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>#Id</th>
                    <th>Title</th>
                    {(isAdmin || myVideosFlag) && (
                      <>
                        <th>Challenges</th>
                        <th>Reward</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {videos.length > 0 ? (
                    videos.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="ff-barlow fw-normal font-size-16 text-secondary">
                            {item?._id.slice(-3)}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <img
                              src={
                                item?.videoId?.videoDetail?.thumbnail?.path
                                  ? `${process.env.REACT_APP_S3_BUCKET}${item?.videoId?.videoDetail?.thumbnail?.path}`
                                  : pic1
                              }
                              className="object-fit-cover rounded-4 me-3"
                              alt="..."
                              width="60"
                              height="100"
                            />
                            <div className="d-flex align-items-center">
                              <h6 className="ff-barlow fw-normal font-size-16 text-secondary">
                                {item?.videoId?.title}
                              </h6>
                            </div>
                          </div>
                        </td>
                        {(isAdmin || myVideosFlag) && (
                          <>
                            <td>
                              <div className="ff-barlow fw-normal font-size-16 text-secondary">
                                {item?.videoId?.topicId?.title || "N/A"}
                              </div>
                            </td>
                            <td>
                              <div className="ff-barlow fw-normal font-size-16 text-secondary">
                                {item?.awardType || "N/A"}
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <Alert className="text-center">No Awards Found</Alert>{" "}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </SimpleBar>
        </CardBody>
      </Card>
    </div>
  )
}
