import React from "react"
import { Navigate, useLocation } from "react-router-dom"

const Authmiddleware = props => {
  const location = useLocation()
  // Construct full URL
  const fullUrl = location.pathname + location.search + location.hash

  if (!localStorage.getItem("accessToken")) {
    return (
      <Navigate
        to={{
          pathname: `/login`,
          search: `?redirect=${encodeURIComponent(fullUrl)}`,
        }}
      />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
