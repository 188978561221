import React from "react"

function FormatDate({ dateStr }) {
  const formatDate = isoString => {
    const date = new Date(isoString)

    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = date.getDate()
    const year = date.getFullYear()

    return `${month}-${day}-${year}`
  }

  return <span>{formatDate(dateStr)}</span>
}

export default FormatDate
