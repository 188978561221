import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { Formik, Form } from "formik"
import pic from "assets/images/home/challenge1.png"
import { Progress } from "reactstrap"
import like from "assets/images/videodetail/like.svg"
import TimeAgo from "react-timeago"
import { post, get, put } from "api/axios"
import endpoints from "api/endpoints"
import * as Yup from "yup"
import { Bounce, toast } from "react-toastify"
import personPlaceholder from "assets/images/personPlaceholder.png"
import { useNavigate } from "react-router-dom"

export const WinnerModal = ({ isOpen, toggle, videoDetail }) => {
  const status = videoDetail?.dataStatus
  const [awards, setAwards] = useState([])
  const [selectawards, setSelectAwards] = useState([])

  const getAward = async () => {
    const resp = await get(`${endpoints.award}`)

    if (resp?.data?.success) {
      setAwards(resp?.data?.data)
    }
  }
  useEffect(() => {
    getAward()
  }, [])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg">
      <div className="d-flex justify-content-end p-2">
        <button
          className="btn d-flex gap-2 align-items-center"
          onClick={toggle}
        >
          <i className="fa-solid fa-dash" />
          <span className="font-size-14 text-darkcolor fw-medium">Close</span>
        </button>
      </div>
      <ModalBody className="px-5">
        <Formik
          enableReinitialize
          initialValues={
            status === "PENDING"
              ? {
                  comment: "",
                  videoId: videoDetail?._id,
                  userId: videoDetail?.createdBy?._id,
                  dataStatus: "REJECTED",
                }
              : {
                  award: "",
                  awardType: "",
                  tag: "",
                  videoId: videoDetail?._id,
                  reason: "",
                  userId: videoDetail?.createdBy?._id,
                }
          }
          validationSchema={
            status === "PENDING"
              ? Yup.object({
                  comment: Yup.string().required("Comment is Required!"),
                })
              : Yup.object({
                  award: Yup.string().required("Award is Required!"),
                  awardType: Yup.string().required("Award Type is Required!"),
                  reason: Yup.string().required("Please Enter a Reason!"),
                  tag: Yup.string().required("Please Enter a TAG!"),
                })
          }
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            let response = await (status === "ACTIVE"
              ? post(`${endpoints.getVideos}/winner/`, values)
              : put(`${endpoints.getVideos}/monitize/`, values))

            if (response.status === 201) {
              toast.success(
                `${
                  status === "PENDING" ? "Video Rejected" : "Winner Announced"
                } Successfully`,
                {
                  position: "bottom-center",
                  theme: "colored",
                  transition: Bounce,
                }
              )
              toggle()
              resetForm()
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            formProps,
            setValues,
            /* and other goodies */
          }) => (
            <Form method="post" className="wysiwyg-custom">
              <div className="d-flex flex-column gap-3">
                <span className="font-size-26 text-darkcolor fw-medium">
                  {status === "ACTIVE" && "ANNOUNCE AS WINNER"}
                  {status === "PENDING" && "REJECT VIDEO"}
                </span>
                {status === "ACTIVE" && (
                  <div>
                    <div>
                      <Label className="form-label">Award Type</Label>
                      <Input
                        name="awardType"
                        value={values.awardType || ""}
                        type="select"
                        placeholder="Reason For Announcing Winner"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={
                          touched.awardType && errors.awardType ? true : false
                        }
                      >
                        <option defaultValue key={"defaultValue"}>
                          Select Award Type
                        </option>
                        {awards.length > 0 &&
                          awards.map((award, index) => (
                            <option key={index}>{award?._id}</option>
                          ))}
                      </Input>
                      {touched.awardType && errors.awardType ? (
                        <FormFeedback type="invalid">
                          {errors.awardType}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {values.awardType != "" && (
                      <div>
                        <Label className="form-label">Award</Label>
                        <Input
                          name="award"
                          value={values.award || ""}
                          type="select"
                          placeholder="Reason For Announcing Winner"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={touched.award && errors.award ? true : false}
                        >
                          <option defaultValue key={"defaultValue"}>
                            Select Award
                          </option>
                          {awards
                            .filter(group => group?._id === values.awardType)
                            .flatMap(group => group.awards)
                            .map(award => (
                              <option key={award?._id}>{award.award}</option>
                            ))}
                        </Input>
                        {touched.award && errors.award ? (
                          <FormFeedback type="invalid">
                            {errors.award}
                          </FormFeedback>
                        ) : null}
                      </div>
                    )}
                    <div>
                      <Label className="form-label">Reason</Label>
                      <Input
                        name="reason"
                        value={values.reason || ""}
                        type="text"
                        placeholder="Reason For Announcing Winner"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.reason && errors.reason ? true : false}
                      />

                      {touched.reason && errors.reason ? (
                        <FormFeedback type="invalid">
                          {errors.reason}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div>
                      <Label className="form-label">Award Tag</Label>
                      <Input
                        name="tag"
                        value={values.tag || ""}
                        type="select"
                        placeholder="Reason For Announcing Winner"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.tag && errors.tag ? true : false}
                      >
                        <option value={""}>Select a Tag</option>
                        <option value="Parent / Child Combo" key="1">
                          Parent / Child Combo
                        </option>
                        <option value="Best Background" key="2">
                          Best Background
                        </option>
                        <option value="Most Funny" key="3">
                          Most Funny
                        </option>
                        <option value="Best Mustache" key="4">
                          Best Mustache
                        </option>
                      </Input>
                      {touched.tag && errors.tag ? (
                        <FormFeedback type="invalid">{errors.tag}</FormFeedback>
                      ) : null}
                    </div>
                  </div>
                )}
                {status === "PENDING" && (
                  <div>
                    <Label className="form-label">Reason</Label>
                    <Input
                      name="comment"
                      value={values.comment || ""}
                      type="text"
                      placeholder="Reason For Rejecting Video"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.comment && errors.comment ? true : false}
                    />
                    {touched.comment && errors.comment ? (
                      <FormFeedback type="invalid">
                        {errors.comment}
                      </FormFeedback>
                    ) : null}
                  </div>
                )}
                <div>
                  <span className="font-size-18 fw-medium text-darkcolor">
                    USER DETAILS
                  </span>
                </div>
                <div className="d-flex flex-column gap-2 gap-md-5 flex-md-row align-items-md-center">
                  <div className="d-flex gap-3">
                    <img
                      className="rounded-circle "
                      width={60}
                      height={60}
                      style={{ objectFit: "cover" }}
                      src={
                        videoDetail?.createdBy?.additionalInfo?.profileImage
                          ? process.env.REACT_APP_S3_BUCKET +
                            videoDetail?.createdBy?.additionalInfo?.profileImage
                          : personPlaceholder
                      }
                      alt="Header Avatar"
                    />
                    <div className="d-flex gap-1 flex-column">
                      <span className="font-size-15 fw-medium text-darkcolor">
                        {videoDetail?.createdBy?.firstName}{" "}
                        {videoDetail?.createdBy?.lastName}
                      </span>
                      <span className="font-size-12">
                        <TimeAgo
                          date={videoDetail?.createdBy?.createdAt || new Date()}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column ps-md-5 text-darkcolor">
                    <span className="font-size-16">
                      Email:{" "}
                      <span className="gray-700">
                        {videoDetail?.createdBy?.email}
                      </span>
                    </span>
                  </div>
                </div>
                <div>
                  <span className="font-size-18 fw-medium">VIDEO DETAILS</span>
                  <Row className="gx-4">
                    <Col sm="12" lg="3">
                      <img
                        src={
                          videoDetail?.thumbnail
                            ? `${process.env.REACT_APP_S3_BUCKET}${videoDetail?.thumbnail}`
                            : pic
                        }
                        alt="challenges-card"
                        className="h-100 w-100 rounded-4 object-fit-cover"
                      />
                    </Col>
                    <Col sm="12" lg="9">
                      <div className="d-flex flex-column gap-2 h-100">
                        <span>
                          <span className="text-primary">Submited on: </span>

                          <TimeAgo date={videoDetail?.updatedAt} />
                        </span>
                        <span className="font-size-26 text-uppercase pre-text ">
                          "{videoDetail?.title}"
                        </span>
                        <span className="font-size-16 gray-700">
                          {videoDetail?.description}
                        </span>
                        <div className="d-flex flex-column flex-sm-row gap-2 custom-border">
                          {videoDetail?.tags?.map((catagory, index) => (
                            <span
                              className="gray-700 font-size-16 "
                              key={index}
                            >
                              #{catagory}
                            </span>
                          ))}
                        </div>
                        {status === "ACTIVE" && (
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-medium font-size-15 text-darkcolor">
                              UPVOTES
                            </span>
                            <div className="d-flex  align-items-center">
                              <button className="btn pt-0" type="button">
                                <img
                                  src={like}
                                  alt="like"
                                  className="like-dislike-icon-small"
                                />
                              </button>
                              <span className="font-size-12 gray-700">
                                {videoDetail?.likeCount} Upvote
                              </span>
                            </div>
                            <div className="w-50">
                              <Progress
                                className=""
                                color="success"
                                value={`${(videoDetail?.likeCount / 25) * 100}`}
                                style={{ height: "6px", borderRadius: "6px" }}
                              ></Progress>
                            </div>
                          </div>
                        )}

                        <div className="d-flex align-items-center gap-2"></div>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end gap-3 mt-5">
                    <Button
                      className={`btn ${
                        status === "PENDING"
                          ? "btn-primary-red"
                          : "btn-primary-green"
                      }`}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {status === "PENDING"
                        ? "REJECT VIDEO"
                        : "ANNOUNCE AS WINNER"}
                    </Button>

                    <button
                      type="button"
                      className={`btn ${
                        status === "PENDING"
                          ? "btn-primary-red-outline"
                          : "btn-primary-green"
                      }`}
                      onClick={toggle}
                    >
                      DISCARD
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}
