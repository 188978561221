export default {
  // auth
  googleAuthentication: "/auth/google",
  googleAuthenticationConnect: "/auth/google/connect",
  facebookAuthentication: "/auth/facebook",
  instagramToken: "/auth/instagram/token",
  instagramExchangeCode: "/auth/instagram/exchange/code",
  facebookLikesList: "/facebook/like",
  facebookBotGroupMember: "/facebook/subscribe",
  youTubeSubscriptionList: "/google/youtube/subscriptions",
  tiktokAuthentication: "/auth/tiktok/url",
  youtubeMedia: "/auth/google/member",
  youtubeRemove: "/auth/google/disconnect",
  tiktokToken: "/auth/tiktok/token",
  getTopics: "/topic",
  getVideos: "/video",
  voteVideos: "/video/vote",
  login: "/auth/login",
  reset: "/auth/reset",
  register: "register",
  notification: "/notification",
  notificationAll: "/notification/all",
  fcm: "/user/fcm",
  userDetail: "/user",
  blog: "/blog",
  topic: "/topic",
  otp: "/auth/login/otp",
  usersStats: "/user",
  staff: "/staff",
  mail: "/mail",
  award: "/award",
  search: "/search",
  resendOTP: "/auth/resend/2fa",
  getBlogNumber: "/blog/order/",
  deleteMyAccount: "/user/del",
}
