import React, { useState, useEffect } from "react"
import BlockedUsersTable from "./myTable"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import {
  Row,
  Col,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import CheckImg from "assets/images/check.svg"

const BlockedUsers = () => {
  const [getArray, setArray] = useState([])
  const [loading, setLoading] = useState(true)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filter, setFilter] = useState({
    action: "",
    text: "Filter By Category",
  })

  const filters = [
    { text: "Filter Via Youtube", action: "google" },
    { text: "Filter Via Facebook", action: "facebook" },
    { text: "Filter Via Instagram", action: "instagram" },
    { text: "Filter Via TikTok", action: "tiktok" },
    { text: "Blocked Users", action: "block" },
    { text: "Unblocked Users", action: "active" },
    { text: "All Users", action: "" },
  ]

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

  async function getfetch() {
    try {
      const resp = await get(`${endpoints.usersStats}?filter=${filter.action}`)
      if (resp?.data?.success) {
        setArray(resp?.data?.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getfetch()
  }, [filter])

  return (
    <div className="page-content margin-video-vaults bg-skin">
      <Container fluid>
        <div className="d-flex justify-content-between">
          <h1 className="text-uppercase">Users</h1>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            direction={"down"}
          >
            <DropdownToggle
              caret
              className="d-flex justify-content-between align-items-center filter-btn"
            >
              <span>{filter.text}</span>
              <img src={CheckImg} className="px-2" alt="Check Icon" />
            </DropdownToggle>
            <DropdownMenu>
              {filters.map((item, index) => (
                <DropdownItem
                  key={index}
                  onClick={() =>
                    setFilter({ action: item.action, text: item.text })
                  }
                >
                  {item.text}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        {!loading && (
          <BlockedUsersTable
            showUnblockColumn={true}
            actions={["view"]}
            showHeading={true}
            showDeactive={true}
            data={getArray}
            getdata={getfetch}
            setData={setArray}
          />
        )}
      </Container>
    </div>
  )
}

export default BlockedUsers
