import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Route, Routes } from "react-router-dom"
import { connect, useSelector } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes, adminRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { Bounce, toast } from "react-toastify"
// Import Fack Backend
import fakeBackend from "./helpers/AuthType/fakeBackend"
import { getToken, onMessage } from "firebase/messaging"
import { messaging } from "./constants/firebase/firebaseConfig"
import Message from "./components/Common/MessageNotification"
import { useLocation } from "react-router-dom"

// Activating fake backend
fakeBackend()

const App = props => {
  const layoutType = useSelector(state => state.settings.layoutType)
  const { userRole } = useSelector(
    state => state.auth?.currentUser || { userRole: null }
  )
  const location = useLocation()
  const referrer = location.state?.referrer
  function getLayout() {
    let layoutCls = VerticalLayout

    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID,
      })
      localStorage.setItem("fcm", token)
      //We can send token to server
    } else if (permission === "denied") {
      //notifications are blocked
      // alert(
      //   "Thank you for choosing our service! Notifications are currently disabled in your browser settings. If you'd like to stay updated, you can enable them at any time in your browser preferences."
      // )
    }
  }
  onMessage(messaging, payload => {
    console.log(JSON.stringify(payload), "------notification-----------")
    toast(<Message notification={payload.notification} />)
    // toast(payload.notification)
  })
  useEffect(() => {
    var ref = document.referrer
    console.log(`${ref} -- i am coming from any where Doc`)
    requestPermission()
  }, [])
  useEffect(() => {
    console.log(`${referrer} -- i am coming from any where`)
  }, [referrer])
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {userRole == "subscriber"
          ? userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                }
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))
          : adminRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                }
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
      </Routes>
    </React.Fragment>
  )
}

// App.propTypes = {
//   layout: PropTypes.any,
// }

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

// export default connect(mapStateToProps, null)(App);
export default App
