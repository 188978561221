import React from "react"
import { Card, CardBody, Row, Col } from "reactstrap"
import pic1 from "assets/images/home/hero-event.png"
import FormatDate from "../FormatDate"
import personPlaceholder from "assets/images/personPlaceholder.png"

const ProfileCard = ({ isAdmin, userDetail }) => {
  return (
    <Card
      className="rounded-4 d-flex flex-column justify-content-center"
      style={{ height: "85%" }}
    >
      <CardBody className="d-flex align-items-center">
        <Row className="d-flex align-items-center">
          <Col xs={12} sm={5} md={4}>
            <div className="flex-shrink-0 d-flex justify-content-center justify-content-sm-start">
              <img
               height={120}
               width={130}
                src={
                  userDetail?.additionalInfo?.profileImage
                    ? process.env.REACT_APP_S3_BUCKET +
                      userDetail?.additionalInfo?.profileImage
                    : personPlaceholder
                }
                alt="Profile"
                className="rounded-2 object-fit-cover"
              />
            </div>
          </Col>
          <Col xs={12} sm={7} md={8}>
            <div className="flex-grow-1 d-flex flex-column align-items-sm-start align-items-center">
              <span className="font-size-26 fw-medium ff-barlow text-primary text-uppercase">
                {userDetail?.firstName} {userDetail?.lastName}
              </span>
              <span className="font-size-16 text-secondary ff-barlow fw-normal">
                Member Since: <FormatDate dateStr={userDetail?.createdAt} />
              </span>
              <div className="d-flex flex-column align-items-sm-start align-items-center">
                <span className="font-size-14 text-secondary ff-barlow fw-normal d-flex align-items-center">
                  <i className="fa-light fa-envelope me-2" />
                  {userDetail?.email}
                </span>
                {userDetail?.additionalInfo?.phoneNumber && (
                  <span className="font-size-14 text-secondary ff-barlow fw-normal d-flex align-items-center">
                    <i className="fa-light fa-phone me-2" />
                    {userDetail?.additionalInfo?.phoneNumber}
                  </span>
                )}
                {userDetail?.additionalInfo?.location && (
                  <span className="ps-3 pe-3 px-sm-0 font-size-14 d-flex align-items-baseline text-secondary ff-barlow fw-normal d-flex align-items-center ps-2 ps-md-0">
                    <i className="fa-light fa-map-marker-alt me-2" />
                    {userDetail?.additionalInfo?.location}
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProfileCard
