import React, { useState, useEffect } from "react"
import { post } from "api/axios"
import endpoints from "api/endpoints"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
import { FacebookShare, WhatsappShare, TwitterShare } from "react-share-kit"
import { Bounce, toast } from "react-toastify"
import Like from "assets/images/likefilled.svg"
import unLike from "assets/images/unlikenew.svg"
import Group from "assets/images/votingIcon/groups.svg"
import UnGroup from "assets/images/votingIcon/groupol.svg"
import Bg from "assets/images/votingIcon/bgs.svg"
import UnBg from "assets/images/votingIcon/bgol.svg"
import Fun from "assets/images/votingIcon/funs.svg"
import Unfun from "assets/images/votingIcon/funol.svg"
import Mustach from "assets/images/votingIcon/mustashs.svg"
import UnMustach from "assets/images/votingIcon/mustashol.svg"

const VideoVoted = ({
  videoId,
  isLiked,
  voteTypes = [],
  userVoteType = null,
  likeCount = 0,
  showShare = false,
  showDownload = false,
  videoTitle = "",
  videoDescription = "",
  videoTags = "",
  videoThumbnail = "",
  downloadButton = null, // New prop for download button
}) => {
  const { isAuth } = useSelector(state => state.auth)
  const [upVote, setUpVote] = useState(isLiked)
  const [vId, setvId] = useState(videoId)
  const [userVoteTypes, SetUserVoteTypes] = useState(userVoteType)
  const [voteCount, setVoteCount] = useState(likeCount)
  const [disabled, setDisabled] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [animate, setAnimate] = useState(false) // State for animation
  const navigate = useNavigate()

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const eventHandler = async (voter, type) => {
    if (isAuth) {
      setDisabled(true)
      setAnimate(true) // Trigger animation
      const resp = await post(`${endpoints.voteVideos}`, {
        like: type,
        videoId: vId,
      })

      if (resp) {
        setUpVote(!upVote)
        setDisabled(false)
        console.log(JSON.stringify(resp?.data?.data))
        setVoteCount(resp?.data?.totalLikes || 0)
        SetUserVoteTypes(resp?.data?.data?.like || "")
        // Reset animation after 1 second
        setTimeout(() => setAnimate(false), 1000)
      }
    } else {
      // Save the pending video ID to localStorage
      localStorage.setItem("pendingLikeVideoId", vId)

      toast.error("Please sign in and then upvote.", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })

      // Redirect to login page, with the videoId as a query parameter
      navigate(`/login?redirect=/videodetail/${vId}`)
    }
  }

  useEffect(() => {
    setvId(videoId)
    setVoteCount(likeCount)
    setAnimate(true) // Trigger animation on refresh when the like state changes

    // Reset animation after 1 second
    const resetAnimation = setTimeout(() => setAnimate(false), 1000)

    return () => clearTimeout(resetAnimation) // Cleanup the timeout
  }, [videoId, likeCount, userVoteType])

  return (
    <>
      <Row className="g-3">
        <Col p={2}>
          <p className="font-size-12 fw-normal text-secondary m-0 pb-1">
            <span>{voteCount} Upvote</span>
          </p>
          <div className="progress mb-3">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${(parseInt(voteCount) / 25) * 100}%`,
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <div className="gap-1 d-flex align-items-center">
        <button className="btn btn-link p-1" disabled={disabled}>
          <img
            src={userVoteTypes == "LIKE" ? Like : unLike}
            height={30}
            width={30}
            className={`fa fa-thumbs-up cursor-pointer font-size-22 ${
              animate ? "like-button-animation" : ""
            }`}
            aria-hidden="true"
            onClick={() => {
              eventHandler(!upVote, "LIKE")
            }}
            alt={userVoteTypes == "LIKE" ? "like" : "unlike"}
          />
        </button>
        {/* <button className="btn btn-link p-1" disabled={disabled}>
          <img
            src={userVoteTypes == "GROUP" ? Group : UnGroup}
            height={30}
            width={30}
            className={`fa fa-thumbs-up cursor-pointer font-size-22 ${
              animate ? "like-button-animation" : ""
            }`}
            aria-hidden="true"
            onClick={() => {
              eventHandler(!upVote, "GROUP")
            }}
            alt={userVoteTypes == "LIKE" ? "like" : "unlike"}
          />
        </button>
        <button className="btn btn-link p-1" disabled={disabled}>
          <img
            src={userVoteTypes == "BACKGROUND" ? Bg : UnBg}
            height={30}
            width={30}
            className={`fa fa-thumbs-up cursor-pointer font-size-22 ${
              animate ? "like-button-animation" : ""
            }`}
            aria-hidden="true"
            onClick={() => {
              eventHandler(!upVote, "BACKGROUND")
            }}
            alt={upVote ? "like" : "unlike"}
          />
        </button>
        <button className="btn btn-link p-1" disabled={disabled}>
          <img
            src={userVoteTypes == "FUN" ? Fun : Unfun}
            height={30}
            width={30}
            className={`fa fa-thumbs-up cursor-pointer font-size-22 ${
              animate ? "like-button-animation" : ""
            }`}
            aria-hidden="true"
            onClick={() => {
              eventHandler(!upVote, "FUN")
            }}
            alt={upVote ? "like" : "unlike"}
          />
        </button>
        <button className="btn btn-link p-1" disabled={disabled}>
          <img
            src={userVoteTypes == "MUSTACH" ? Mustach : UnMustach}
            height={30}
            width={30}
            className={`fa fa-thumbs-up cursor-pointer font-size-22 ${
              animate ? "like-button-animation" : ""
            }`}
            aria-hidden="true"
            onClick={() => {
              eventHandler(!upVote, "MUSTACH")
            }}
            alt={upVote ? "like" : "unlike"}
          />
        </button>
        {showShare && (
          <>
            <span className="me-3 text-darkcolor fw-bold font-size-18">|</span>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle className="dropdown-custom-bg" caret>
                Share
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <FacebookShare
                    round={true}
                    size={"20px"}
                    url={`${process.env.REACT_APP_DOMAIN_URL}/videodetail/${videoId}`}
                    quote={`${videoTitle} - ${videoDescription}`}
                    hashtag={videoTags}
                    className="h-25 w-25 rounded-2 col-6"
                    buttonTitle={"Facebook"}
                  />
                </DropdownItem>
                <DropdownItem>
                  <TwitterShare
                    url={`${process.env.REACT_APP_DOMAIN_URL}/videodetail/${videoId}`}
                    title={`${videoTitle} - ${videoDescription}`}
                    hashtag={videoTags}
                    round={true}
                    size={"20px"}
                    className="h-25 w-25 rounded-2 col-6"
                    buttonTitle={"X (Twitter)"}
                  />
                </DropdownItem>
                <DropdownItem>
                  <WhatsappShare
                    round={true}
                    size={"20px"}
                    url={`${process.env.REACT_APP_DOMAIN_URL}/videodetail/${videoId}`}
                    title={`${videoTitle} - ${videoDescription}`}
                    separator=":: "
                    buttonTitle={"WhatsApp"}
                  />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        )} */}
        {downloadButton && downloadButton}
      </div>
    </>
  )
}

export default VideoVoted
