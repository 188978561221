import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row, Container, Alert, Table } from "reactstrap"
import ProfileCard from "../../../components/Common/Cards/ProfileCard"
import StatsCard from "../../../components/Common/Cards/StatsCard"
import VideoCard from "../../../components/Common/Cards/VideoCard"
import SimpleBar from "simplebar-react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { get, put, post, del } from "api/axios"
import endpoints from "api/endpoints"
import pic1 from "assets/images/home/hero-event.png"
import ProfileRewards from "./ProfileRewards"
import UserAllVideo from "./UserAllVideo"
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import { Bounce, toast } from "react-toastify"
//Social Media Imports
import { useGoogleLogin } from "@react-oauth/google"
import { setYoutubeConnect } from "store/slices/authSlice"

export default function UserProfilee() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)
  const [videoData, setVideoData] = useState([])
  const [userDetail, setUserDetail] = useState({})
  const [userStats, setUserStats] = useState({})
  const [userAwardVideo, setUserAwardVideo] = useState([])
  const [userAllVideo, setUserAllVideo] = useState([])
  const [myVideosFlag, setMyVideosFlag] = useState(false)
  const { id } = useParams()
  const { currentUser, youtubeConnected } = useSelector(state => state.auth)

  const isAdmin = currentUser?.userRole == "admin" ? true : false

  const getFetch = async (nextPage = 1) => {
    const resp = await get(`${endpoints.getVideos}?page=${nextPage}&limit=4`)
    if (resp?.data?.success) {
      setVideoData(resp?.data?.data)
    }
  }
  const getUserDetails = async (nextPage = 1) => {
    const resp = await get(`${endpoints.userDetail}/${id}`)
    if (resp?.data?.success) {
      setUserDetail(resp?.data?.data)
      setMyVideosFlag(currentUser?._id == resp?.data?.data?._id ? true : false)
    }
  }
  const getUserStats = async (nextPage = 1) => {
    const resp = await get(`${endpoints.userDetail}/stat/${id}`)
    if (resp?.data?.success) {
      setUserStats(resp?.data)
    }
  }
  const getAwardVideo = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}/winner/my/${id}?page=${nextPage}`
    )
    if (resp?.data?.success) {
      setUserAwardVideo(resp?.data)
    }
  }
  const getAllVideos = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}/my/${id}?page=${nextPage}&limit=6&filter=ACTIVE`
    )
    if (resp?.data?.success) {
      setUserAllVideo({
        data: resp?.data?.data,
        count: resp?.data?.totalCount || resp?.data?.count || 0, // Adjust to use total count
      })
    }
  }
  const delMyAccount = async () => {}

  const blockUser = async () => {
    const resp = await put(`${endpoints.userDetail}/disable/${id}`)
    if (resp.data.success) {
      toast.success("User Blocked Successfully", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      getUserDetails()
    } else {
      toast.error("Some Error Occurred!", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    }
  }
  const unblockUser = async () => {
    const resp = await put(`${endpoints.userDetail}/enable/${id}`)
    if (resp.data.success) {
      toast.success("User Unblocked Successfully", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      getUserDetails()
    } else {
      toast.error("Header Video Added Successfully", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    }
  }

  useEffect(() => {
    getFetch()
    getUserDetails().then(() => {
      getUserStats().then(() => {
        getAwardVideo().then(() => {
          getAllVideos()
        })
      })
    })
  }, [id])
  const handlerYoutubeMember = async () => {
    const resp = await put(`${endpoints.userDetail}/enable/${id}`)
  }

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl",
    onSuccess: async codeResponse => {
      try {
        const tokens = await post(endpoints.googleAuthenticationConnect, {
          code: codeResponse.code,
        })
        userData(tokens.data, "google")
      } catch (error) {}
    },
    onError: errorResponse => console.error("googleAuthent", errorResponse),
  })
  const userData = async (tokens, credType) => {
    dispatch(
      setYoutubeConnect({
        currentUser: tokens?.data?.user,
        youtubeConnected: true,
      })
    )
    toast.success("You Successfully connect your youtube account.", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
  }
  const syncYoutubeMember = async () => {
    const tokens = await get(endpoints.youtubeMedia)
    if (tokens) {
      toast.success(JSON.stringify(tokens.data), {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    } else {
      {
        toast.error(JSON.stringify(tokens.data), {
          position: "bottom-center",
          theme: "colored",
          transition: Bounce,
        })
      }
    }
  }
  const removeYoutubeMember = async () => {
    const tokens = await get(endpoints.youtubeRemove)

    if (tokens) {
      toast.success("Youtube account removed! ", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      dispatch(
        setYoutubeConnect({
          currentUser: tokens?.data?.data?.user,
          youtubeConnected: false,
        })
      )
    } else {
      {
        toast.error(JSON.stringify(tokens.data), {
          position: "bottom-center",
          theme: "colored",
          transition: Bounce,
        })
      }
    }
  }

  const contentDeleteHandler = async () => {
    const resp = await del(`${endpoints.deleteMyAccount}/${currentUser?.email}`)
    if (resp?.data?.success) {
      localStorage.clear()
      toast.success("Deletion Successfull", {
        position: "bottom-center",
        transition: Bounce,
        theme: "colored",
      })
      navigate("/login")
    }
  }
  return (
    <section id="user-profile" className="page-content">
      <Container fluid>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={contentDeleteHandler}
          onCloseClick={e => setDeleteModal(false)}
        />
        <div className="d-flex justify-content-between  pb-3 ">
          <h2 className="text-uppercase">User Profile </h2>
          <div>
            {isAdmin &&
              userDetail.dataStatus == "active" &&
              userDetail.userRole !== "admin" && (
                <button
                  className="btn btn-primary-red-outline"
                  onClick={blockUser}
                >
                  BLOCK USER
                </button>
              )}
            {!youtubeConnected &&
              currentUser?._id === userDetail?._id &&
              isAdmin && (
                <span
                  className="btn btn-danger btn-primary-red ms-3"
                  onClick={googleLogin}
                >
                  <i class="fa-brands fa-youtube"></i> Connect YouTube
                </span>
              )}
            {youtubeConnected &&
              currentUser?._id === userDetail?._id &&
              isAdmin && (
                <span
                  className="btn btn-info btn-primary-green ms-3"
                  onClick={syncYoutubeMember}
                >
                  <i class="fa-brands fa-youtube"></i> Sync Youtube Members
                </span>
              )}
            {youtubeConnected &&
              currentUser?._id === userDetail?._id &&
              isAdmin && (
                <span
                  className="btn btn-danger btn-primary-red ms-3"
                  onClick={removeYoutubeMember}
                >
                  <i class="fa-brands fa-youtube"></i> Remove YouTube
                </span>
              )}
            {currentUser?._id === userDetail?._id && (
              <>
                <Link
                  to={`/editprofile`}
                  className="btn btn-primary-green  ms-3"
                >
                  Edit Profile
                </Link>
                <button
                  className="btn btn-primary-red-outline ms-3"
                  onClick={e => setDeleteModal(true)}
                >
                  Delete My Account
                </button>
              </>
            )}
            {isAdmin && userDetail.dataStatus == "block" && (
              <button
                className="btn btn-primary-red-outline"
                onClick={unblockUser}
              >
                UNBLOCK USER
              </button>
            )}
          </div>
        </div>
        <Row className="">
          <Col xl="6">
            <div className="h-100">
              <ProfileCard isAdmin={isAdmin} userDetail={userDetail} />
            </div>
          </Col>
          <Col md="6" xl="3">
            <div className="h-100">
              <StatsCard type={"videos"} count={userStats?.videoCount || 0} />
            </div>
          </Col>
          <Col md="6" xl="3">
            <div className="h-100">
              <StatsCard type={"awards"} count={userStats?.awardCount || 0} />
            </div>
          </Col>

          <Col sm="12">
            <Row className="">
              <Col lg="6">
                <ProfileRewards
                  userAwardVideo={userAwardVideo}
                  isAdmin={isAdmin}
                  myVideosFlag={myVideosFlag}
                  userId={id}
                />
              </Col>
              <Col lg="6">
                <UserAllVideo
                  userAllVideo={userAllVideo}
                  userId={id}
                  videoCount={userStats?.videoCount || 0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
