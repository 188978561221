import React, { useState, useRef, useEffect } from "react"
import styles from "./ShowMoreText.module.css" // Import your styles

const ShowMoreText = ({ text, lineBreak }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef.current.scrollHeight > textRef.current.clientHeight) {
      setIsOverflowing(true)
    }
  }, [text])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div>
      <p
        ref={textRef}
        className={`${styles["text-para"]} ${
          isExpanded ? styles.expanded : styles.collapsed
        } mt-1 mb-0 ff-barlow font-size-16 fw-normal py-2`}
      >{lineBreak && (
        <>{text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}</>
      )}
      {!lineBreak && (
        <>{text}</>
      )}
        
      </p>
      {isOverflowing && (
        <button onClick={toggleExpand} className={styles["toggle-button"]}>
          {isExpanded ? "Show Less" : "Show More"}
        </button>
      )}
    </div>
  )
}

export default ShowMoreText
