import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import personPlaceholder from "assets/images/personPlaceholder.png"

//i18n
import { withTranslation } from "react-i18next"
// Redux
// import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "../../Common/withRouter"

// users
import user4 from "../../../assets/images/users/avatar-4.jpg"
import dashboard from "assets/images/dash-icon.svg"

import { useSelector } from "react-redux"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const { currentUser } = useSelector(state => state.auth)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        if (obj.username) {
          setusername(obj.username)
        } else {
          setusername(obj.name)
        }
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            style={{ objectFit: "cover" }}
            src={
              currentUser?.additionalInfo?.profileImage
                ? process.env.REACT_APP_S3_BUCKET +
                  currentUser?.additionalInfo?.profileImage
                : personPlaceholder
            }
            alt="Header Avatar"
          />
          <span
            className={`d-none d-xl-inline-block text-white ms-1 fw-medium font-size-15 ${props.titleColor}`}
          >
            {`${currentUser?.firstName}`}
          </span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            tag={Link}
            to={
              currentUser?.userRole == "subscriber"
                ? `/dashboard`
                : `/admin-dashboard`
            }
          >
            {" "}
            <img
              src={dashboard}
              alt=".."
              className="font-size-18 align-middle text-muted me-1"
            />
            {props.t("Dashboard")}{" "}
          </DropdownItem>
          <DropdownItem tag={Link} to={`/userprofile/${currentUser?._id}`}>
            {" "}
            <i className="uil uil-edit font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  titleColor: PropTypes.string,
}

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile
//   return { error, success }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// )
export default withRouter(withTranslation()(ProfileMenu))
