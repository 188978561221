import React from "react"
import { Card, CardBody, CardTitle, CardText, Col, Row } from "reactstrap"
import connect from "assets/images/dollarconnect.png"
import laugh from "assets/images/laugh.png"
import compete from "assets/images/compete.png"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast, Bounce } from "react-toastify"

const styles = {
  gradientBorder: {
    background: "linear-gradient(45deg, #FFDF2B, #126586, #F1803B, #1D3860)",
    borderRadius: "12px",
    padding: "4px",
    animation: "borderAnimation 10s linear infinite",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "#fff",
  },
}

const keyframes = `
  @keyframes borderAnimation {
    0% {
      background-position: 0% 50%; 
    }

    100% {
      background-position: 0% 50%; 
    }
  }
`
const styleSheet = document.styleSheets[0]
styleSheet.insertRule(keyframes, styleSheet.cssRules.length)

const CustomCard = ({ color1, color2, image, title, description }) => (
  <Col xs="12" md="4" className="mb-4">
    <div
      style={{
        ...styles.gradientBorder,
        backgroundSize: "",
      }}
    >
      <Card
        style={{
          margin: 0,
          ...styles.card,

          background: `linear-gradient(150.63deg, ${color1} -19.14%, ${color2} 77.98%)`,
          height: "450px",
          border: "0px",
        }}
      >
        <CardBody className="d-flex flex-column align-items-center justify-content-center text-center">
          <div className="rounded-4 d-flex align-items-center justify-content-center ff-proxima">
            <img
              src={image}
              alt={title}
              width={160}
              height={160}
              className="ff-proxima"
            />
          </div>
          {/* <CardTitle className="ff-cooper text-white fw-normal font-size-48 ">
            {title}
          </CardTitle> */}
          <p className="ff-cooper text-white fw-normal font-size-48 pt-2 m-0">
            {title}
          </p>
          <CardText className="ff-proxima fw-normal font-size-18 ">
            {description}
          </CardText>
        </CardBody>
      </Card>
    </div>
  </Col>
)

const CardContainer = () => {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()

  const handleJoinClick = () => {
    if (isAuth) {
      navigate("/dashboard")
    } else {
      toast.error("Please sign in to continue", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login")
    }
  }
  const cardData = [
    {
      color1: "#AFDBC3",
      color2: "#3B7555",
      image: compete,
      title: "CONNECT",
      description:
        "Team up with friends and family to show us your jokes and connect with other dad joke enthusiasts in our community.",
    },
    {
      color1: "#126586",
      color2: "#1D3860",
      image: laugh,
      title: "LAUGH",
      description:
        "Whether you’re having fun making jokes or finding new ones that make you smile, you’ll find plenty of reason to laugh at the Dockies.",
    },
    {
      color1: "#FFDF2B",
      color2: "#F1803B",
      image: connect,
      title: "COMPETE",
      description:
        "Compete in the Dockies monthly joke competitions to win awesome prizes. Plus all winners are featured on Dock Tok social media.",
    },
  ]

  return (
    <section id="how-dock-tok-work" className="py-5">
      <p className="text-uppercase fw-normal ff-cooper font-size-56  text-center pb-3 black-text-color">
        Join The dockies to:
      </p>
      <div className="">
        <Row className="">
          {cardData.map((card, index) => (
            <CustomCard
              key={index}
              color1={card.color1}
              color2={card.color2}
              image={card.image}
              title={card.title}
              description={card.description}
            />
          ))}
        </Row>
        <div className="text-center">
          <button
            onClick={handleJoinClick}
            className="btn btn-primary-yellow ff-proxima font-size-14 font-weight-500 text-black bg-yellow text-uppercase text-center pt-3"
          >
            Join Our Community
          </button>
        </div>
      </div>
    </section>
  )
}

export default CardContainer
