import React from "react"
import { Badge, Modal, ModalBody } from "reactstrap"
import TimeAgo from "react-timeago"
import { put } from "api/axios"
import endpoints from "api/endpoints"
import { toast, Bounce } from "react-toastify"
import { useNavigate } from "react-router-dom"
export const ResetTopicModal = ({ isOpen, toggle, awardDetail }) => {
  const id = localStorage.getItem("topic")
  const navigate = useNavigate()
  async function handleClick() {
    const resp = await put(`${endpoints.topic}/${id}`, { featured: false })
    if (resp?.data?.success) {
      toast.success("Stats Reset Successful", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/")
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg">
      <div className="d-flex justify-content-end p-2">
        <button
          className="btn d-flex gap-2 align-items-center"
          onClick={toggle}
        >
          <i className="fa-solid fa-dash" />
          <span className="font-size-14 text-darkcolor fw-medium">Close</span>
        </button>
      </div>
      <ModalBody className="px-5">
        <div>
          <span className="font-size-26 text-darkcolor fw-medium">
            RESET STATISTICS COUNT
          </span>
        </div>
        <div>
          <span className="font-size-18">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien.
          </span>
        </div>
        <div className=" py-2 d-flex gap-2 justify-content-end">
          <button
            className="btn btn-success text-white pre-color "
            onClick={handleClick}
          >
            Reset Statistics Count
          </button>
          <button className="btn btn-primary-black-outline  " onClick={toggle}>
            CLOSE
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}
