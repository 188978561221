// actionTypes.js
import { createSlice } from "@reduxjs/toolkit"

export const settingSlice = createSlice({
  name: "settings",
  initialState: {
    layoutType: "horizontal",
    layoutModeType: "light",
    layoutWidth: "lg",
    leftSideBarTheme: "light",
    topbarTheme: "light",
    sidebarSizeType: "default",
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
  },
  reducers: {
    changeLayout: (state, action) => {
      state.layoutType = action.payload
      if (action.payload === "horizontal") {
        state.topbarTheme = "colored"
        if (document.body) {
          document.body.removeAttribute("data-sidebar")
          document.body.removeAttribute("data-sidebar-size")
        }
      } else {
        state.topbarTheme = "light"
      }
      if (document.body) {
        document.body.setAttribute("data-layout", action.payload)
      }
    },
    changeLayoutMode: (state, action) => {
      state.layoutModeType = action.payload
      if (document.body) {
        document.body.setAttribute("data-bs-theme", action.payload)
        if (document.body.getAttribute("data-layout") === "vertical") {
          document.body.setAttribute("data-sidebar", action.payload)
          document.body.setAttribute("data-sidebar", action.payload)
        }
      }
    },
    changePreloader: (state, action) => {
      state.isPreloader = action.payload
    },
    changeLayoutWidth: (state, action) => {
      state.layoutWidth = action.payload
      if (action.payload === "lg") {
        if (document.body) {
          document.body.setAttribute("data-layout-size", "fluid")
        }
      } else {
        if (document.body) {
          document.body.setAttribute("data-layout-size", "boxed")
        }
      }
    },
    changeSidebarTheme: (state, action) => {
      state.leftSideBarTheme = action.payload
      if (document.body) {
        document.body.setAttribute("data-sidebar", action.payload)
      }
    },
    changeTopbarTheme: (state, action) => {
      state.topbarTheme = action.payload
      if (document.body) {
        document.body.setAttribute("data-topbar", action.payload)
      }
    },
    changeSidebarSize: (state, action) => {
      state.sidebarSizeType = action.payload
      switch (action.payload) {
        case "lg":
          if (document.body) {
            document.body.setAttribute("data-sidebar-size", "lg")
          }
          break
        case "small":
          if (document.body) {
            document.body.setAttribute("data-sidebar-size", "small")
          }
          break
        case "sm":
          if (document.body) {
            document.body.setAttribute("data-sidebar-size", "sm")
          }
          break
        default:
          if (document.body) {
            document.body.setAttribute("data-sidebar-size", "lg")
          }
      }
    },
    showRightSidebar: (state, action) => {
      state.showRightSidebar = action.payload
    },
    showSidebar: (state, action) => {
      state.showSidebar = action.payload
    },
    toggleLeftmenu: (state, action) => {
      state.leftMenu = action.payload
    },
  },
})

export const {
  changeLayout,
  changeLayoutMode,
  changePreloader,
  changeLayoutWidth,
  changeSidebarTheme,
  changeTopbarTheme,
  changeSidebarSize,
  showRightSidebar,
  showSidebar,
  toggleLeftmenu,
} = settingSlice.actions

export default settingSlice.reducer
