import React from "react";
import { BlogEditor } from "./components/BlogEditor";

export const EditBlog = () => {
  return (
    <div className="page-content container-fluid">
        <BlogEditor />

    </div>
  )
};
