import React from "react"

import VideoDetailPage from "./components/VideoDetailPage"

const VideoDetail = () => {
  return (
    <>
      <VideoDetailPage />
    </>
  )
}

export default VideoDetail
