import React, { useEffect, useState } from "react"
import { post } from "api/axios"
import endpoints from "api/endpoints"

const VideoComment = ({
  setLoading = () => {},
  reply = null,
  videoId = null,
}) => {
  const [comment, setComment] = useState("")
  const [replyId, setReplyId] = useState(null)
  const [videoID, setVideoId] = useState(null)

  const submitComment = async () => {
    const resp = await post(`${endpoints.getVideos}/comment`, {
      content: comment,
      videoId: videoID,
      reply: replyId,
    })
    if (resp?.data?.success) {
      setLoading(prev => !prev)
      setComment("")
    }
  }
  useEffect(() => {
    reply != null && setReplyId(reply)
    videoId != null && setVideoId(videoId)
  }, [reply, videoId])
  return (
    <div>
      <h5 className="font-weight-bold text-success mt-3">ENTER YOUR COMMENT</h5>
      <div className="input-group">
        <input
          name="message"
          className="form-control"
          id="message"
          type="text"
          value={comment}
          onChange={e => setComment(e.target.value)}
          placeholder="Write your comment here"
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={submitComment}
          >
            <i className="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default VideoComment
