/* import React, { useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data}) => {
    const stats = ({
        labels: data.map((parse, index) => {
            return index + 1
        }),
        datasets: [
          {
            label:'abc',
            fill: false,
            lineTension: 0.5,
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: "#5b73e8",
            pointBackgroundColor: "#3B72C4",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#5b73e8",
            pointHoverBorderColor: "#fff",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 5,
            data:  data.map((user) => user.count)
          }
        ]
      })
  var lineOpts = {
    plugins: {
      legend: {
          display: false, // Hide legend
      },
  },
    scales: {
      y: {
        grid: {
          drawBorder: false, 
          borderDash: [5, 5]
        },
        ticks: {
          color: '#86909C',
        }
      },
      x: {
        ticks: {
          color: '#86909C'
      },
      grid :{
        display: false
      }
      }
    }
  };

  return (
    <React.Fragment>
        <Line width={474} height={300} data={stats} options={lineOpts}  />
    </React.Fragment>
  )
}

export default LineChart
 */

/* import React from "react"
import ReactApexChart from "react-apexcharts"

const LineChart = () => {
  const series = [
    
    {
      name: "Team C",
      type: "line",
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ]
  const options = {
    chart: {
      type: 
    },
    stroke: {
      width: [0, 2, 4],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    colors: ['#34c38f', '#f4f4f4', '#f1b44c'],
    series: [{
      name: 'Team C',
      type: 'line',
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    }],
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'],
    markers: {
      size: 0
    },

    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      title: {
        text: 'Points',
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points";
          }
          return y;

        }
      }
    },
    grid: {
      borderColor: '#f1f1f1'
    }
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
      className="apex-charts"
    />
  )
}

export default LineChart
 */

import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({type, data}) => {
  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false // This will hide the toolbar buttons
      },},
    xaxis: {
      categories: data.map((parse, index) => {
        return index + 1
    }),
    tickAmount: 10, 
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    
  };

  const series = [
    {
      name: 'Users',
      data:  data.map((user) => user.count)
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default LineChart;
