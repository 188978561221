import {} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Container, Input } from "reactstrap"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup" // For validation if needed
import { post } from "api/axios"
import { Bounce, toast } from "react-toastify"
import transitions from "@material-ui/core/styles/transitions"

export const AddStaff = () => {
  const navigate = useNavigate()
  const handleDiscard = () => {
    navigate(-1)
  }
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address!")
      .required("Email is required!"),
    firstName: Yup.string().required("First Name is required!"),
    lastName: Yup.string().required("Last Name is required!"),
    role: Yup.string().required("User Role is required!"),
  })
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    role: "",
  }
  const handleSubmit = async values => {
    const resp = await post(`${endpoints.staff}`, values)

    if (resp?.response?.data?.error) {
      toast.error("Staff Already Exists", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    }

    if (resp?.data?.success) {
      toast.success("Staff Added Successfully", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })

      navigate("/staff")
    }
  }

  return (
    <section className="page-content">
      <Container fluid>
        <div>
          <div>
            <span className="font-size-26 text-darkcolor text-uppercase fw-medium">
              add new staff
            </span>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema} // Optional validation
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, errors, touched }) => (
              <Form>
                <div className="card p-3 shadow rounded-4">
                  <div className="row">
                    {/*  <div className="col-6 g-3">
                <Field as="select" name="existingUser" className="form-select" onChange={handleChange} onBlur={handleBlur}>
                  <option value="existing user">Existing User</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </Field>
              </div>
 */}

                    <div className="col-6 g-3">
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={
                          touched.firstName && errors.firstName ? true : false
                        }
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-6 g-3">
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={
                          touched.lastName && errors.lastName ? true : false
                        }
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-6 g-3">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="User Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={touched.email && errors.email ? true : false}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-6 g-3">
                      <Field
                        as="select"
                        type="select"
                        name="role"
                        className="form-select"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        /* invalid={touched.role && errors.role ? true : true} */
                      >
                        <option defaultValue>User Role</option>
                        <option value="editor">Editor</option>
                        <option value="viewer">Viewer</option>
                        <option value="manager">Manager</option>
                      </Field>
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-3 justify-content-end mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary-green text-uppercase"
                    >
                      Add Staff
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary text-uppercase"
                      onClick={handleDiscard}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  )
}
