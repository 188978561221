import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import play1 from "assets/images/home/play.svg"
import play from "assets/images/circle-bg.png"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import { Link } from "react-router-dom"
import ShuffleText from "components/animation/ShullfeText/ShullfeText"
import AnimatedText from "components/animation/AnimatedText/AnimatedText"

const HowDockTokkWorks = () => {
  const [blogs, setBlogs] = useState([])

  const contentHandler = async () => {
    const resp = await get(`${endpoints.blog}?page=1&limit=3&filter=featured`)
    if (resp?.data?.success) {
      setBlogs(resp?.data?.data)
    }
  }

  function getText(html) {
    var divContainer = document.createElement("div")
    divContainer.innerHTML = html
    return divContainer.textContent || divContainer.innerText || ""
  }

  useEffect(() => {
    contentHandler()
  }, [])

  const buttonLabels = [
    "SUBSCRIBE ON SOCIAL MEDIA",
    "CONNECT YOUR ACCOUNT",
    "SIGN IN TO THE DOCKIES",
    "I’M READY TO POST MY JOKES",
  ]

  return (
    <section id="how-dock-tok-work" className="py-2 bg-light">
      <Container fluid className="py-5 px-3 px-sm-2">
        <Row className="justify-content-center">
          <Col xs="12">
            <Row className="justify-content-start">
              <Col md={12}>
                <h2 className="fw-bold font-size-40 text-uppercase text-darkcolor text-left mb-2">
                  <ShuffleText text="HOW TO BECOME A DOCKIE" />
                </h2>
                <p className="text-secondary font-size-18 fw-normal text-left  ff-barlow pt-0 mt-0">
                  <AnimatedText
                    el="span"
                    type={"heading2"}
                    text={`so easy a blind man could do with his eyes closed`}
                  />
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            md={1}
            className={
              "d-flex justify-content-center align-items-center d-sm-none d-md-none d-none  blog-text-container"
            }
          >
            <p className={" text-uppercase  font-size-140 vert_text"}>
              We Are the Dockies
            </p>
          </Col>
          <Col md={11} sm={12}>
            <Row className="pt-2 pb-2 w-100">
              {blogs.length > 0 ? (
                blogs.map((key, index) => {
                  let temp = index + 1
                  return (
                    <Col
                      lg={12}
                      md={12}
                      sm={6}
                      key={index}
                      className="d-flex flex-column mb-4"
                    >
                      <Row className="align-items-center">
                        {temp % 2 == 0 && (
                          <Col
                            lg={3}
                            md={4}
                            className={" d-none d-sm-none d-md-block"}
                          ></Col>
                        )}
                        <Col lg={3} md={4} sm={6} xs={12}>
                          <Link>
                            <div className="position-relative w-100">
                              {key?.videoDetail?.thumbnail?.path ? (
                                <img
                                  src={`${process.env.REACT_APP_S3_BUCKET}${key?.videoDetail?.thumbnail?.path}`}
                                  alt="blog"
                                  className="rounded-4 img-fluid w-100 object-fit-cover blog-Img"
                                  style={{ maxHeight: "400px" }} // Ensures image scaling and no overflow
                                />
                              ) : (
                                <video className="rounded w-100 h-100"></video>
                              )}
                              {key?.videoDetail?.video && (
                                <button className="btn position-absolute top-50 start-50 translate-middle">
                                  <div className="position-relative">
                                    {/* Play Image */}
                                    <img
                                      src={play}
                                      alt="Play"
                                      height={100}
                                      width={100}
                                      className="position-absolute top-50 start-50 translate-middle"
                                      style={{ zIndex: 1 }} // Higher z-index to ensure this appears above
                                    />
                                    {/* Play1 Image */}
                                    <img
                                      src={play1}
                                      alt="Play1"
                                      height={40}
                                      width={40}
                                      className="position-absolute top-50 start-50 translate-middle"
                                      style={{ zIndex: 2 }} // Ensure play1 is above the other icon
                                    />
                                  </div>
                                </button>
                              )}
                            </div>
                          </Link>
                        </Col>
                        <Col lg={6} md={temp % 2 != 0 ? 6 : 4} sm={6} xs={12}>
                          <Link
                            to={`/how-it-works-details/${key?._id}`}
                            className="d-flex flex-column align-items-center"
                          >
                            <div className="  w-100">
                              <div>
                                <p
                                  style={{ color: "#64B98B" }}
                                  className={"font-size-56"}
                                >
                                  0{temp}
                                </p>
                                <h3 className="font-size-32 fw-medium text-uppercase text-darkcolor mb-2">
                                  {getText(key.title).length > 80
                                    ? getText(key.title).slice(0, 80) + "..."
                                    : getText(key.title).slice(0, 80)}
                                </h3>
                                <p className="font-size-16 text-secondary">
                                  {getText(key.content).length > 200
                                    ? getText(key.content).slice(0, 200) + "..."
                                    : getText(key.content).slice(0, 200)}
                                </p>
                              </div>
                              {/* <button className="btn btn-primary-black-outline text-uppercase font-size-14 mb-3">
                                {buttonLabels[index]}
                              </button> */}
                            </div>
                          </Link>
                        </Col>
                        {temp % 2 != 0 && (
                          <Col
                            lg={3}
                            md={2}
                            className={" d-none d-sm-none d-md-block"}
                          ></Col>
                        )}
                      </Row>
                    </Col>
                  )
                })
              ) : (
                <h3 className="text-center">No Content Found!</h3>
              )}
              <Col xs={12} className={"text-center p-5"}>
                <Link to="/login">
                  {" "}
                  <button
                    className="text-uppercase font-size-14 btn btn-primary-green"
                    onClick={e => {}}
                  >
                    I want to be a Dockie
                  </button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HowDockTokkWorks
