import React, { StrictMode } from "react"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import ReactDOM from "react-dom/client"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { GoogleOAuthProvider } from "@react-oauth/google"
import { google } from "./config"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { Provider } from "react-redux"
import { PersistGate } from "reduxjs-toolkit-persist/integration/react"
import { persistedStore, store } from "./store"
const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistedStore}>
        <GoogleOAuthProvider clientId={google.CLIENT_ID}>
          <ToastContainer />
          <GoogleReCaptchaProvider reCaptchaKey="react-google-recaptcha-v3">
            <App />
          </GoogleReCaptchaProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>
)

serviceWorker.unregister()
