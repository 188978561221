// store.js
import settingsReducer from "./slices/settingsSlice"
import authReducer from "./slices/authSlice"
// import redux and persist plugins
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer } from "reduxjs-toolkit-persist"
import storage from "reduxjs-toolkit-persist/lib/storage"
import persistStore from "reduxjs-toolkit-persist/es/persistStore"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "reduxjs-toolkit-persist/es/constants"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["menu", "settings", "lang", "auth"],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,

    auth: authReducer,
  })
)
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
const persistedStore = persistStore(store)
export { store, persistedStore }
