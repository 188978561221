import axios from "axios"
import React, { useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { toast } from "react-toastify"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import { useSelector } from "react-redux"
const StatsCard = ({ type, count = 0 }) => {
  const [data, setData] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setData(count)
    setLoading(false)
  }, [count])
  return (
    <Card
      className="rounded-4 d-flex flex-column justify-content-center "
      style={{ height: "85%" }}
    >
      <CardBody>
        {loading ? (
          <span className="font-size-26 text-center fw-medium pre-text">
            loading...
          </span>
        ) : (
          <div className="d-flex flex-column gap-1">
            <span className="font-size-96 lh-1 fw-medium pre-text">{data}</span>
            <h2 className="font-size-26 fw-medium text-uppercase text-secondary">
              {type === "videos" ? "videos submitted" : "awards received"}
            </h2>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
export default StatsCard
