import React from "react"
import { Col, Container, Row } from "reactstrap"
import pic1 from "assets/images/about3.png"
import pic2 from "assets/images/about2.png"
import Charity from "assets/images/charity.png"
import Faq from "./Faq"
import HowItWorks from "../../HomePage/components/HowItWorks"
export default function About() {
  return (
    <>
      <section id="about-page" className="">
        <Container fluid>
          <Row>
            <h2 className="ff-cooper text-uppercase font-size-56 fw-normal blue-text-color  pt-3 pb-2">
              we are the dockies{" "}
            </h2>
            {/* <span className="font-size-18 fw-normal pre-text  padding-left-text ms-2 ">
              We are the dockies
            </span> */}
            <Row className="align-items-center justify-content-center py-0 py-md-0 px-md-2">
              <Col lg={6} className="px-2   py-md-0">
                <img src={pic1} alt="Image Alt" className="img-fluid " />
              </Col>
              <Col lg={6} className=" px-2 px-lg-0  py-2 py-lg-0  ">
                <h3 className="ps-1 ps-lg-5 font-size-48 fw-bold  black-text-color ff-proxima">
                  Our Mission: to give the greater community a point of
                  connection for family, friends, and laughter
                </h3>
                <p className="ps-1 ps-lg-5 about-text-clamp overflow-hidden ff-proxima font-size-18 fw-normal gray-700 ">
                  When we started Dock Tok in 2020, it was really just a couple
                  of mustaches joking around on our hometown’s dock. Little did
                  we know the impact our “silly jokes” would have on a world in
                  need of a good old fashioned laugh.Fast forward 3 years, 8
                  million fans, and over 1 billion video views later….we are
                  ready to share the dock
                </p>
              </Col>
            </Row>
          </Row>
        </Container>
      </section>
      <section id="about-page" className="bg-light-skin">
        <Container fluid>
          {" "}
          <Row className="justify-content-center py-3 py-md-5 ">
            <Col xs="12" lg={12} className="text-lg-center">
              <h1 className=" ff-cooper text-uppercase font-size-56 fw-normal blue-text-color  ">
                Welcome to The Dockies
              </h1>

              <div className="ff-proxima font-size-18 fw-normal gray-700  align-items-center justify-content-center">
                It’s your turn to share your humor and creativity with the world
                as you showcase your best dad jokes in our global community. We
                can’t wait to laugh together as YOU take the spotlight at The
                Dockies. After receiving countless DMs, comments, and emails
                with hilarious jokes and requests from our Dock Tok fans, we
                created a place for YOU to share YOUR best dad jokes for the
                chance to win monthly prizes and be featured on Dock Tok social
                media <b>@loganlisle</b>. Grab your friends, pull up a chair,
                and we’ll see you on The Dock
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <HowItWorks />

      <section className=" py-0 py-md-5 bg-light-skin">
        <Container fluid>
          <Row className="align-items-center justify-content-center py-0 py-md-0 px-md-2">
            <Col md={6} className="px-2  py-md-0">
              <img src={Charity} alt="Image Alt" className="img-fluid" />
            </Col>
            <Col md={6} className="p-0 p-md-0 px-2 px-md-0  py-2 py-md-0  ">
              <h3 className="ps-0 ps-md-5 ff-proxima fw-bold font-size-48 black-text-color  text-uppercase ">
                5 percent of the revenue earned by this site will go to the
                single parents
              </h3>
              <p className=" ff-proxima font-size-18 fw-normal gray-700 ps-0 ps-md-5 about-text-clamp overflow-hidden">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-light ">
        <Container fluid className="py-5">
          {" "}
          <Row>
            <Col md={12}>
              <div className="pt-4 pb-4  ">
                <Faq />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
