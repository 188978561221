import React, { useState, useEffect, useCallback } from "react"
import CheckImg from "assets/images/check.svg"
import { debounce } from "lodash"
import pic1 from "assets/images/home/hero-event.png"
import next from "assets/images/next.svg"
import previous from "assets/images/previous.svg"
import DeleteModal from "components/Common/DeleteModal"
import { get, del } from "api/axios"
import endpoints from "api/endpoints"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import Paginations from "components/Common/Paginations/Paginations"
import ShowingCount from "components/Common/Paginations/ShowingCount"
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Alert,
} from "reactstrap"
import BlockedUsersTable from "pages/BlockedUsers/components/myTable"

export default function AwardsListing() {
  const [show, setShow] = useState(false)
  const [awards, setAwards] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [searchItem, setSearchItem] = useState("")

  const [selectedAward, setSelectedAward] = useState("")

  const toggleModal = () => {
    setShow(!show)
  }
  const handleDelete = async () => {
    try {
      const resp = await del(`${endpoints.award}/${selectedAward}`).then(() => {
        toast.success("Award Deleted Successfully", {
          position: "bottom-center",
          theme: "colored",
        })
        getFetch()
        toggleModal()
      })
    } catch (e) {}
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filter, setFilter] = useState({
    action: "lastMonth",
    text: "Last Month",
  })
  const toggle = () => setDropdownOpen(prevState => !prevState)

  const [startIndex, setStartIndex] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState(0)
  const getFetch = useCallback(async () => {
    const resp = await get(
      `${endpoints.award}?page=${startIndex}&limit=${itemsPerPage}&pagination=true&search=${searchItem}&filter=${filter?.action}`
    )

    if (resp?.data?.success) {
      setAwards(resp?.data?.data)
      setTotalPages(Math.ceil(resp?.data?.totalPages))
      setCount(Math.ceil(resp?.data?.count))
    }
  }, [startIndex, itemsPerPage, searchItem, filter])
  const debouncedSearch = useCallback(
    debounce(term => {
      setSearchItem(term)

      // Call your search function or API here
    }, 500), // 300ms delay
    []
  )

  const handleChange = e => {
    setSearchTerm(e.target.value)
    debouncedSearch(e.target.value)
  }
  useEffect(() => {
    setStartIndex(currentPage)
  }, [currentPage, itemsPerPage])

  useEffect(() => {
    getFetch()
  }, [startIndex, itemsPerPage, getFetch, filter])

  return (
    <section id="awards-list" className="page-content bg-light">
      <DeleteModal
        show={show}
        onCloseClick={toggleModal}
        onDeleteClick={handleDelete}
      />
      <Container fluid className="margin-video-vaults">
        <h2 className=" d-flex flex-column flex-md-row text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase d-flex justify-content-between">
          <div class="d-flex justify-content-start align-items-center">
            awards
            <span className="  p-2   mx-3 filter-btn d-flex justify-content-between align-items-center ">
              <Input
                type="text"
                name="search"
                className="hover-input"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-3 mt-md-0">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
              <DropdownToggle
                caret
                className="d-flex justify-content-between align-items-center filter-btn"
              >
                <span> {filter.text}</span>
                <img src={CheckImg} className="px-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "lastMonth",
                      text: "Last Month",
                    })
                    setAwards([])
                    setCurrentPage(1)
                  }}
                  value={"Last Month"}
                >
                  Last Month
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "threeMonth",
                      text: "Last Three Month",
                    })
                    setAwards([])
                    setCurrentPage(1)
                  }}
                  value={"Last Three Month"}
                >
                  Last Three Month
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "lastYear",
                      text: "Last Year",
                    })
                    setAwards([])
                    setCurrentPage(1)
                  }}
                  value={"Last Year"}
                >
                  Last Year
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "active",
                      text: "Older",
                    })
                    setAwards([])
                    setCurrentPage(1)
                  }}
                  value={"Older"}
                >
                  Older
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Link to="/add-awards">
              <button className="btn btn-primary-green text-uppercase ms-3">
                add new award
              </button>
            </Link>
          </div>
        </h2>

        <div className="overflow-auto card shadow p-3 rounded-4 mt-3">
          <table className="table">
            <thead>
              <tr className="my-2 text-uppercase">
                <th
                  scope="col"
                  className="font-size-14 fw-medium text-darkcolor"
                >
                  #ID{" "}
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  Award Category
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  Award Type
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  Added On
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  Last Updated On
                </th>
                <th scope="col" className="font-size-14 text-darkcolor ">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {awards.length > 0 ? (
                awards.map((award, index) => (
                  <tr key={award.id}>
                    <td className="text-secondary fw-normal font-size-16">
                      {index + 1 + (currentPage - 1) * itemsPerPage}
                    </td>
                    <td className="text-secondary fw-normal font-size-16">
                      {award.awardType}
                    </td>{" "}
                    <td className="text-secondary fw-normal font-size-16">
                      {award.award}
                    </td>
                    <td className="text-secondary fw-normal font-size-16">
                      {new Date(award.createdAt).toLocaleDateString()}
                    </td>
                    <td className="text-secondary fw-normal font-size-16">
                      {new Date(award.updatedAt).toLocaleDateString()}
                    </td>
                    <td scope="col" className="">
                      <div className="d-flex gap-2 ">
                        <Link to={`/add-awards/${award?._id}`}>
                          <button className="btn btn-link p-0">
                            <i className="fa-light fa-edit text-secondary font-size-16" />
                          </button>
                        </Link>

                        <button
                          className="btn btn-link p-0"
                          onClick={e => {
                            toggleModal()
                            setSelectedAward(award?._id)
                          }}
                        >
                          <i className="fa-light fa-trash gray-700 font-size-16" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Alert className="text-center">No Record Found!</Alert>{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3">
          <ShowingCount
            currentPage={currentPage}
            count={count}
            itemsPerPage={itemsPerPage}
          />
          <Paginations
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </Container>
    </section>
  )
}
