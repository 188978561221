import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Row,
  Col,
  // CardBody,
  // Card,
  Alert,
  // Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

// Redux
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logo from "../../assets/images/logo-dark.png"
import logoLg from "assets/images/header-logo-lg.svg"

import SocialLink from "./SocialLink/SocialLink"
import { post } from "../../api/axios"
import endpoints from "../../api/endpoints"
import { setLogin } from "../../store/slices/authSlice"
import { useDispatch } from "react-redux"
import { Bounce, toast } from "react-toastify"

const TikTok = props => {
  document.title = " TikTok | Dock Tok"

  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const queryString = location.search
  const params = new URLSearchParams(queryString)
  const tiktokResp = async () => {
    try {
      const tokens = await post(endpoints.tiktokToken, {
        code: params.get("code"),
      })

      userData(tokens?.data, "tiktok")
    } catch (error) {}
  }
  const userData = (tokens, credType) => {
    localStorage.setItem("accessToken", tokens?.token)
    dispatch(
      setLogin({
        currentUser: tokens?.data?.user,
        jid: tokens?.token,
        isAuth: true,
        loginType: credType,
      })
    )

    navigate("/dashboard")
    toast.success("Login Successful", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
  }
  useEffect(() => {
    tiktokResp()
    document.body.className = "authentication-bg"

    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  }, [])

  return (
    <Row className="px-md-5">
      <Col lg={12}>
        <div className="text-center">
          <Link to="/" className="mb-sm-3 mt-5 d-block auth-logo">
            <img src={logo} alt="" height="80" className="logo logo-light" />
            <img src={logoLg} alt="" height="80" className="logo logo-dark" />
          </Link>
        </div>
      </Col>
      <Col xs="12" lg="12">
        <div className="text-center mt-2">
          <h5 className="text-primary text-uppercase">Signin with Tiktok</h5>
          <p className="text-muted">We are verifying your data...</p>
          <div className="p-2 mt-4 ">
            <Spinner>Loading...</Spinner>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default withRouter(TikTok)

TikTok.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
