import React from "react"
import { NavLink } from "react-router-dom"

const MobileMenu = ({ className }) => {
  const links = [
    {
      id: "link1",
      title: <i className="fa-regular fa-house-blank"></i>,
      path: "/",
    },
    {
      id: "link2",
      title: "About",
      path: "/about",
    },
    // {
    //   id: "link3",
    //   title: "challenges",
    //   path: "#!",
    // },
    // {
    //   id: "link4",
    //   title: "events",
    //   path: "#!",
    // },
    {
      id: "link6",
      title: "The Dockie Awards",
      path: "/thedockies",
    },
    {
      id: "link5",
      title: "Video Vault",
      path: "/videovaults",
    },
    // {
    //   id: "link7",
    //   title: "Blog",
    //   path: "/blogs",
    // },
  ]
  return (
    <div
      className={`d-flex  align-items-center gap-2 justify-content-center ${className}`}
    >
      <ul className="nav gap-2 gap-sm-4   border border-secondary-subtle border-1 rounded-pill px-2 py-2 px-sm-3 py-sm-2 bg-white text-secondary fw-medium ">
        {links.map((link, indx) => (
          <li
            key={link.id + indx}
            className={`nav-item ff-barlow ${indx > 3 && "d-sm-block d-none"}`}
          >
            <NavLink
              to={link.path}
              className={({ isActive }) =>
                isActive
                  ? "text-yellow  text-uppercase font-size-14 fw-normal"
                  : "gray-700  text-uppercase font-size-14 fw-normal"
              }
            >
              {link.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MobileMenu
