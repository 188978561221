import React from "react"
import { Col, Placeholder, Row } from "reactstrap"
import TimeAgo from "react-timeago"
import VideoVoted from "pages/Home/components/VideoVoted"
import userPlaceHolder from "../../../assets/images/userPlaceHolder.svg"
import { Link } from "react-router-dom"
import personPlaceholder from "assets/images/personPlaceholder.png"
import FloatingHeart from "../../animation/FloatingHeart/FloatingHeart"

const VideoCard = ({ data, imageHeight = 480, showVideoVoted = true }) => {
  return (
    <div>
      <FloatingHeart
        number={data?.likeCount}
        duration={Math.floor(Math.random() * 20) + 5}
      />
      <div className="image-container position-relative">
        <Link to={`/videodetail/${data?._id}`}>
          <img
            src={`${process.env.REACT_APP_S3_BUCKET}${data?.thumbnail}`}
            alt=""
            className="w-100 rounded-4 object-fit-cover videoCard-thumbnail"
          />
        </Link>
        <div
          style={{ maxWidth: "92%", height: "10%" }}
          className={` position-absolute top-0 bg-white  rounded-pill ps-2 mt-2 ms-2 py-1 icon-container  d-flex align-items-center`}
        >
          <Link to={`/userprofile/${data?.createdBy?._id}`}>
            {data?.createdBy?.additionalInfo?.profileImage ? (
              <img
                src={
                  process.env.REACT_APP_S3_BUCKET +
                  data?.createdBy?.additionalInfo?.profileImage
                }
                alt="icon"
                style={{
                  borderRadius: "50px",
                  objectFit: "cover",
                }}
                width={40}
                height={40}
              />
            ) : (
              <img
                src={personPlaceholder}
                alt="icon"
                width={40}
                height={40}
                className="rounded-circle object-fit-cover"
              />
            )}
          </Link>

          <span
            className={`text-black mx-1 font-size-15 text-uppercase font-weight-500 text-nowrap icon-title`}
          >
            <Link to={`/userprofile/${data?.createdBy?._id}`}>
              {data?.createdBy?.firstName} {data?.createdBy?.lastName}
            </Link>
          </span>
        </div>
      </div>
      <div className="mt-2">
        <p className={` font-size-12 text-secondary p-0 m-0 `}>
          <TimeAgo date={data?.updatedAt} />
        </p>
        <h3
          className={` font-size-18 fw-medium text-darkcolor `}
          style={{ textWrap: "nowrap", overflow: "hidden" }}
        >
          <Link to={`/videodetail/${data?._id}`}>{data?.title}</Link>
        </h3>
      </div>

      {showVideoVoted && (
        <VideoVoted
          videoId={data?._id}
          isLiked={data?.isLiked}
          userVoteType={data?.userVoteType}
          voteTypes={data?.voteTypes}
          likeCount={data?.likeCount}
        />
      )}
    </div>
  )
}

export default VideoCard
