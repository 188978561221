import React from "react"
import { Badge, Modal, ModalBody } from "reactstrap"
import TimeAgo from "react-timeago"

export const AwardDetailsModal = ({ isOpen, toggle, awardDetail }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg">
      <div className="d-flex justify-content-end p-2">
        <button
          className="btn d-flex gap-2 align-items-center"
          onClick={toggle}
        >
          <i className="fa-solid fa-dash" />
          <span className="font-size-14 text-darkcolor fw-medium">Close</span>
        </button>
      </div>
      <ModalBody className="px-5">
        <div className="d-flex flex-column gap-3">
          <div>
            <span className="text-darkcolor font-size-26  fw-medium">
              Award Details
            </span>
          </div>
          <div className="row gy-3 align-items-end">
            {/* <div className="col-12 col-md-4 col-lg-3 d-flex flex-column">
              <span className="font-size-16 gray-700">Award Type</span>
              <span className="pre-text font-size-26">
                {awardDetail?.awardType}
              </span>
            </div> */}
            <div className="col-12 col-md-7 col-lg-6 d-flex flex-column">
              <span className="font-size-16 gray-700">Award</span>
              <span
                className="pre-text font-size-26 text-nowrap"
                style={{ minHeight: "32px" }}
              >
                {awardDetail?.award}
              </span>
            </div>
            <div className="col-12 col-md-5 col-lg-6 d-flex flex-column">
              <span className="font-size-16 gray-700">Awarded On</span>
              <span className="pre-text font-size-26 text-nowrap">
                <TimeAgo date={awardDetail?.createdAt || new Date()} />
              </span>
            </div>
          </div>

          <div>
            <span className="font-size-16 gray-700">
              Reason for announcing winner
            </span>
          </div>
          <div>
            <span className="font-size-18 text-darkcolor">
              {awardDetail?.reason}
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success text-white pre-color "
              onClick={toggle}
            >
              CLOSE
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
