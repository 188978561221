import React, { useState } from "react"
import { useEffect } from "react"
import {
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"

const Paginations = ({ currentPage, setCurrentPage, totalPages }) => {
  return (
    <Pagination>
      <PaginationItem
        onClick={e => currentPage > 1 && setCurrentPage(st => st - 1)}
        disabled={currentPage == 1 ? true : false}
      >
        <PaginationLink href="#" previous />
      </PaginationItem>
      {currentPage > 1 && (
        <PaginationItem onClick={() => setCurrentPage(st => st - 1)}>
          <PaginationLink href="#">{currentPage - 1}</PaginationLink>
        </PaginationItem>
      )}

      <PaginationItem active>
        <PaginationLink href="#"> {currentPage}</PaginationLink>
      </PaginationItem>
      {currentPage < totalPages && (
        <PaginationItem onClick={e => setCurrentPage(st => st + 1)}>
          <PaginationLink href="#"> {currentPage + 1}</PaginationLink>
        </PaginationItem>
      )}

      <PaginationItem
        disabled={currentPage == totalPages ? true : false}
        onClick={e => currentPage < totalPages && setCurrentPage(st => st + 1)}
      >
        <PaginationLink href="#" next />
      </PaginationItem>
    </Pagination>
  )
}

export default Paginations
