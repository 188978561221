import React, { useEffect, useState } from "react"
import {
  Button,
  FormGroup,
  Input,
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Label,
} from "reactstrap"
import imgupload from "./imageupload.svg"
import { useNavigate } from "react-router-dom"
import { Bounce, toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import endpoints from "api/endpoints"
import { put } from "api/axios"
import { setUser } from "store/slices/authSlice"
import { Formik, Form, Field, ErrorMessage, validateYupSchema } from "formik"
import * as Yup from "yup"
import pic1 from "assets/images/delete.svg"

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "First Name must contain alphabets only")
    .required("First Name is required!"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last Name must contain alphabets only")
    .required("Last Name is required!"),
  email: Yup.string()
    .email("Invalid Email address!")
    .required("Email is required!"),
  dateOfBirth: Yup.date()
    .max(new Date(), "Date of Birth can't be Future Date")
    .nullable(),
  phoneNumber: Yup.string().matches(
    /^[+\d()\- ]*$/,
    "Phone number must contain only numeric digits, '+', '()', '-', or spaces."
  ),

  location: Yup.string(),
  gender: Yup.string(),
})

export default function EditProfile() {
  const formatDate = isoString => {
    const date = new Date(isoString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const { currentUser } = useSelector(state => state.auth)
  const [imgURL, setImgURL] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    firstName: currentUser.firstName || "",
    lastName: currentUser.lastName || "",
    email: currentUser.email || "",
    dateOfBirth: currentUser.additionalInfo?.dateOfBirth
      ? formatDate(currentUser.additionalInfo.dateOfBirth)
      : "",
    gender: currentUser.additionalInfo.gender || "",
    phoneNumber: currentUser.additionalInfo.phoneNumber || "",
    location: currentUser.additionalInfo?.location || "",
    file: currentUser?.additionalInfo?.profileImage || "",
  }

  const handleImageUpload = (event, setFieldValue) => {
    setFieldValue("file", event.target.files[0])

    const file = event.target.files[0]

    if (file) {
      setImgURL(URL.createObjectURL(file))
    }
  }

  const handleImageRemove = setFieldValue => {
    setImgURL(null) // Remove the image preview

    // Update Formik state to set file to null
    setFieldValue("file", imgURL)

    // Clear the file input value
    const fileInput = document.getElementById("fileInput")
    if (fileInput) {
      fileInput.value = "" // Reset the file input field
    }
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData()

    // Append form fields as usual
    formData.append("firstName", values.firstName)
    formData.append("lastName", values.lastName)
    formData.append("email", values.email)
    // formData.append("dateOfBirth", values.dateOfBirth)
    formData.append("gender", values.gender)
    formData.append("phoneNumber", values.phoneNumber)
    formData.append("location", values.location)
    if (values.dateOfBirth) {
      formData.append("dateOfBirth", values.dateOfBirth)
    } else {
      formData.append("dateOfBirth", "") // Set to null if the field is empty
    }
    if (values.file) {
      formData.append("file", values.file)
    } else {
      formData.append("nofile", true)
    }

    try {
      formData.forEach((value, key) => {})
      const resp = await put(
        `${endpoints.usersStats}/${currentUser._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      if (resp?.data?.success) {
        dispatch(setUser(resp?.data?.data))
        toast.success("Profile Updated Successfully", {
          theme: "colored",
          position: "bottom-center",
          transition: Bounce,
        })

        navigate(-1)
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div>
      <section id="edit-profile" className="page-content">
        <Container fluid>
          <h2 className="mb-4 text-uppercase font-size-28">Edit Profile</h2>
          <Card className="rounded-4">
            <CardBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col xs={12} md={3} className="text-center">
                        <div className="mb-3">
                          {values.file ? (
                            <div className="d-flex align-items-start position-relative h-20 justify-content-center">
                              <img
                                src={`${
                                  imgURL
                                    ? imgURL
                                    : process.env.REACT_APP_S3_BUCKET +
                                      values.file
                                }`}
                                alt="Upload Images"
                                className="img-fluid object-fit-cover"
                              />
                              <Button
                                className="delete-button position-absolute rounded-circle bg-white"
                                onClick={() => {
                                  handleImageRemove(setFieldValue)
                                }}
                              >
                                <img src={pic1} alt="" />
                              </Button>
                            </div>
                          ) : (
                            <label
                              htmlFor="fileInput"
                              className="img-thumbnail d-flex justify-content-center align-items-center text-secondary rounded-2"
                              style={{
                                height: "120px",
                                width: "120px",
                                cursor: "pointer",
                              }}
                            >
                              <div className="d-flex flex-column align-items-center gap-2">
                                <img
                                  src={imgupload}
                                  alt="img"
                                  height={"32px"}
                                  width={"32px"}
                                />
                                <span className="font-size-12 text-secondary">
                                  Upload Image
                                </span>
                              </div>
                            </label>
                          )}

                          <Input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={event =>
                              handleImageUpload(event, setFieldValue)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <Row form>
                          <Col md={6}>
                            <Label for="firstName">First Name</Label>
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              placeholder="First Name"
                              className="form-control"
                            />

                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <Label for="lastName">Last Name</Label>
                            <Field
                              type="text"
                              name="lastName"
                              id="lastName"
                              placeholder="Last Name"
                              className="form-control"
                            />

                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <Label for="email">Email</Label>
                            <Field
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email"
                              className="form-control"
                              disabled
                            />

                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <Label for="gender">Gender</Label>
                            <Field
                              as="select"
                              name="gender"
                              id="gender"
                              className="form-control"
                            >
                              {/* Add an empty option as a placeholder */}
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </Field>
                            <ErrorMessage
                              name="gender"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <Label for="dateOfBirth">Date of Birth</Label>
                            <Field
                              type="date"
                              name="dateOfBirth"
                              id="dateOfBirth"
                              placeholder="Date of Birth"
                              className="form-control"
                            />

                            <ErrorMessage
                              name="dateOfBirth"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={6}>
                            <Label for="phoneNumber">Phone No.</Label>
                            <Field
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="Phone No."
                              className="form-control"
                            />

                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={12}>
                            <Label for="location">Address</Label>
                            <Field
                              type="text"
                              name="location"
                              id="location"
                              placeholder="Location"
                              className="form-control"
                            />

                            <ErrorMessage
                              name="location"
                              component="div"
                              className="text-danger"
                            />
                            <div className="d-flex justify-content-end gap-2 mt-3">
                              <Button
                                className="btn-primary-green"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Update Profile
                              </Button>
                              <Button
                                type="button"
                                color="light"
                                onClick={() => navigate(-1)}
                              >
                                Discard
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </section>
    </div>
  )
}
