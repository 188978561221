import React, { useState, useEffect } from "react"
import { motion, useInView } from "framer-motion"

const ShuffleText = ({ text }) => {
  const [shuffledText, setShuffledText] = useState("")
  const [iterations, setIterations] = useState(0)
  const ref = React.useRef(null)

  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.5 })

  useEffect(() => {
    if (isInView) {
      const shuffle = str => {
        let array = str.split("")
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[array[i], array[j]] = [array[j], array[i]]
        }
        return array.join("")
      }

      const interval = setInterval(() => {
        setShuffledText(shuffle(text))
        setIterations(prev => prev + 1)
      }, 100)

      if (iterations > 10) {
        clearInterval(interval)
        setShuffledText(text)
      }

      return () => clearInterval(interval)
    }
  }, [isInView, text, iterations])

  useEffect(() => {
    if (iterations > 10) {
      setShuffledText(text)
    }
  }, [iterations, text])

  return (
    // <motion.div
    //   ref={ref}
    //   initial={{ opacity: 0 }}
    //   animate={{ opacity: isInView ? 1 : 0 }}
    //   transition={{ duration: 0.5 }}
    // >
    //   {shuffledText}
    // </motion.div>
    <span>{text}</span>
  )
}

export default ShuffleText
