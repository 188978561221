import React from "react"
import { TopicEditor } from "./components/TopicEditor"

export const EditTopic = () => {
  return (
    <div className="page-content container-fluid">
      <TopicEditor />
    </div>
  )
}
