import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import SimpleBar from "simplebar-react"
import { useNavigate } from "react-router-dom"
import pic1 from "assets/images/noti-pic.png"

import avatar3 from "assets/images/personPlaceholder.png"
import endpoints from "api/endpoints"
import { get, put } from "api/axios"
import { toast } from "react-toastify"
import TimeAgo from "react-timeago"

const NotificationsPage = () => {
  const [notificationList, setNotificationList] = useState([])
  const [page, setPage] = useState(1)
  const [hasMoreNotifications, setHasMoreNotifications] = useState(true)
  const navigate = useNavigate()

  const notificationRoutes = {
    VIDEO_POST: id => `/videodetail/${id}`,
    COMMENT: id => `/videodetail/${id}`,
    VOTE: id => `/videodetail/${id}`,
    BLOG: id => `/how-it-works-details/${id}`,
    TOPIC: id => `/header-video-details/${id}`,
    CHALLENGE: id => `/challengedetail/${id}`,
    WINNER: id => `/winnerdetail/${id}`,
    EVENT: id => `/eventdetail/${id}`,
    APPROVAL: id => `/approvaldetail/${id}`,
  }

  const getData = async () => {
    try {
      const response = await get(
        `${endpoints.notification}?page=${page}&limit=5`
      )
      if (response.status === 200) {
        const newNotifications = response.data.data
        if (newNotifications.length > 0) {
          setNotificationList(prevList => [...prevList, ...newNotifications])
        } else {
          setHasMoreNotifications(false)
        }
      }
    } catch (error) {
      toast.error(error.message)
      console.error("Error fetching notifications:", error)
    }
  }

  const markNotification = async notification => {
    const {
      _id: notificationId,
      type,
      data: { id: relatedId },
    } = notification
    try {
      const response = await put(`${endpoints.notification}`, {
        notificationId,
      })
      if (response.status === 200) {
        getData()
        const route = notificationRoutes[type]
        if (route) {
          navigate(route(relatedId))
        } else {
          toast.info("Unknown notification type")
        }
      }
    } catch (error) {
      toast.error(error.message)
      console.error("Error marking notification as read:", error)
    }
  }

  const markAllHandle = async () => {
    try {
      const response = await put(`${endpoints.notificationAll}`)
      if (response.status === 200) {
        toast.success("All notifications marked as read")
        navigate("/dashboard") // Redirect to the dashboard after marking all as read
      }
    } catch (error) {
      toast.error(error.message)
      console.error("Error marking all notifications as read:", error)
    }
  }

  useEffect(() => {
    getData()
  }, [page])

  return (
    <section id="user-profile" className="page-content">
      <Container fluid>
        <Row>
          <Col xs="12">
            <div className="margin-video-vaults d-flex flex-column flex-sm-row justify-content-between">
              <div>
                <h2 className="ff-barlow font-size-26 fw-medium text-uppercase text-darkcolor mb-0 pb-0">
                  Notifications
                </h2>
              </div>
              <div className="text-end mb-0 pb-1">
                <button
                  className="text-uppercase font-size-14  btn btn-primary-green  ms-auto"
                  onClick={markAllHandle}
                >
                  Mark all as read
                </button>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <Card className="rounded-4 mt-3">
              <CardBody>
                <SimpleBar style={{ maxHeight: "600px" }}>
                  {notificationList.length > 0 ? (
                    notificationList.map(item => (
                      <div
                        className="notification-item mb-3 position-relative"
                        key={item?._id}
                        onClick={() => markNotification(item)}
                        style={
                          !item.readStatus
                            ? {
                                backgroundColor: "#f8f9fa",
                                borderBottom: "1px solid #d6cece",
                              }
                            : {}
                        }
                      >
                        {!item.readStatus && (
                          <i
                            className="fa fa-circle position-absolute end-0 font-size-10 marker-px pe-2"
                            aria-hidden="true"
                          ></i>
                        )}
                        <Row className="align-items-start">
                          <Col xs="auto">
                            <div
                              className="cursor-pointer"
                              onClick={e => {
                                e.stopPropagation() // Prevent triggering the main notification click
                                navigate(`/userprofile/${item.sender?._id}`)
                              }}
                            >
                              <img
                                src={
                                  item?.sender?.additionalInfo?.profileImage
                                    ? process.env.REACT_APP_S3_BUCKET +
                                      item?.sender?.additionalInfo?.profileImage
                                    : avatar3
                                }
                                className="rounded-circle avatar-xs"
                                alt="user-pic"
                              />
                            </div>
                          </Col>
                          <Col>
                            <h4
                              className="fw-medium font-size-15 ff-barlow mt-0 mb-1 cursor-pointer"
                              onClick={e => {
                                e.stopPropagation() // Prevent triggering the main notification click
                                navigate(`/userprofile/${item.sender?._id}`)
                              }}
                            >
                              {item.sender?.firstName} {item.sender?.lastName}
                            </h4>
                            <p className="mb-0 float-end fw-normal font-size-14 ff-barlow text-secondary">
                              <TimeAgo date={item.createdAt} />
                            </p>
                            <div className="font-size-12 fw-normal font-size-14 ff-barlow text-secondary cursor-pointer">
                              <p className="mb-1">{item.type}</p>
                              {item?.data?.thumbnail && (
                                <img
                                  src={
                                    process.env.REACT_APP_S3_BUCKET +
                                      item?.data?.thumbnail || pic1
                                  }
                                  style={{ height: "15vh", width: "10vh" }}
                                  alt="."
                                  className="rounded-4  object-fit-cover"
                                />
                              )}

                              <div className="mt-1 ps-2 border-start border-2 border-success">
                                <span className="fw-normal font-size-14 ff-barlow text-secondary cursor-pointer">
                                  {item.message}
                                </span>
                              </div>
                            </div>
                            <hr className="border-dark mb-0"></hr>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <p className="text-secondary font-size-14 text-center">
                      No Notification Found!
                    </p>
                  )}
                  {hasMoreNotifications ? (
                    <div className="text-center">
                      <button
                        className="text-uppercase font-size-14 font-medium  btn btn-primary-green ms-auto"
                        onClick={() => setPage(prev => prev + 1)}
                      >
                        Load More
                      </button>
                    </div>
                  ) : (
                    <p className="text-center text-secondary font-size-14">
                      No More Notifications
                    </p>
                  )}
                </SimpleBar>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default NotificationsPage
