import React from "react"
import { Col, Row } from "reactstrap"
import { useGoogleLogin } from "@react-oauth/google"
import endpoints from "api/endpoints"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { facebook, google, instagram, tiktok } from "../../../config"
import { useDispatch } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { setLogin } from "../../../store/slices/authSlice"
import { Bounce, toast } from "react-toastify"
import { get, post } from "../../../api/axios"
import { InstagramLogin } from "@amraneze/react-instagram-login"

const SocialLinkUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const queryString = location.search
  const params = new URLSearchParams(queryString)
  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl",
    onSuccess: async codeResponse => {
      try {
        const tokens = await post(endpoints.googleAuthentication, {
          code: codeResponse.code,
        })

        if (tokens?.response?.data?.error) {
          toast.error(tokens?.response?.data?.error, {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
        } else if (tokens?.data?.success) {
          userData(tokens.data, "google")
        }
      } catch (error) {
        console.log(error, "google")
      }
    },
    onError: errorResponse => console.log("gogole ", errorResponse),
  })

  //handleInstagramLoginResponse
  const instagramResponse = async response => {
    const tokens = await post(endpoints.instagramToken, {
      code: response,
    })
    if (tokens?.response?.data?.error) {
      toast.error(tokens?.response?.data?.error, {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    } else if (tokens?.data?.success) {
      userData(tokens.data, "instagram")
    }
  }

  const instagramOnFailure = response => {
    console.log(response, "instagram  on failure ")
    toast.error("You canceled the Instagram authentication", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
  }
  const facebookOnFailure = response => {
    console.log(response, "facebook  on failure ")
    toast.error("You canceled the Facebook authentication", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
  }

  //handleFacebookLoginResponse
  const facebookResponse = async response => {
    const tokens = await post(endpoints.facebookAuthentication, {
      ...response,
    })
    if (tokens?.response?.data?.error) {
      toast.error(tokens?.response?.data?.error, {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    } else if (tokens?.data?.success) {
      userData(tokens.data, "facebook")
    }
  }
  //handleTiktokLoginResponse
  const tiktokResponse = async () => {
    const url = await get(endpoints.tiktokAuthentication)
    if (url?.data?.success) {
      window.location.href = url.data.data
    }
  }
  const userData = async (tokens, credType) => {
    localStorage.setItem("accessToken", tokens?.token)
    const fcmToke = await localStorage.getItem("fcm")
    const fcmData = await post(endpoints.fcm, { fcmToken: fcmToke })
    dispatch(
      setLogin({
        currentUser: tokens?.data?.user,
        jid: tokens?.token,
        isAuth: true,
        loginType: credType,
      })
    )
    if (params.get("redirect")) {
      navigate(params.get("redirect"))
    } else {
      navigate("/dashboard?sociallogin=true")
    }
    toast.success("Login Successful", {
      position: "bottom-center",
      theme: "colored",
      transition: Bounce,
    })
  }

  const data = {
    youtube: {
      icon: "fa-brands fa-youtube",
      bg: "#FF0000",

      title: "Continue with Youtube",
    },
    tiktok: {
      icon: "fa-brands fa-tiktok",
      bg: "#252525",

      title: "Continue with Tik-Tok",
    },
    instagram: {
      icon: "fa-brands fa-instagram",
      bg: "linear-gradient(45deg, #FFD600 0%, #FF0100 50%, #D800B9 100%)",

      title: "Continue with Instagram",
    },
    facebook: {
      icon: "fa-brands fa-facebook-f",
      bg: "#3C5A99",

      title: "Continue with Facebook",
    },
  }
  return (
    <>
      <span className="text-center text-secondary mb-2 d-flex align-items-center justify-content-center">
        <div className="stroke-line-login" />
        <span>Get started with</span>
        <div className="stroke-line-login" />
      </span>

      <Col xs="12" md="12" lg="12" xl="12" className=" px-md-0 px-2">
        <Row className="px-5 custom-height">
          {google.CLIENT_ID !== "" && (
            <Col xs="12" md="6" lg="12" xl="6" className="py-2 h-100">
              <button
                style={{ background: data.youtube.bg }}
                className="h-100 text-white border-0 d-flex align-items-center justify-content-center text-center gap-2 py-2 px-2 rounded-3 w-100"
                onClick={googleLogin}
              >
                <i
                  className={`${data.youtube.icon} font-size-16 ps-1 ps-md-0 ps-lg-1 ps-xl-3`}
                ></i>
                <span className="text-uppercase font-size-12">
                  {data.youtube.title}
                </span>
              </button>
            </Col>
          )}
          {tiktok.APP_ID !== "" && (
            <Col xs="12" md="6" lg="12" xl="6" className="py-2 h-100">
              <button
                style={{ background: data.tiktok.bg }}
                className="h-100 text-white border-0 d-flex align-items-center justify-content-center text-center gap-2 py-2 px-2 rounded-3 w-100"
                onClick={tiktokResponse}
              >
                <i
                  className={`${data.tiktok.icon} font-size-16 ps-xl-3 ps-xxl-3`}
                ></i>
                <span className="text-uppercase font-size-12">
                  {data.tiktok.title}
                </span>
              </button>
            </Col>
          )}
          {instagram.APP_ID !== "" && (
            <Col xs="12" md="6" lg="12" xl="6" className="py-2 h-100">
              {/*https://www.instagram.com/oauth/authorize?client_id=1076203853921455&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_content_publish%2Cuser_read_followers%2Cuser_read_subscriptions&redirect_uri=https%3A%2F%2Fdocktok.pixarsclients.com%2Flogin&logger_id=105f6bc9-62bc-40c5-a225-45e8991a5eb6*/}
              <InstagramLogin
                clientId={instagram.APP_ID}
                redirectUri="https://docktok.pixarsclients.com/login"
                scope="instagram_business_basic,instagram_business_manage_messages,instagram_business_content_publish"
                onSuccess={instagramResponse}
                onFailure={instagramOnFailure}
                cssClass="h-100 m-0 p-0 border-0 bg-transparent w-100"
              >
                <button
                  style={{ background: data.instagram.bg }}
                  className="h-100 text-white border-0 d-flex align-items-center justify-content-center text-center gap-2 py-2 px-2 rounded-3 w-100"
                >
                  <i
                    className={`${data.instagram.icon} font-size-16 ps-3 ps-md-2 ps-lg-3 ps-xxl-4`}
                  ></i>
                  <span className="text-uppercase font-size-12">
                    {data.instagram.title}
                  </span>
                </button>
              </InstagramLogin>
            </Col>
          )}
          {facebook.APP_ID !== "" && (
            <Col xs="12" md="6" lg="12" xl="6" className="py-2 h-100">
              <FacebookLogin
                appId={facebook.APP_ID}
                autoLoad={false}
                scope="email,user_posts,user_friends,user_birthday"
                callback={facebookResponse}
                onFailure={facebookOnFailure}
                fields="id,name,email,picture"
                render={renderProps => (
                  <button
                    style={{ background: data.facebook.bg }}
                    className="h-100 text-white border-0 d-flex align-items-center justify-content-center text-center gap-2 py-2 px-2 rounded-3 w-100"
                    onClick={renderProps.onClick}
                  >
                    <i
                      className={`${data.facebook.icon} font-size-16 ps-2 ps-xl-3 ps-xxl-4`}
                    ></i>
                    <span className="text-uppercase font-size-12">
                      {data.facebook.title}
                    </span>
                  </button>
                )}
              />
            </Col>
          )}
        </Row>
      </Col>
    </>
  )
}

export default SocialLinkUpdate
