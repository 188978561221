import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import withRouter from "./Common/withRouter"
import TopNavBar from "./LandingLayout/TopNavBar/TopNavBar"
import MobileMenu from "./LandingLayout/TopNavBar/MobileMenu"
import Footer from "./LandingLayout/Footer/Footer"
import { useLocation } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import authBanner from "assets/images/loginBg.jpg"
import { useNavigate } from "react-router-dom"
import videoImage from "assets/images/video.mp4"

import authBg from "assets/images/authBg.png"
import play from "assets/images/play-login.svg"

const NonAuthLayout = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const videoRef = useRef(null) // Create a ref for the video element
  const [isPlaying, setIsPlaying] = useState(false) // Track play/pause state
  const handleVideoEnd = () => {
    setIsPlaying(false) // Set to false when the video ends
  }

  // Use effect to add event listener when the component mounts
  useEffect(() => {
    const videoElement = videoRef.current

    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnd)

      // Cleanup event listener on component unmount
      return () => {
        videoElement.removeEventListener("ended", handleVideoEnd)
      }
    }
  }, [])

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
      setIsPlaying(true)
    } else {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }
  const handleClick = () => {
    navigate("/admin")
  }

  const paths = [
    "/login",
    "/register",
    "/forgot-password",
    "/confirm-password",
    "/admin",
    "/otp",
    "/tiktok",
  ]

  return (
    <>
      {!paths.includes(location?.pathname) ? (
        <div className="d-flex flex-column  ">
          <TopNavBar />
          <MobileMenu className="fixed-bottom d-lg-none mb-3" />
          <main className="flex-shrink-0">{props.children}</main>
          <Footer />
        </div>
      ) : (
        <div fluid className=" overflow-hidden vh-100 w-100">
          {location.pathname !== "/admin" && (
            <button
              onClick={handleClick}
              className="btn d-none d-md-block  position-absolute btn-primary-green 
        "
              style={{ zIndex: "8000", right: "2%", top: "2rem" }}
            >
              LOGIN WITH ADMIN
            </button>
          )}
          <MobileMenu className="fixed-top mt-4 pt-2" />
          <Row className="align-items-center h-100">
            <Col lg="6" className="d-lg-block d-none   position-relative">
              <video
                src={
                  "https://dkkt6vopmv10m.cloudfront.net/uploads/771cb4ad-8d25-4fdc-bd41-e0391d826658-loginpagesvideo.mp4"
                }
                poster="https://dkkt6vopmv10m.cloudfront.net/uploads/de5cd158-e4d2-4156-90e5-1dd7c53ad573-d4d2f77a51bb4941bd00c5680e2e1031loginpagesvideo.mp4.jpg"
                alt="auth-banner"
                ref={videoRef} //
                playsInline={true}
                autoPlay={false}
                muted={true}
                style={{
                  objectFit: "cover",
                  border: "20px solid white",
                  borderTopRightRadius: "70px",
                  borderBottomRightRadius: "70px",
                }}
                className="w-100 vh-100  position-relative"
              />
              <div
                className="position-absolute  rounded-circle "
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div
                  className="position-relative rounded-circle"
                  style={{
                    color: "white",
                    height: "185px",
                    width: "185px",
                    backgroundColor: "rgba(37, 37, 37, 0.6)",
                  }}
                >
                  <span
                    className="text-ring position-absolute mx-2 fw-bold font-size-26"
                    style={{
                      "--total": "40",
                      top: "50%",
                      left: "45%",
                      color: "#ffffff", // Keep text fully white
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {/* Your text here */}

                    <span aria-hidden="true" className="font-size-20">
                      <span style={{ "--index": "0" }}>&nbsp;P</span>
                      <span style={{ "--index": "1" }}>L</span>
                      <span style={{ "--index": "2" }}>A</span>
                      <span style={{ "--index": "3" }}>Y&nbsp;</span>
                      <span style={{ "--index": "4" }}>V</span>
                      <span style={{ "--index": "5" }}>I</span>
                      <span style={{ "--index": "6" }}>D</span>
                      <span style={{ "--index": "7" }}>E</span>
                      <span style={{ "--index": "8" }}>O&nbsp;</span>
                      <span style={{ "--index": "9" }}>-</span>
                      <span style={{ "--index": "10" }}>&nbsp;P</span>
                      <span style={{ "--index": "11" }}>L</span>
                      <span style={{ "--index": "12" }}>A</span>
                      <span style={{ "--index": "13" }}>Y&nbsp;</span>
                      <span style={{ "--index": "14" }}>V</span>
                      <span style={{ "--index": "15" }}>I</span>
                      <span style={{ "--index": "16" }}>D</span>
                      <span style={{ "--index": "17" }}>E</span>
                      <span style={{ "--index": "18" }}>O&nbsp;</span>
                      <span style={{ "--index": "19" }}>-</span>
                      <span style={{ "--index": "20" }}>&nbsp;P</span>
                      <span style={{ "--index": "21" }}>L</span>
                      <span style={{ "--index": "22" }}>A</span>
                      <span style={{ "--index": "23" }}>Y&nbsp;</span>
                      <span style={{ "--index": "24" }}>V</span>
                      <span style={{ "--index": "25" }}>I</span>
                      <span style={{ "--index": "26" }}>D</span>
                      <span style={{ "--index": "27" }}>E</span>
                      <span style={{ "--index": "28" }}>O&nbsp;</span>
                      <span style={{ "--index": "29" }}>-</span>
                      <span style={{ "--index": "30" }}>&nbsp;P</span>
                      <span style={{ "--index": "31" }}>L</span>
                      <span style={{ "--index": "32" }}>A</span>
                      <span style={{ "--index": "33" }}>Y&nbsp;</span>
                      <span style={{ "--index": "34" }}>V</span>
                      <span style={{ "--index": "35" }}>I</span>
                      <span style={{ "--index": "36" }}>D</span>
                      <span style={{ "--index": "37" }}>E</span>
                      <span style={{ "--index": "38" }}>O&nbsp;</span>
                      <span style={{ "--index": "39" }}>-</span>
                    </span>
                    <span class="sr-only">Your text here!</span>
                  </span>
                  <div
                    className="play-button position-absolute text-center"
                    style={{
                      top: "40%",
                      left: "42%",
                    }}
                  >
                    <button
                      className="btn p-0 text-secondary"
                      onClick={handlePlayPause}
                    >
                      <i
                        className={`fa ${
                          isPlaying ? "fa-pause" : "fa-play"
                        } font-size-26 mb-3`}
                        style={{ color: "white" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              className="vh-100     position-relative  d-flex justify-content-center align-items-center "
            >
              <main style={{ zIndex: 2 }}>{props.children}</main>
              <img src={authBg} alt="" className="authOverlay" />
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(NonAuthLayout)
