import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"

import { Row, Col, Form, Input, FormFeedback } from "reactstrap"
//redux
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

import * as Yup from "yup"
import { useFormik } from "formik"

import logo from "../../assets/images/logo-dark.png"
import logoLg from "assets/images/header-logo-lg.svg"

import { post } from "../../api/axios"
import endpoints from "../../api/endpoints"
import { setLogin } from "../../store/slices/authSlice"
import { useDispatch } from "react-redux"
import { Bounce, toast } from "react-toastify"

const Admin2FA = props => {
  document.title = "Login | Dock Tok"
  const location = useLocation()
  const email = location.state?.email
  const [timer, setTimer] = useState(60)
  const timeoutRef = useRef(null)

  const timeOutCallback = useCallback(() => {
    setTimer(currTimer => {
      if (currTimer > 0) {
        return currTimer - 1
      } else {
        return 0
      }
    })
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (timer > 0) {
      timeoutRef.current = setTimeout(timeOutCallback, 1000)
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [timer, timeOutCallback])

  const resetTimer = function () {
    if (!timer) {
      setTimer(60)
    }
  }
  const resend = async () => {
    const resp = await post(`${endpoints.resendOTP}`, {
      email: email,
    })
    if (resp?.data?.success) {
      toast.success("OTP sent")
      resetTimer()
    }
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // State to manage the error message

  const handleClick = () => {
    navigate(-1)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("OTP is Required"),
    }),
    onSubmit: async values => {
      try {
        const response = await post(endpoints.otp, values)
        if (response.data.success) {
          localStorage.setItem("accessToken", response?.data?.token)
          const fcmToke = await localStorage.getItem("fcm")
          await post(endpoints.fcm, { fcmToken: fcmToke })

          dispatch(
            setLogin({
              currentUser: response?.data?.data,
              jid: response?.data?.token,
              isAuth: true,
              loginType: "credentials",
              youtubeConnected:
                response?.data?.data?.socialConnect?.google == null
                  ? false
                  : true,
            })
          )
          navigate("/admin-dashboard")
          toast.success("Login Successful", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
        } else {
          toast.error("Your OTP is wrong. Please try again.", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
        }
      } catch (error) {
        toast.error("Your OTP is wrong. Please try again.", {
          position: "bottom-center",
          theme: "colored",
          transition: Bounce,
        })
      }
    },
  })

  useEffect(() => {
    document.body.className = "authentication-bg"
    return function cleanup() {
      document.body.className = ""
    }
  }, [])

  return (
    <div className="d-flex px-md-5 mx-lg-3">
      <Row className="px-md-5">
        <Col lg={12}>
          <div className="text-center">
            <Link to="/" className="mb-sm-3 mt-5 d-block auth-logo">
              <img src={logo} alt="" height="80" className="logo logo-light" />
              <img src={logoLg} alt="" height="80" className="logo logo-dark" />
            </Link>
          </div>
        </Col>
        <Col xs="12" lg="12">
          <div className="text-center mt-2">
            <span className="fw-medium font-size-26">OTP TO YOUR ACCOUNT</span>
            <p className="text-muted">Enter the code sent to your email.</p>
            {/* <p className="text-muted mt-3">
              The OTP is valid for 10 minutes. If it has expired, please request
              a new one.
            </p> */}
          </div>
          <div className="p-2 mt-4">
            <Form
              className="form-horizontal w-100"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="mb-2">
                <Input
                  name="code"
                  value={validation.values.code || ""}
                  type="text"
                  placeholder="Enter Your Code"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.code && validation.errors.code
                      ? true
                      : false
                  }
                />
                {validation.touched.code && validation.errors.code ? (
                  <FormFeedback type="invalid">
                    {validation.errors.code}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mt-3 mb-0">
                <button
                  className="btn btn-primary-green w-100 waves-effect waves-light"
                  type="submit"
                >
                  Submit Code
                </button>
              </div>

              {!timer && (
                <div className="mt-1 mb-0">
                  <Link
                    className="pre-text"
                    type="button"
                    onClick={() => {
                      // Handle resend OTP functionality here
                      resend()
                    }}
                  >
                    Resend OTP ?
                  </Link>
                </div>
              )}
              {timer > 0 && (
                <div className="mt-1 gray-700">
                  <span>You Can Resend OTP in 00:{timer}</span>
                </div>
              )}

              <div className="mt-4 text-center"></div>
            </Form>
            <span
              className="d-flex d-md-none justify-content-center font-size-14 fw-medium text-darkcolor cursor-pointer"
              onClick={handleClick}
            >
              Go Back To Previous Page
            </span>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(Admin2FA)

Admin2FA.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
