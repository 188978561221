import { motion, useInView, useAnimation } from "framer-motion"
import { useEffect, useRef } from "react"

// Default animations with set speed
const defaultAnimations = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3, // Default duration for the animation
    },
  },
}

const AnimatedText = ({
  text,
  el: Wrapper = "p",
  className,
  once = true,
  repeatDelay = 0,
  animation = defaultAnimations, // default animation, can be overridden
  customSpeed, // custom speed passed as prop
}) => {
  const controls = useAnimation()
  const textArray = Array.isArray(text) ? text : [text]
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 0.5, once })

  useEffect(() => {
    let timeout

    const show = () => {
      controls.start("visible")
      if (repeatDelay) {
        timeout = setTimeout(async () => {
          await controls.start("hidden")
          controls.start("visible")
        }, repeatDelay)
      }
    }

    if (isInView) {
      show()
    } else {
      controls.start("hidden")
    }

    return () => clearTimeout(timeout)
  }, [isInView, repeatDelay, controls])

  // If custom speed is passed, modify the duration of the visible state
  const modifiedAnimation = {
    ...animation,
    visible: {
      ...animation.visible,
      transition: {
        ...animation.visible.transition,
        duration: customSpeed || animation.visible.transition.duration, // Use custom speed or default duration
      },
    },
  }

  return (
    // <Wrapper className={className} style={{ wordWrap: "break-word" }}>
    //   <span className="sr-only">{textArray.join(" ")}</span>
    //   <motion.span
    //     ref={ref}
    //     initial="hidden"
    //     animate={controls}
    //     variants={{
    //       visible: { transition: { staggerChildren: 0.03 } }, // Default staggerChildren
    //       hidden: {},
    //     }}
    //     aria-hidden
    //   >
    //     {textArray.map((line, lineIndex) => (
    //       <span className="block" key={`${line}-${lineIndex}`}>
    //         {line.split(" ").map((word, wordIndex) => (
    //           <span className="inline-block" key={`${word}-${wordIndex}`}>
    //             {word.split("").map((char, charIndex) => (
    //               <motion.span
    //                 key={`${char}-${charIndex}`}
    //                 className="inline-block"
    //                 variants={modifiedAnimation} // Use modified or default animation
    //               >
    //                 {char}
    //               </motion.span>
    //             ))}
    //             <span className="inline-block">&nbsp;</span>
    //           </span>
    //         ))}
    //       </span>
    //     ))}
    //   </motion.span>
    // </Wrapper>
    <span>{text}</span>
  )
}

export default AnimatedText
