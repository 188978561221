// App.js
import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import { Link } from "react-router-dom"
import AdminDockies from "./myTable"
import { Container } from "reactstrap"
import { toast } from "react-toastify"

export const StaffListing = () => {
  const [getArray, setArray] = useState([])
  const [loading, setLoading] = useState(true)
  async function getfetch() {
    try {
      const resp = await get(`${endpoints.staff}`)
      if (resp?.data?.success) {
        setArray(resp?.data?.data)
        setLoading(false)
      } else {
        toast.error("Some Error Occured!")
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    getfetch()
  }, [])

  return (
    <section className=" page-content  bg-light">
      <Container fluid className="margin-video-vaults">
        <div className={""}>
          <AdminDockies />
        </div>
        <div></div>
      </Container>{" "}
    </section>
  )
}
