import React, { useEffect, useState } from "react"
import VideoComment from "./VideoComment"
import styles from "../VideoDetailPage.module.css"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import TimeAgo from "react-timeago"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import avatar3 from "assets/images/profile.jpg"
import personPlaceholder from "assets/images/personPlaceholder.png"

const VideoCommentListing = ({ videoId, status, info, rules }) => {
  const [commentData, setCommentData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const { isAuth } = useSelector(state => state.auth)
  const [loadMoreComment, setLoadMoreComment] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const getComment = async (nextPage = 1) => {
    const resp = await get(`${endpoints.getVideos}/comment/${videoId}`)
    if (resp?.data?.success) {
      setCommentData(resp?.data?.data)
      setLoadMoreComment({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  useEffect(() => {
    getComment()
  }, [refresh])
  return (
    <div className="col-md-4 col-sm-6  mb-5  mb-sm-0">
      {status === "ACTIVE" && (
        <div className="mt-3">
          <h3 className={`ff-barlow font-size-18 text-dark fw-medium`}>
            COMMENTS
          </h3>
          <div className={`${styles.scrollContainer} overflow-auto`}>
            {commentData.length > 0 ? (
              commentData.map((el, commentIdx) => (
                <div className={`row ${styles.item}`} key={commentIdx}>
                  <div className="col-2 col-md-3 col-sm-4 col-lg-2 d-flex justify-content-center mb-0">
                    <div className="w-100">
                      <div className="">
                        <img
                          src={
                            el?.createdBy?.additionalInfo?.profileImage
                              ? process.env.REACT_APP_S3_BUCKET +
                                el?.createdBy?.additionalInfo?.profileImage
                              : personPlaceholder
                          }
                          alt="icon"
                          width={50}
                          height={50}
                          className="rounded-circle object-fit-cover"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-10 col-md-9 col-sm-8 col-lg-10 d-flex justify-content-center mb-0">
                    <div className="w-100">
                      <Link to={`/userprofile/${el?.createdBy?._id}`}>
                        <h4
                          className={`ff-barlow mb-0 font-size-15 text-dark ${styles["text-dark"]}`}
                        >
                          {el?.createdBy?.firstName} {el?.createdBy?.lastName}
                        </h4>
                      </Link>
                      <p
                        className={` text-secondary mb-1 ff-barlow font-size-12`}
                      >
                        <TimeAgo date={el?.createdAt} />
                      </p>
                    </div>
                  </div>
                  <div className="col-12 mt-0 my-1">
                    <p
                      className={`${styles["text-para"]} text-secondary mb-0 py-2 ff-barlow font-size-14 fw-normal`}
                    >
                      {el?.content}
                    </p>
                    <div
                      className={`${styles["custom-border-color"]} pb-2`}
                    ></div>
                  </div>
                </div>
              ))
            ) : (
              <p>Be the first one to comment on this video.</p>
            )}
          </div>
          {isAuth && <VideoComment videoId={videoId} setLoading={setRefresh} />}
        </div>
      )}
      {status === "PENDING" && (
        <div>
          <div>
            <span className="font-size-18 fw-medium text-darkcolor text-uppercase">
              rules and regulations
            </span>
          </div>
          <div>
            <p
              className="fw-normal font-size-16 text-secondary"
              dangerouslySetInnerHTML={{ __html: rules }}
            ></p>
          </div>
        </div>
      )}
      {status === "REJECTED" && (
        <div>
          <div>
            <span className="font-size-18 fw-medium text-darkcolor text-uppercase">
              reason for rejecting video
            </span>
          </div>
          <div>
            <span className="font-size-16 text-secondary">{info?.comment}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoCommentListing
