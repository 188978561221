import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import pic1 from "assets/images/home/hero-event.png"
import next from "assets/images/next.svg"
import previous from "assets/images/previous.svg"
import DeleteModal from "components/Common/DeleteModal"
import axios from "axios"
import { toast } from "react-toastify"
import Switch from "react-switch"
import { Link } from "feather-icons-react/build/IconComponents"
export const data = [
  {
    id: 1,
    user: "john_doe",
    registeredOn: "2024-01-15",
    registrationWith: "Email",
    subscriptionStatus: "Active",
    action: "Edit/Delete",
  },
  {
    id: 2,
    user: "jane_smith",
    registeredOn: "2024-02-20",
    registrationWith: "Google",
    subscriptionStatus: "Active",
    action: "Edit/Delete",
  },
  {
    id: 3,
    user: "mike_jones",
    registeredOn: "2024-03-10",
    registrationWith: "Facebook",
    subscriptionStatus: "Active",
    action: "Edit/Delete",
  },
  {
    id: 4,
    user: "emma_wilson",
    registeredOn: "2024-04-05",
    registrationWith: "Email",
    subscriptionStatus: "Active",
    action: "Edit/Delete",
  },
  {
    id: 5,
    user: "liam_brown",
    registeredOn: "2024-05-25",
    registrationWith: "Twitter",
    subscriptionStatus: "Active",
    action: "Edit/Delete",
  },
]

const generateData = (baseData, multiplier) => {
  const newData = []
  for (let i = 0; i < multiplier; i++) {
    newData.push(
      ...baseData.map(item => ({ ...item, id: item.id + i * baseData.length }))
    )
  }
  return newData
}

const paginatedData = generateData(data, 10)

export default function AdminDockies({
  showUnblockColumn = false,
  actions = ["view", "edit", "delete"],
  showHeading = false,
  showDeactive = false,
  showName = false,
}) {
  const users = data.slice(0, 6)
  const [show, setShow] = React.useState(false)
  const toggleModal = () => {
    setShow(!show)
  }
  const handleDelete = async () => {
    try {
      await axios
        .delete("")

        .then(() => setShow(false))
        .then(() => toast("Video Deleted Successfully"))
    } catch (e) {}
  }
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8
  const totalPages = Math.ceil(paginatedData.length / itemsPerPage)
  const maxVisibleButtons = 5

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handlePageClick = pageNum => {
    setCurrentPage(pageNum)
  }

  const getVisiblePageNumbers = () => {
    const startPage = Math.max(
      1,
      Math.min(
        currentPage - Math.floor(maxVisibleButtons / 2),
        totalPages - maxVisibleButtons + 1
      )
    )
    const endPage = Math.min(startPage + maxVisibleButtons - 1, totalPages)

    return { startPage, endPage }
  }

  const { startPage, endPage } = getVisiblePageNumbers()

  const currentData = paginatedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const [isBlocked, setIsBlocked] = useState(true)

  const handleToggle = checked => {
    setIsBlocked(checked)
  }

  return (
    <section id="admin-dockies" className="page-content bg-skin">
      <Container fluid>
        <div>
          <DeleteModal
            show={show}
            onCloseClick={toggleModal}
            onDeleteClick={handleDelete}
          />
          <Row className="mb-0">
            <Col sm="6">
              {showUnblockColumn && (
                <td className="gray-700 font-size-16">
                  <div className="d-flex align-items-center">
                    <span className="ms-2 pe-2">
                      {isBlocked ? "Unblock" : "Block"}
                    </span>
                    <Switch
                      checked={isBlocked}
                      onChange={handleToggle}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#d9534f"
                      offColor="#198754"
                    />
                  </div>
                </td>
              )}
              {showName && (
                <h2 className="fw-semibold font-size-26 text-uppercase">
                  The Dockies
                </h2>
              )}
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-end align-items-center gap-2 bg-light">
                <select className="rounded-pill px-4 py-1">
                  <option selected>Filter By Category</option>
                  <option>Most Funny</option>
                  <option>Most Like</option>
                  <option>Most Viewed</option>
                </select>
              </div>
            </Col>
          </Row>
          <div className="overflow-auto card shadow p-3 rounded-4 mt-2">
            <table className="table">
              <thead>
                <tr className="my-2 ">
                  <th scope="col" className="font-size-14 text-darkcolor  ">
                    #ID
                  </th>
                  <th scope="col" className="font-size-14 text-darkcolor ">
                    USER
                  </th>
                  <th scope="col" className="font-size-14 text-darkcolor ">
                    REGISTERED ON
                  </th>
                  <th scope="col" className="font-size-14 text-darkcolor">
                    REGISTRATION WITH
                  </th>
                  <th scope="col" className="font-size-14 text-darkcolor">
                    SUBSCRIPTION STATUS
                  </th>
                  {showHeading && (
                    <th
                      scope="col"
                      className="font-size-14 text-darkcolor"
                    ></th>
                  )}

                  <th
                    scope="col"
                    className="font-size-14 text-darkcolor d-flex justify-content-center"
                  >
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {currentData.map((user, index) => (
                  <tr key={user.id}>
                    <td className="gray-700 font-size-16">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="">
                      <div className="gap-2 d-flex  align-items-center">
                        <img
                          src={pic1}
                          alt="person"
                          className="object-fit-cover rounded-3"
                          width={"48px"}
                          height={"48px"}
                        />
                        <span className="gray-700 font-size-16 text-uppercase">
                          {user.user}
                        </span>
                      </div>
                    </td>
                    <td className="gray-700 font-size-16 ">
                      {user.registeredOn}
                    </td>
                    <td className="gray-700 font-size-16">
                      {user.registrationWith}
                    </td>
                    <td className="gray-700 font-size-14">
                      <span
                        className={`badge p-2 text-secondary font-size-14 fw-normal rounded-pill ${
                          showDeactive ? "primary-light-red" : "light-green-bg"
                        }`}
                      >
                        {showDeactive ? "Deactive" : user.subscriptionStatus}
                      </span>
                    </td>

                    {showUnblockColumn && (
                      <td className="gray-700 font-size-16">
                        <div className="d-flex align-items-center">
                          <span className="ms-2 pe-2">
                            {isBlocked ? "Unblock" : "Block"}
                          </span>
                          <Switch
                            checked={isBlocked}
                            onChange={handleToggle}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#d9534f"
                            offColor="#198754"
                          />
                        </div>
                      </td>
                    )}
                    <td scope="col" className="align-middle">
                      <div className="d-flex justify-content-center gap-2">
                        {actions.includes("view") && (
                          <button className="btn btn-link p-0">
                            <i className="fa-light fa-eye gray-700 font-size-16" />
                          </button>
                        )}
                        {actions.includes("edit") && (
                          <button className="btn btn-link p-0">
                            <i className="fa-light fa-edit gray-700 font-size-16" />
                          </button>
                        )}
                        {actions.includes("delete") && (
                          <button
                            className="btn btn-link p-0"
                            onClick={toggleModal}
                          >
                            <i className="fa-light fa-trash gray-700 font-size-16" />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              Showing {currentPage * itemsPerPage} to {paginatedData.length}
            </div>
            <div className="pagination-container d-flex align-items-center">
              <button
                className="btn bg-dgray mx-1"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <img src={previous} alt="Previous" />
              </button>
              {[...Array(endPage - startPage + 1)].map((_, index) => (
                <button
                  key={startPage + index}
                  className={`btn bg-dgray mx-1 ${
                    currentPage === startPage + index
                      ? "light-green-color btn-success"
                      : ""
                  }`}
                  onClick={() => handlePageClick(startPage + index)}
                >
                  {startPage + index}
                </button>
              ))}
              <button
                className="btn bg-dgray mx-1"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <img src={next} alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
