import React, { useState, useEffect } from "react"
import upload from "assets/images/image upload.png"
import {
  Button,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import Switch from "react-switch"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { post, get, put } from "api/axios"
import endpoints from "api/endpoints"
import pic1 from "assets/images/delete.svg"
import { Bounce, toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"

import { stateFromHTML } from "draft-js-import-html"

export const BlogEditor = () => {
  const [videoUrl, setVideoUrl] = useState(null)
  const [newVideoUpload, setNewVideoUpload] = useState(false)
  const [mediaType, setMediaType] = useState(null)
  const navigate = useNavigate()
  const { id = null } = useParams()
  const [content, setContent] = useState(null)
  const [blogNumber, setBlogNumber] = useState([])
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const getBlogNumber = async () => {
    const resp = await get(`${endpoints.getBlogNumber}`)

    if (resp?.data?.success) {
      setBlogNumber(resp?.data?.orderNumber)
    }
  }

  const handleVideoUpload = (setFieldValue, e) => {
    const file = e.target.files[0]

    if (file.type.includes("image")) {
      setMediaType("image")
    } else if (file.type.includes("video")) {
      setMediaType("video")
    } else {
      setMediaType("other")
    }
    setFieldValue("file", file)
    if (file) {
      setVideoUrl(URL.createObjectURL(file))
      setNewVideoUpload(true)
    }
  }
  const handleVideoRemove = () => {
    setVideoUrl(null)
    setNewVideoUpload(false)
  }
  const [blog, setBlog] = useState({})
  const getBlogContent = async () => {
    const resp = await get(`${endpoints.blog}/${id}`)

    if (resp?.data?.success) {
      setBlog(resp?.data?.data)
      setMediaType(resp?.data?.data?.mediaType)
      resp?.data?.data?.mediaType == "image" &&
        setVideoUrl(
          `${process.env.REACT_APP_S3_BUCKET}${resp?.data?.data?.videoDetail?.thumbnail?.path}`
        )
      resp?.data?.data?.mediaType == "video" &&
        setVideoUrl(
          `${process.env.REACT_APP_S3_BUCKET}${resp?.data?.data?.videoDetail?.video?.Key}`
        )

      const htmlContent = resp?.data?.data?.content
      const contentState = stateFromHTML(htmlContent)
      setContent(EditorState.createWithContent(contentState))
    }
  }
  useEffect(() => {
    id != null && getBlogContent()
    getBlogNumber()
  }, [id])
  useEffect(() => {
    if (JSON.stringify(blog) !== "{}") {
      setTimeout(() => {
        setBlogNumber(prev => {
          // Check if values.order is already in prev
          if (prev.includes(blog.order)) {
            // If it exists, return the previous state without changes
            return prev
          } else {
            // If it doesn't exist, add values.order to the array
            return [...prev, blog.order]
          }
        })
      }, 1000)
    }
  }, [blog])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: blog?.title || "",
        content: blog?.content || "",
        category: blog?.category || "",
        file: null,
        featured: blog?.featured || false,
        order: blog?.order || "",
      }}
      validationSchema={Yup.object({
        title: Yup.string().required("Required"),
        content: Yup.string().required("Description is required"),
        category: Yup.string(),
        featured: Yup.boolean(),
        order: Yup.number()
          .nullable()
          .when("featured", (featured, schema) => {
            return featured?.at(0) == true
              ? schema
                  .required("Must select the order")
                  .min(1, "Order must be a positive number")
              : schema.notRequired()
          }),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const htmlContent = stateToHTML(content.getCurrentContent())
        let response = null

        setSubmitting(true)

        // Check if the file (video) is missing before submission
        if (!values.file && !videoUrl) {
          toast.error("Please Upload Video", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
          setSubmitting(false)
          return
        }

        const formData = new FormData()
        formData.append("title", values.title)
        formData.append("content", htmlContent)
        formData.append("featured", values.featured)
        formData.append("order", values.order)

        if (values.file && newVideoUpload) {
          formData.append("file", values.file)
        }

        // Check if it's an update or a new blog post
        if (id != null) {
          response = await put(`${endpoints.blog}/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        } else {
          response = await post(endpoints.blog, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        }

        if (response.status === 200 || response.status === 202) {
          toast.success(
            id != null ? "Updated Successfully" : "Added Successfully",
            {
              position: "bottom-center",
              theme: "colored",
              transition: Bounce,
            }
          )
          handleVideoRemove()
          setContent(null)
          resetForm()
          navigate("/how-it-works")
        } else {
          setSubmitting(false)
          toast.error("An error occurred. Please try again.", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        formProps,
        setValues,
        /* and other goodies */
      }) => (
        <Form method="post" className="wysiwyg-custom">
          <div className="card p-3 m-2 roundd-3">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between">
                <span className="font-size-18 fw-medium text-darkcolor ">
                  VIDEO
                </span>
              </div>
              <div>
                <FormGroup
                  onDragOver={e => e.preventDefault()}
                  onDrop={e => {
                    e.preventDefault()
                    const file = e.dataTransfer.files[0]
                    if (file && file.type.startsWith("video/")) {
                      const videoUrl = URL.createObjectURL(file)
                      setVideoUrl(videoUrl)
                      values.file = file
                      setNewVideoUpload(true)
                      setMediaType("video")
                    } else if (file && !file.type.startsWith("video/")) {
                      toast.error("Upload a Video file")
                    }
                  }}
                  className="position-relative"
                >
                  <Label
                    for="videoUpload"
                    className={`upload-label d-block w-100 text-center ${
                      videoUrl ? "d-none" : ""
                    }`}
                  >
                    <div className="btn bg-light p-4 rounded-4 w-auto text-center">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-center">
                          <img
                            src={upload}
                            alt="upload"
                            className="img-fluid w-50"
                          />
                        </div>
                        <span className="font-size-12 secondary-white">
                          Upload Video
                        </span>
                      </div>
                    </div>
                  </Label>
                  <Input
                    type="file"
                    accept="video/*"
                    name="file"
                    id="videoUpload"
                    className="d-none"
                    onChange={e => handleVideoUpload(setFieldValue, e)}
                  />
                  {videoUrl && mediaType === "video" ? (
                    <div
                      className="d-flex align-items-start position-relative justify-content-center"
                      style={{ height: "40vh" }}
                    >
                      <video className="uploaded-video rounded-4" controls>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <Button
                        className="delete-button position-absolute rounded-circle bg-white"
                        onClick={handleVideoRemove}
                      >
                        <img src={pic1} alt="" />
                      </Button>
                    </div>
                  ) : videoUrl && mediaType === "image" ? (
                    <div className="d-flex align-items-start position-relative h-20 justify-content-center">
                      <img
                        src={videoUrl}
                        alt="Upload Images"
                        className="img-fluid"
                        style={{
                          height: "clamp(250px, 207.1429px + 13.3929vw, 400px)",
                        }}
                      />
                      <Button
                        className="delete-button position-absolute rounded-circle bg-white"
                        onClick={handleVideoRemove}
                      >
                        <img src={pic1} alt="" />
                      </Button>
                    </div>
                  ) : (
                    videoUrl && (
                      <div className="d-flex align-items-start position-relative justify-content-center">
                        <p type="invalid" className="text-danger">
                          This is not a correct format to upload. Only image and
                          video files are accepted. Please remove the incorrect
                          file and upload another one.
                          <Button
                            className="delete-button rounded-circle bg-white"
                            onClick={handleVideoRemove}
                          >
                            <img src={pic1} alt="" />
                          </Button>
                        </p>
                      </div>
                    )
                  )}
                </FormGroup>
                {/* {touched.file && errors.file ? (
                  <div className="text-danger"> Please Select the Video</div>
                ) : null} */}
              </div>
              <div className="mt-5 mt-xl-0">
                <span className="font-size-18 text-uppercase fw-medium text-darkcolor ">
                  other details
                </span>
                <FormGroup floating>
                  <Input
                    id="blog-title"
                    name="title"
                    placeholder="Enter Title"
                    type="text"
                    className="rounded-4 border-2 green-border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title || ""}
                    invalid={touched.title && errors.title ? true : false}
                  />
                  <Label for="exampleEmail" className="text-uppercase">
                    title
                  </Label>
                  {touched.title && errors.title ? (
                    <FormFeedback type="invalid">{errors.title}</FormFeedback>
                  ) : null}
                </FormGroup>
                {blogNumber.length > 0 && !id && (
                  <FormGroup>
                    <div className="d-flex justify-content-between py-4">
                      <Label for="exampleEmail" className="text-uppercase">
                        Current Selection
                      </Label>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#64B98B"
                        onChange={() => {
                          setFieldValue("featured", !values.featured)
                        }}
                        checked={values.featured}
                      />
                    </div>
                    {touched.featured && errors.featured ? (
                      <FormFeedback type="invalid">
                        {errors.featured}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                )}
                {id && blogNumber.length > 0 && (
                  <FormGroup>
                    <div className="d-flex justify-content-between py-4">
                      <Label for="exampleEmail" className="text-uppercase">
                        Current Selection
                      </Label>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#64B98B"
                        onChange={() => {
                          setFieldValue("featured", !values.featured)
                          setBlogNumber(prev => {
                            // Check if values.order is already in prev
                            if (prev.includes(values.order)) {
                              // If it exists, return the previous state without changes
                              return prev
                            } else {
                              // If it doesn't exist, add values.order to the array
                              return [...prev, values.order]
                            }
                          })
                        }}
                        checked={values.featured}
                      />
                    </div>
                    {touched.featured && errors.featured ? (
                      <FormFeedback type="invalid">
                        {errors.featured}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                )}
                {values.featured && (
                  <FormGroup>
                    <Label for="exampleEmail" className="text-uppercase">
                      Select Order
                    </Label>
                    <Input
                      type="select"
                      name="order"
                      id="order"
                      className=" gray-700 font-size-14 rounded-4"
                      onChange={e => {
                        values.order = e.target.value
                      }}
                      invalid={touched.order && errors.order ? true : false}
                    >
                      {values.order ? (
                        <></>
                      ) : (
                        <option value={""} selected={true} disabled={true}>
                          Select Order
                        </option>
                      )}
                      {blogNumber &&
                        blogNumber.map((_, index) => (
                          <option
                            key={index}
                            value={_}
                            selected={blog?.order == _ ? true : false}
                          >
                            {_}
                          </option>
                        ))}
                    </Input>
                    {touched.order && errors.order ? (
                      <FormFeedback type="invalid">{errors.order}</FormFeedback>
                    ) : null}
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="exampleEmail" className="text-uppercase">
                    Description
                  </Label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName px-3"
                    editorState={content}
                    onEditorStateChange={editorState => {
                      setFieldValue(
                        "content",
                        editorState.getCurrentContent().getPlainText()
                      )
                      setContent(editorState)
                    }}
                  />

                  {touched.content && errors.content ? (
                    <div className="text-danger">
                      Must be 150 characters or greater
                    </div>
                  ) : null}
                </FormGroup>
                <Button
                  className="btn btn-primary-green"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={e => {}}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
