import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import Placeholders from "pages/Home/components/Placeholders"

import CheckImg from "assets/images/check.svg"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"

export default function VideoVaults() {
  const [active, setActive] = useState("all")
  const tabsMenu = [
    "all",
    "dads jokes",
    "new and trending",
    "best seller",
    "independence",
    "heartthrobs",
  ]

  const [videoData, setVideoData] = useState([])
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })

  const getFetch = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}?page=${nextPage}&limit=12&filter=${filter?.action}`
    )
    if (resp?.data?.success) {
      setVideoData(prev => [...prev, ...resp?.data?.data])
      setLoadMore({
        currentPage: resp?.data?.currentPage,
        lastPage: resp?.data?.totalPages,
      })
    }
  }

  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1
    getFetch(nextPage)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filter, setFilter] = useState({
    action: "active",
    text: "All",
  })
  const toggle = () => setDropdownOpen(prevState => !prevState)

  useEffect(() => {
    setVideoData([])
    getFetch()
  }, [filter])

  return (
    <section id="user-profile" className="">
      <Container fluid>
        <div className="d-flex flex-column  position-relative z-1 ">
          <h2 className="ff-cooper text-uppercase font-size-56 fw-normal blue-text-color  pt-3 text-uppercase d-flex justify-content-between">
            Video Vault
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
              <DropdownToggle
                caret
                className="d-flex justify-content-between align-items-center filter-btn"
              >
                <span> {filter.text}</span>
                <img src={CheckImg} className="px-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={e =>
                    setFilter({
                      action: "lastMonth",
                      text: "Last Month",
                    })
                  }
                  value={"Last Month"}
                >
                  Last Month
                </DropdownItem>
                <DropdownItem
                  onClick={e =>
                    setFilter({
                      action: "threeMonth",
                      text: "Last Three Month",
                    })
                  }
                  value={"Last Three Month"}
                >
                  Last Three Month
                </DropdownItem>
                <DropdownItem
                  onClick={e =>
                    setFilter({
                      action: "lastYear",
                      text: "Last Year",
                    })
                  }
                  value={"Last Year"}
                >
                  Last Year
                </DropdownItem>
                <DropdownItem
                  onClick={e =>
                    setFilter({
                      action: "active",
                      text: "Older",
                    })
                  }
                  value={"Older"}
                >
                  Older
                </DropdownItem>
                <DropdownItem
                  onClick={e =>
                    setFilter({
                      action: "active",
                      text: "All",
                    })
                  }
                  value={"Active"}
                >
                  All
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </h2>
          <p className="font-size-20 fw-normal gray-700 ff-proxima">
            Great jokes live forever. See what The Dockies have been cookin’ up:
          </p>
        </div>

        <Row className=" ">
          <Col xs="12">
            <Row className="mt-2 row-gap-3 mb-5">
              {videoData?.length > 0 &&
                videoData.map(card => (
                  <Col sm={6} lg={4} xxl={3} className="mb-3" key={card.id}>
                    <VideoCard data={card} />
                  </Col>
                ))}
            </Row>
            {loadMore.lastPage !== loadMore.currentPage && (
              <Row className="justify-content-center mb-5 pb-5">
                <div className="text-center">
                  <button
                    className="text-uppercase font-size-14 btn btn-primary-green"
                    onClick={handleLoadMore}
                  >
                    Load More Come on, show us what you’ve got!
                  </button>
                </div>
              </Row>
            )}
            <Row>{videoData.length <= 0 && <Placeholders />}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
