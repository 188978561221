import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import TimeAgo from "react-timeago"
import { get, put } from "api/axios"

import avatar3 from "assets/images/personPlaceholder.png"

import pic1 from "../../../assets/images/noti-pic.png"

import { withTranslation } from "react-i18next"
import endpoints from "../../../api/endpoints"
import { toast } from "react-toastify"

const notificationRoutes = {
  VIDEO_POST: id => `/videodetail/${id}`,
  COMMENT: id => `/videodetail/${id}`,
  VOTE: id => `/videodetail/${id}`,
  BLOG: id => `/how-it-works-details/${id}`,
  TOPIC: id => `/header-video-details/${id}`,
  CHALLENGE: id => `/challengedetail/${id}`,
  WINNER: id => `/videodetail/${id}`,
  EVENT: id => `/eventdetail/${id}`,
  APPROVAL: id => `/videodetail/${id}`,
}

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)
  const navigate = useNavigate()

  const getData = async () => {
    try {
      const response = await get(`${endpoints.notification}?page=1&limit=5`)
      if (response.status === 200) {
        setNotificationList(response.data.data)
        const unreadCount = response.data.data.filter(
          notification => !notification.readStatus
        ).length
        setNotificationCount(unreadCount)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const markNotification = async notification => {
    const {
      _id: notificationId,
      type,
      data: { id: relatedId },
    } = notification
    try {
      const response = await put(`${endpoints.notification}`, {
        notificationId,
      })
      if (response.status === 200) {
        getData()
        const route = notificationRoutes[type]
        if (route) {
          navigate(route(relatedId))
        } else {
          toast.info("Unknown notification type")
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const markAllHandle = async () => {
    try {
      const response = await put(`${endpoints.notificationAll}`)
      if (response.status === 200) {
        const updatedNotifications = notificationList.map(notification => ({
          ...notification,
          readStatus: true,
        }))
        setNotificationList(updatedNotifications)
        setNotificationCount(0)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="dropdown d-inline-block"
      tag="li"
    >
      <DropdownToggle
        className="btn header-item noti-icon waves-effect"
        tag="button"
        id="page-header-notifications-dropdown"
      >
        <i className="uil-bell"></i>
        <span className="badge bg-danger rounded-pill">
          {notificationCount}
        </span>
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0 rounded-4 ">
        <div className="p-3 shadow">
          <Row className="align-items-center">
            <Col>
              <div className="d-flex align-items-center">
                <h3 className="m-0 font-size-18 text-uppercase fw-medium text-darkcolor">
                  {props.t("Notifications")}
                </h3>
                <span
                  className="badge pre-color fw-medium font-size-15 ms-1 d-flex justify-content-center align-items-center"
                  style={{
                    borderRadius: "50%",
                    width: "1.5em",
                    height: "1.5em",
                  }}
                >
                  {notificationCount}
                </span>
              </div>
            </Col>
            <div className="col-auto">
              <Link
                className="small pre-text font-size-14 fw-normal"
                onClick={e => markAllHandle()}
              >
                {props.t("Mark all as read")}
              </Link>
            </div>
          </Row>
        </div>

        <SimpleBar
          style={{
            height: notificationList.length <= 0 ? "100px" : "250px",
          }}
        >
          {notificationList.length > 0 ? (
            notificationList.map(item => (
              <Link
                to="#"
                className="text-dark notification-item"
                key={item?._id}
                onClick={e => markNotification(item)}
              >
                <div
                  className="d-flex align-items-start position-relative"
                  style={
                    !item?.readStatus
                      ? {
                          backgroundColor: "#f8f9fa",
                          borderBottom: "1px solid #d6cece",
                        }
                      : {}
                  }
                >
                  {!item?.readStatus && (
                    <i
                      className="fa fa-circle position-absolute end-0 font-size-10 marker-px pe-2"
                      aria-hidden="true"
                    ></i>
                  )}
                  <img
                    src={
                      item?.sender?.additionalInfo?.profileImage
                        ? process.env.REACT_APP_S3_BUCKET +
                          item?.sender?.additionalInfo?.profileImage
                        : avatar3
                    }
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                    onClick={e => {
                      e.stopPropagation() // Prevent triggering the main notification click
                      navigate(`/userprofile/${item?.sender?._id}`)
                    }}
                  />
                  <div className="flex-1">
                    <h6
                      className="mt-0 mb-1"
                      onClick={e => {
                        e.stopPropagation() // Prevent triggering the main notification click
                        navigate(`/userprofile/${item?.sender?._id}`)
                      }}
                    >
                      {item?.sender?.firstName} {item?.sender?.lastName}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{item?.action}</p>
                      {item?.data?.thumbnail && (
                        <img
                          src={
                            process.env.REACT_APP_S3_BUCKET +
                              item?.data?.thumbnail || pic1
                          }
                          className="img-fluid rounded-4 object-fit-cover"
                          style={{ height: "15vh", width: "10vh" }}
                          alt="."
                        />
                      )}
                      <br />
                      <div className="mt-2">
                        <div className="mt-1 ps-2 border-start border-2 border-success">
                          <span className="fw-normal font-size-14 ff-barlow text-secondary">
                            {item?.message}
                          </span>
                        </div>
                      </div>
                      <p className="mb-0">
                        <TimeAgo date={item?.createdAt} />
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p className="text-secondary front-size-14 notification-item text-center">
              No Notification found!
            </p>
          )}
        </SimpleBar>
        {notificationList.length > 0 && (
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center pre-text text-uppercase"
              to="/notificationlist"
              onClick={() => setMenu(false)}
            >
              {props.t("view all notification")}
            </Link>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NotificationDropdown)
