import React, { useEffect, useState } from "react"
import { Input, Row, Col } from "reactstrap"
import debounce from "lodash.debounce"
import PropTypes from "prop-types"
import endpoints from "api/endpoints"
import { get } from "api/axios"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import TimeAgo from "react-timeago"

function SearchDropDown({ text, onClose }) {
  const [search, setSearch] = useState("")
  const [videoData, setVideoData] = useState([])
  const delayedHandleChange = debounce(eventData => {
    if (search !== "" || search !== undefined) {
      getFetch(eventData)
    }
  }, 500)

  const getFetch = async text => {
    const trimmedText = text.trim()
    if (trimmedText.length === 0) {
      return
    }

    try {
      const resp = await get(
        `${endpoints.getVideos}?&filter=search&search=${trimmedText}`
      )
      if (resp?.data?.success) {
        setVideoData(resp?.data?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getFetch(search)
  }, [search])

  const handleVideoClick = () => {
    if (onClose) onClose()
  }

  return (
    <div className="d-flex mt-1 overflow-auto">
      <div className="p-3" style={{ width: "250px" }}>
        {!text && (
          <div className="d-flex gap-3 align-items-center mb-3">
            <Input
              value={search}
              placeholder="Search video by title"
              onChange={e => {
                setSearch(e.target.value)
                delayedHandleChange(e.target.value)
              }}
            />
            <button className="btn p-0">
              <i className="fa fa-search font-size-26" />
            </button>
          </div>
        )}
        <div>
          <SimpleBar style={{ maxHeight: "300px" }}>
            <Row className="row-gap-3 mb-5">
              {videoData?.length > 0 &&
                videoData.map(card => (
                  <div key={card.id}>
                    <div className="row align-items-center">
                      <div className="image-container position-relative col-5">
                        <Link
                          to={`/videodetail/${card?._id}`}
                          onClick={handleVideoClick} // Close dropdown on click
                        >
                          <img
                            src={`${process.env.REACT_APP_S3_BUCKET}${card?.thumbnail}`}
                            alt=""
                            className="w-100 rounded-1 object-fit-cover"
                            height={"50px"}
                          />
                        </Link>
                      </div>
                      <div className="col-7">
                        <h3 className="font-size-14 fw-medium text-darkcolor">
                          <Link
                            to={`/videodetail/${card?._id}`}
                            onClick={handleVideoClick} // Close dropdown on click
                          >
                            {card?.title}
                          </Link>
                        </h3>
                        <p className="font-size-12 text-secondary p-0 m-0">
                          <TimeAgo date={card.updatedAt} />
                        </p>
                      </div>
                    </div>
                    <hr className="p-0 m-0 mt-1 text-secondary" />
                  </div>
                ))}
            </Row>
          </SimpleBar>
        </div>
      </div>
    </div>
  )
}

SearchDropDown.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func, // Add onClose prop type
}

export default SearchDropDown
