import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Formik, Form, Field } from "formik"
import { Bounce, toast } from "react-toastify"
import { Container, FormGroup, FormFeedback, Input, Label } from "reactstrap"
import * as Yup from "yup"
import { post, get, put } from "api/axios"
import endpoints from "api/endpoints"

export default function AddAwards() {
  const navigate = useNavigate()
  const { id = null } = useParams()
  const [award, setAward] = useState()

  const getAwardContent = async () => {
    const resp = await get(`${endpoints.award}/${id}`)

    if (resp?.data?.success) {
      setAward(resp?.data?.data)
    }
  }
  useEffect(() => {
    id != null && getAwardContent()
  }, [id])

  return (
    <section id="add-awards" className="page-content">
      <Container fluid>
        <div>
          <div>
            <span className="font-size-26 text-darkcolor text-uppercase fw-medium">
              Add New Award
            </span>
          </div>

          <Formik
            enableReinitialize
            initialValues={{
              award: award?.award || "",
              awardType: award?.awardType || "GIFTPACK",
            }}
            validationSchema={Yup.object({
              award: Yup.string()
                .max(50, "Must be 50 characters or less")
                .required("Required"),

              awardType: Yup.string(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              let response
              setSubmitting(true)
              if (id != null) {
                response = await put(`${endpoints.award}/${id}`, values)
              } else {
                response = await post(endpoints.award, values)
              }

              if (response.status === 200 || response.status === 202) {
                id != null
                  ? toast.success("Award Updated Successfully", {
                      position: "bottom-center",
                      theme: "colored",
                      transition: Bounce,
                    })
                  : toast.success("Award Added Successfully", {
                      position: "bottom-center",
                      theme: "colored",
                      transition: Bounce,
                    })

                resetForm()
                navigate("/awards-list")
              } else {
                setSubmitting(false)
                toast.error("Award Already Exists", {
                  position: "bottom-center",
                  theme: "colored",
                  transition: Bounce,
                })
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              formProps,
              resetForm,
              setValues,
              /* and other goodies */
            }) => (
              <Form>
                <div className="card p-3 shadow rounded-4">
                  <div className="row">
                    <div className="col-12 col-md-6 g-3 ">
                      <FormGroup floating>
                        <Input
                          id="awrds-type"
                          name="awardType"
                          placeholder="Award Type"
                          type="select"
                          className="rounded-4 border-2 green-border"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.awardType || ""}
                          invalid={
                            touched.awardType && errors.awardType ? true : false
                          }
                        >
                          <option value={"GIFTPACK"} key={0}>
                            GIFTPACK
                          </option>
                          <option value={"CASH"} key={1}>
                            CASH
                          </option>
                        </Input>
                        <Label for="awrds-type" className="text-uppercase">
                          Award Category
                        </Label>
                        {touched.awardType && errors.awardType ? (
                          <FormFeedback type="invalid">
                            {errors.awardType}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </div>

                    <div className="col-12 col-md-6 g-3">
                      <FormGroup floating>
                        <Input
                          id="award-cat"
                          name="award"
                          placeholder="Award Category"
                          type="text"
                          className="rounded-4 border-2 green-border"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.award || ""}
                          invalid={touched.award && errors.award ? true : false}
                        />
                        <Label for="award-cat" className="text-uppercase">
                          Award Type
                        </Label>
                        {touched.award && errors.award ? (
                          <FormFeedback type="invalid">
                            {errors.award}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="d-flex gap-3 justify-content-end mt-3">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary-green text-uppercase"
                    >
                      {id ? "Update Award" : "Add Award"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary text-uppercase"
                      onClick={() => resetForm()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  )
}
