import React, { useState, useEffect } from "react"
import upload from "assets/images/image upload.png"
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import Switch from "react-switch"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { post, get, put } from "api/axios"
import endpoints from "api/endpoints"
import pic1 from "assets/images/delete.svg"
import { Bounce, toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"

import { stateFromHTML } from "draft-js-import-html"

export const TopicEditor = () => {
  const [videoUrl, setVideoUrl] = useState(null)
  const [newVideoUpload, setNewVideoUpload] = useState(false)
  const [mediaType, setMediaType] = useState(null)
  const navigate = useNavigate()
  const { id = null } = useParams()
  const [content, setContent] = useState(null)
  const [rules, setRules] = useState(null)
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const handleVideoUpload = (setFieldValue, e) => {
    const file = e.target.files[0]
    if (file.type.includes("image")) {
      setMediaType("image")
    } else {
      setMediaType("other")
    }
    setFieldValue("file", file)
    if (file) {
      setVideoUrl(URL.createObjectURL(file))
      setNewVideoUpload(true)
    }
  }
  const handleVideoRemove = () => {
    setVideoUrl(null)
    setNewVideoUpload(false)
  }
  const [topic, setTopic] = useState({})
  const getTopicContent = async () => {
    const resp = await get(`${endpoints.topic}/${id}`)
    if (resp?.data?.success) {
      setTopic(resp?.data?.data)
      setVideoUrl(
        `${process.env.REACT_APP_S3_BUCKET}${resp?.data?.data?.videoDetail?.video?.Key}`
      )

      const htmlContent = resp?.data?.data?.description
      const contentState = stateFromHTML(htmlContent)
      setContent(EditorState.createWithContent(contentState))
      const htmlRules = resp?.data?.data?.rules
      const rulesState = stateFromHTML(htmlRules)
      setRules(EditorState.createWithContent(rulesState))
    }
  }
  // const initialValues = {

  // }
  const handleDragOver = e => {
    e.preventDefault()
  }

  useEffect(() => {
    id != null && getTopicContent()
  }, [id])
  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: topic?.title || "",
        description: topic?.description || "",
        rules: topic?.rules || "",
        file: null,
        featured: topic?.featured || false,
      }}
      validationSchema={Yup.object({
        title: Yup.string()
          .max(50, "Must be 50 characters or less")
          .required("Required"),
        rules: id != null ? null : Yup.object().required("Required"),
        description: id != null ? null : Yup.object().required("Required"),
        file:
          id != null
            ? Yup.mixed().nullable()
            : Yup.mixed().required("Required"),
        featured: Yup.boolean(),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const htmlContent = stateToHTML(content.getCurrentContent())
        const htmlRules = stateToHTML(rules.getCurrentContent())
        let response = null

        setSubmitting(false)
        if (!values.file && !videoUrl) {
          toast.error("Please Upload Video", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
          setSubmitting(false)
          return
        }

        const formData = new FormData()
        formData.append("title", values.title)
        formData.append("description", htmlContent)
        formData.append("rules", htmlRules)
        formData.append("featured", values.featured)

        if (values.file && newVideoUpload) {
          formData.append("file", values.file)
        }
        if (id != null) {
          response = await put(`${endpoints.topic}/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        } else {
          response = await post(endpoints.topic, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        }

        if (response.status === 200 || response.status === 202) {
          id != null
            ? toast.success("Header Video Updated Successfully", {
                position: "bottom-center",
                theme: "colored",
                transition: Bounce,
              })
            : toast.success("Header Video Added Successfully", {
                position: "bottom-center",
                theme: "colored",
                transition: Bounce,
              })
          handleVideoRemove()
          setContent(null)
          resetForm()
          navigate("/header-videos")
        } else {
          setSubmitting(false)
          toast.error("Please Upload Video", {
            position: "bottom-center",
            theme: "colored",
            transition: Bounce,
          })
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        formProps,
        setValues,
        /* and other goodies */
      }) => (
        <Form method="post" className="wysiwyg-custom">
          <div className="card p-3 m-2 roundd-3">
            <div className="d-flex flex-column gap-3">
              <span className="font-size-18 fw-medium text-darkcolor ">
                VIDEO{" "}
              </span>
              <div>
                <FormGroup
                  onDragOver={handleDragOver}
                  onDrop={e => {
                    e.preventDefault()
                    const file = e.dataTransfer.files[0]
                    if (file && file.type.startsWith("video/")) {
                      const videoUrl = URL.createObjectURL(file)
                      setVideoUrl(videoUrl)
                      values.file = file
                      setNewVideoUpload(true)
                      setMediaType("video")
                    }
                    if (file && !file.type.startsWith("video/")) {
                      toast.error("Upload a Video file")
                    }
                  }}
                  className="position-relative"
                >
                  <Label
                    for="videoUpload"
                    className={`upload-label d-block w-100 text-center  ${
                      videoUrl ? "d-none" : ""
                    }`}
                  >
                    <div className="btn bg-light p-4 rounded-4 w-auto text-center">
                      <div className="d-flex flex-column ">
                        <div className="d-flex justify-content-center">
                          <img
                            src={upload}
                            alt="upload"
                            className="img-fluid w-50"
                          />
                        </div>
                        <span className="font-size-12 secondary-white">
                          Upload Video
                        </span>
                      </div>
                    </div>
                  </Label>
                  <Input
                    type="file"
                    accept="video/*"
                    name="file"
                    id="videoUpload"
                    className="d-none "
                    onChange={e => handleVideoUpload(setFieldValue, e)}
                    invalid={touched.file && errors.file ? true : false}
                  />
                  {videoUrl ? (
                    <div className="d-flex align-items-start position-relative h-20 justify-content-center">
                      <video className="uploaded-video rounded-4" controls>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <Button
                        className="delete-button position-absolute rounded-circle bg-white"
                        onClick={handleVideoRemove}
                      >
                        <img src={pic1} alt="" />
                      </Button>
                    </div>
                  ) : (
                    videoUrl && (
                      <div className="d-flex align-items-start position-relative h-20 justify-content-center">
                        <img
                          src={videoUrl}
                          alt="Upload Images"
                          className="img-fluid "
                          style={{
                            height:
                              "clamp(250px, 207.1429px + 13.3929vw, 400px)",
                          }}
                        />
                        <Button
                          className="delete-button position-absolute rounded-circle bg-white"
                          onClick={handleVideoRemove}
                        >
                          <img src={pic1} alt="" />
                        </Button>
                      </div>
                    )
                  )}
                </FormGroup>

                {touched.file && errors.file ? (
                  <div className="text-danger"> Please Select the Video</div>
                ) : null}
              </div>
              <div>
                <span className="font-size-18 text-uppercase fw-medium text-darkcolor">
                  other details
                </span>
                <FormGroup floating>
                  <Input
                    id="topic-title"
                    name="title"
                    placeholder="topic-title"
                    type="text"
                    className="rounded-4 border-2 green-border"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title || ""}
                    invalid={touched.title && errors.title ? true : false}
                  />
                  <Label for="exampleEmail" className="text-uppercase">
                    title
                  </Label>
                  {touched.title && errors.title ? (
                    <FormFeedback type="invalid">{errors.title}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <div className="d-flex justify-content-between py-4">
                    <Label for="exampleEmail" className="text-uppercase">
                      current selection
                    </Label>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor="#64B98B"
                      onChange={() => {
                        setFieldValue("featured", !values.featured)
                      }}
                      checked={values.featured}
                    />
                  </div>
                  {/* {touched.featured && errors.featured ? (
                    <div className="text-danger"> Please Select the Video</div>
                  ) : null} */}
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail" className="text-uppercase">
                    Rules
                  </Label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName px-3"
                    editorState={rules}
                    onEditorStateChange={editorState => {
                      setFieldValue("rules", editorState)
                      setRules(editorState)
                    }}
                  />
                  {touched.rules && errors.rules ? (
                    <div className="text-danger">
                      Please Enter the Rules for Videos
                    </div>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail" className="text-uppercase">
                    Description
                  </Label>
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName px-3"
                    editorState={content}
                    onEditorStateChange={editorState => {
                      setFieldValue("description", editorState)
                      setContent(editorState)
                    }}
                  />
                </FormGroup>
                {touched.description && errors.description ? (
                  <div className="text-danger">
                    Please Enter the Description
                  </div>
                ) : null}
                <Button
                  className="btn btn-primary-green"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
