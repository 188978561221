import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import pic from "assets/images/home/challenge1.png"
import { get, del } from "api/axios"
import endpoints from "api/endpoints"
import TimeAgo from "react-timeago"
import DeleteModal from "components/Common/DeleteModal"
import { Badge, Button } from "reactstrap"
import { useSelector } from "react-redux"

export const BlogDetails = () => {
  const { id } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const navigate = useNavigate()
  const [blog, setBlog] = useState({})
  const contentHandler = async () => {
    const resp = await get(`${endpoints.blog}/${id}`)
    if (resp?.data?.success) {
      setBlog(resp?.data?.data)
    }
  }
  const contentDeleteHandler = async () => {
    const resp = await del(`${endpoints.blog}/${id}`)
    if (resp?.data?.success) {
      navigate("/how-it-works")
    }
  }
  const { currentUser } = useSelector(state => state.auth)

  useEffect(() => {
    contentHandler()
  }, [])

  return (
    <div className="page-content bg-skin">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={contentDeleteHandler}
        onCloseClick={e => setDeleteModal(false)}
      />
      <div className="container-fluid">
        <div className="card p-3 ">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex  flex-column flex-md-row justify-content-between align-items-md-center">
              <div className="d-flex flex-column">
                <span className="font-size-16 gray-700">
                  <TimeAgo date={blog?.createdAt} />
                </span>
                <span className="font-size-26 fw-medium text-darkcolor">
                  {blog?.title}

                  {blog?.featured && currentUser.userRole == "admin" && (
                    <>
                      <Badge color="primary mx-3">Current Selection</Badge>
                      <Badge color="primary">{blog.order}</Badge>
                    </>
                  )}
                </span>
              </div>
              {currentUser.userRole == "admin" && (
                <div className="d-flex justify-content-end d-md-inline">
                  <Button
                    className=" btn-primary-red-outline  mx-2 mt-1"
                    onClick={e => setDeleteModal(true)}
                  >
                    DELETE
                  </Button>

                  <Link to={`/edit-details/${blog?._id}`}>
                    <Button className="btn btn-primary-green-outline  mt-1">
                      EDIT DETAILS
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <div>
              <span className="font-size-16 gray-700">{blog.description}</span>
            </div>
            <div className="d-flex justify-content-center">
              {blog?.mediaType === "image" ? (
                <img
                  src={
                    blog?.videoDetail?.thumbnail?.path
                      ? `${process.env.REACT_APP_S3_BUCKET}${blog?.videoDetail?.thumbnail?.path}`
                      : pic
                  }
                  alt="blog"
                  className="img-fluid rounded-4 h-auto object-fit-cover"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "500px",
                  }}
                />
              ) : (
                <video
                  src={`${process.env.REACT_APP_S3_BUCKET}${blog?.videoDetail?.video?.Key}`}
                  poster={`${process.env.REACT_APP_S3_BUCKET}${blog?.videoDetail?.thumbnail?.path}`}
                  controls
                  className="rounded-4 w-100 h-auto object-fit-cover"
                  style={{
                    maxWidth: "400px",
                    maxHeight: "500px",
                  }}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>

            <div>
              <span
                className="font-size-16 gray-700"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
