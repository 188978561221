import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { NavLink, Link } from "react-router-dom"
import withRouter from "../Common/withRouter"
import classname from "classnames"
import { useDispatch } from "react-redux"
import { toggleLeftmenu } from "../../store/slices/settingsSlice"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"

// Import your SVG icons
import { ReactComponent as DashboardIcon } from "assets/images/dash-icon.svg"
import { ReactComponent as DockieIcon } from "assets/images/dockie-icon.svg"
import { ReactComponent as StaffIcon } from "assets/images/staff-icon.svg"
import { ReactComponent as VideoIcon } from "assets/images/video-vaults-icon.svg"
import { ReactComponent as AwardIcon } from "assets/images/award-icon.svg"
import { ReactComponent as AboutIcon } from "assets/images/about-icon.svg"
import { ReactComponent as WorkIcon } from "assets/images/how-it-works-icon.svg"
import { ReactComponent as HeaderIcon } from "assets/images/header-icon.svg"
import { ReactComponent as HomeIcon } from "assets/images/home-icon.svg"

const Navbar = props => {
  const [showDropdown, setShowDropdown] = useState({ type: "", show: true })
  const leftMenu = useSelector(state => state.settings.leftMenu)
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.auth)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const adminItem =
    currentUser.userRole === "admin"
      ? [
          {
            title: "Header Video",
            path: "/header-videos",
            iconSVG: <HeaderIcon />,
          },
          {
            title: "Awards",
            path: "/awards-list",
            iconSVG: <AwardIcon />,
          },
          {
            title: "Staff",
            path: "/staff",
            iconSVG: <StaffIcon />,
          },
        ]
      : []

  const menuItems = [
    {
      path: "/",
      iconSVG: <HomeIcon className="home-icon" />,
    },
    {
      title: "Dashboard",
      path:
        currentUser.userRole === "subscriber"
          ? "/dashboard"
          : "/admin-dashboard",
      iconSVG: <DashboardIcon />,
    },
    {
      title: "About",
      path: "/about",
      iconSVG: <AboutIcon />,
    },
    {
      title: "The Dockie Awards",
      path: "/thedockies",
      iconSVG: <DockieIcon />,
    },
    {
      title: "Video Vault",
      path: "/videovaults",
      iconSVG: <VideoIcon />,
    },
    {
      title: "How It Works",
      path: "/how-it-works",
      iconSVG: <WorkIcon />,
    },
    ...adminItem,
  ]

  const dropdownToggle = (e, title) => {
    e.preventDefault()
    setShowDropdown({
      type: title,
      show: !showDropdown.show,
    })
  }

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu "
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav d-flex align-items-lg-center mt-2 mt-lg-0">
                {menuItems.map((el, index) => (
                  <li
                    className={`nav-item ${
                      el.subMenu ? "dropdown" : ""
                    } text-center `}
                    key={index}
                  >
                    <NavLink
                      to={el.path}
                      className={({ isActive }) =>
                        isActive
                          ? "pre-text nav-link nav-link-active nav-item text-uppercase active"
                          : "nav-link text-uppercase"
                      }
                      onClick={e => {
                        if (el.subMenu) dropdownToggle(e, el.title)
                        dispatch(toggleLeftmenu(false))
                        onClick()
                      }}
                    >
                      <span className="d-flex align-items-center justify-content-center">
                        {el.iconSVG}
                        {el.title && (
                          <span className="ps-2">{el.title}</span>
                        )}{" "}
                      </span>
                      {el.subMenu && <div className="arrow-down"></div>}
                    </NavLink>

                    {el.subMenu && (
                      <div
                        className={classname(
                          `dropdown-menu ${
                            el.menuType === "mega"
                              ? "mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl"
                              : ""
                          }`,
                          {
                            show:
                              showDropdown.type === el.title &&
                              showDropdown.show,
                          }
                        )}
                      >
                        <Row>
                          {el.subMenu.map((subEl, subIndex) => (
                            <Col
                              lg={el.menuType === "mega" ? 4 : "auto"}
                              key={subIndex}
                            >
                              <div>
                                <NavLink
                                  to={subEl.path}
                                  className="dropdown-item"
                                >
                                  <i className={`${subEl.icon} me-2`}></i>
                                  {subEl.title}
                                </NavLink>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(withTranslation()(Navbar))
