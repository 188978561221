import React, { useEffect, useState } from "react"
import { Col, Row, Button } from "reactstrap"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import VideoCard from "components/Common/Cards/VideoCard"
import Placeholders from "../../Home/components/Placeholders"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast, Bounce } from "react-toastify"
export default function VideosSection() {
  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()

  const handleJoinClick = () => {
    if (isAuth) {
      navigate("/dashboard") // Redirect to dashboard
    } else {
      toast.error("Please sign in to continue", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login") // Redirect to homepage
    }
  }
  const [videoData, setVideoData] = useState([])

  // Fetch the videos once and limit to 4 items

  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })
  const getFetch = async () => {
    try {
      const resp = await get(
        `${endpoints.getVideos}?page=1&filter=activeTopic&limit=4&status=active`
      )

      if (resp?.data?.success) {
        setVideoData(resp?.data?.data) // Set the video data
      }
    } catch (error) {
      console.error("Error fetching videos:", error)
    }
  }
  const handleLoadMore = () => {
    const nextPage = loadMore.currentPage + 1

    setLoadMore(prevState => ({
      ...prevState,
      currentPage: nextPage,
    }))

    getFetch(nextPage)
  }

  useEffect(() => {
    getFetch()
  }, [])

  return (
    <section id="how-dock-tok-work" className="py-2 px-0 px-lg-3 bg-light-skin">
      <div className="px-2 px-lg-5 py-5">
        <p className="ff-cooper font-size-56 fw-normal black-text-color text-uppercase text-center mb-0">
          videos posted by dockies
        </p>
        <p
          className="ff-proxima fw-bold font-size-24 gray-700 text-center pb-2 pt-0"
          style={{ fontStyle: "italic" }}
        >
          Post, Watch, and Vote.
        </p>
        <Row className="justify-content-center">
          {videoData?.length > 0 ? (
            videoData.map((card, index) => (
              <Col sm={6} lg={3} className="mb-3" key={`Dockies-${index}`}>
                <VideoCard data={card} />
              </Col>
            ))
          ) : (
            <Col>
              <Placeholders />
            </Col>
          )}
        </Row>
        <div className="text-center mb-4  mt-4">
          {" "}
          <button
            onClick={handleJoinClick}
            className="btn btn-primary-yellow font-size-14  ff-proxima  bg-yellow text-uppercase text-center  "
          >
            Join Our Community
          </button>{" "}
        </div>
      </div>
    </section>
  )
}
