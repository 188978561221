import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import pic1 from "assets/images/home/hero-event.png"
import next from "assets/images/next.svg"
import previous from "assets/images/previous.svg"
import DeleteModal from "components/Common/DeleteModal"
import axios from "axios"
import { Bounce, toast } from "react-toastify"
import Switch from "react-switch"
import { Link } from "react-router-dom"
import endpoints from "api/endpoints"
import { post, get, put } from "api/axios"
import personPlaceholder from "assets/images/personPlaceholder.png"

export default function BlockedUsersTable({
  showUnblockColumn = false,
  actions = ["view", "edit", "delete"],
  showHeading = false,
  showDeactive = false,
  showName = false,
  data,
  getdata,
}) {
  const [show, setShow] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const toggleModal = () => {
    setShow(!show)
  }
  const handleDelete = async () => {
    try {
      await axios
        .delete("")
        .then(() => setShow(false))
        .then(() => toast("User Deleted Successfully"))
    } catch (e) {}
  }

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const paginatedData = data || []
  const totalPages = Math.ceil(paginatedData.length / itemsPerPage)
  const maxVisibleButtons = 5

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handlePageClick = pageNum => {
    setCurrentPage(pageNum)
  }

  const getVisiblePageNumbers = () => {
    const startPage = Math.max(
      1,
      Math.min(
        currentPage - Math.floor(maxVisibleButtons / 2),
        totalPages - maxVisibleButtons + 1
      )
    )
    const endPage = Math.min(startPage + maxVisibleButtons - 1, totalPages)

    return { startPage, endPage }
  }

  const { startPage, endPage } = getVisiblePageNumbers()

  const currentData = paginatedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handleChange = async ({ action, id }) => {
    const resp = await put(`${endpoints.usersStats}/${action}/${id}`)
    setDisabled(true)
    if (resp?.data?.success) {
      toast.success("Successfull", {
        theme: "colored",
        transition: Bounce,
        position: "bottom-center",
      })
      getdata()
      setDisabled(false)
    } else {
      setDisabled(false)
      toast.error("Error occurred")
    }
  }

  return (
    <section id="admin-dockies" className="">
      <div>
        <div>
          <DeleteModal
            show={show}
            onCloseClick={toggleModal}
            onDeleteClick={handleDelete}
          />

          <div className="overflow-auto card shadow p-3 rounded-4 mt-2">
            <table className="table">
              <thead>
                <tr className="my-2">
                  <th scope="col" className="font-size-14 text-darkcolor">
                    #ID
                  </th>
                  <th scope="col" className="font-size-14 text-darkcolor">
                    USER
                  </th>
                  <th
                    scope="col"
                    className="font-size-14 text-darkcolor text-center"
                  >
                    REGISTERED ON
                  </th>
                  <th
                    scope="col"
                    className="font-size-14 text-darkcolor text-center"
                  >
                    REGISTRATION WITH
                  </th>
                  <th
                    scope="col"
                    className="font-size-14 text-darkcolor text-center"
                  >
                    SUBSCRIPTION STATUS
                  </th>
                  <th
                    scope="col"
                    className="font-size-14 text-darkcolor d-flex justify-content-center"
                  >
                    ACTION
                  </th>
                  {showHeading && (
                    <th
                      scope="col"
                      className="font-size-14 text-darkcolor"
                    ></th>
                  )}
                </tr>
              </thead>
              <tbody className="align-middle">
                {currentData.length > 0 ? (
                  currentData.map((user, index) => (
                    <tr key={user.id}>
                      <td className="gray-700 font-size-16">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td>
                        <div className="gap-2 d-flex align-items-center">
                          <img
                            src={
                              user?.additionalInfo?.profileImage
                                ? `${process.env.REACT_APP_S3_BUCKET}${user.additionalInfo.profileImage}`
                                : personPlaceholder
                            }
                            alt="person"
                            className="object-fit-cover rounded-circle"
                            width={"48px"}
                            height={"48px"}
                          />
                          <span className="gray-700 font-size-16 text-uppercase">
                            {user.firstName} {user.lastName}
                          </span>
                        </div>
                      </td>
                      <td className="gray-700 font-size-16 text-center">
                        {new Date(user.createdAt).toLocaleDateString()}
                      </td>
                      <td className="gray-700 font-size-16 text-center">
                        {user?.socialConnect?.google && "Google"}
                        {user?.socialConnect?.facebook && "Facebook"}
                        {user?.socialConnect?.tiktok && "TikTok"}
                        {user?.socialConnect?.instagram && "Instagram"}
                      </td>
                      <td className="gray-700 font-size-14 text-center">
                        <span
                          className={`badge p-2 text-secondary font-size-14 fw-normal rounded-pill ${
                            user.dataStatus === "active"
                              ? "light-green-bg"
                              : "primary-light-red"
                          }`}
                        >
                          {user.dataStatus}
                        </span>
                      </td>
                      {showUnblockColumn && (
                        <td className="gray-700 font-size-16">
                          <div className="d-flex align-items-center">
                            <span className="ms-2 pe-2">
                              {user.dataStatus === "block"
                                ? "Unblock"
                                : "Block"}
                            </span>
                            <Switch
                              disabled={disabled}
                              checked={user.dataStatus !== "active"}
                              onChange={() =>
                                handleChange({
                                  action:
                                    user.dataStatus === "active"
                                      ? "disable"
                                      : "enable",
                                  id: user._id,
                                })
                              }
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onColor="#d9534f"
                              offColor="#198754"
                            />
                          </div>
                        </td>
                      )}
                      <td className="align-middle">
                        <div className="d-flex justify-content-center gap-2">
                          {actions.includes("view") && (
                            <button className="btn btn-link p-0">
                              <Link to={`/userprofile/${user._id}`}>
                                <i className="fa-light fa-eye gray-700 font-size-16" />
                              </Link>
                            </button>
                          )}
                          {actions.includes("edit") && (
                            <button className="btn btn-link p-0">
                              <i className="fa-light fa-edit gray-700 font-size-16" />
                            </button>
                          )}
                          {actions.includes("delete") && (
                            <button
                              className="btn btn-link p-0"
                              onClick={toggleModal}
                            >
                              <i className="fa-light fa-trash gray-700 font-size-16" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Users Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Section */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              Showing {(currentPage - 1) * itemsPerPage + currentData.length} of{" "}
              {paginatedData.length}
            </div>

            <div className="pagination-container d-flex align-items-center">
              <button
                className="btn bg-dgray mx-1 px-3"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <i className="fa-duotone fa-solid fa-chevron-left"></i>
              </button>
              {[...Array(endPage - startPage + 1)].map((_, index) => (
                <button
                  key={startPage + index}
                  className={`btn bg-dgray mx-1 ${
                    currentPage === startPage + index
                      ? "light-green-color btn-success"
                      : ""
                  }`}
                  onClick={() => handlePageClick(startPage + index)}
                >
                  {startPage + index}
                </button>
              ))}
              <button
                className="btn bg-dgray mx-1 px-3"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <i className="fa-duotone fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
