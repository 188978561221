import React, { useState, useEffect, useCallback } from "react"

import pic1 from "assets/images/home/hero-event.png"
import DeleteModal from "components/Common/DeleteModal"
import CheckImg from "assets/images/check.svg"
import Paginations from "components/Common/Paginations/Paginations"
import ShowingCount from "components/Common/Paginations/ShowingCount"
import { Bounce, toast } from "react-toastify"
import { Link } from "react-router-dom"
import { get, del } from "api/axios"
import endpoints from "api/endpoints"
import { debounce } from "lodash"
import { Alert } from "reactstrap"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap"
import personPlaceholder from "assets/images/personPlaceholder.png"

export default function AdminDockies({ data, update }) {
  const [show, setShow] = useState(false)
  const [staff, setStaff] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [searchItem, setSearchItem] = useState("")

  const [selectedStaff, setSelectedStaff] = useState(null)

  const toggleModal = () => {
    setShow(!show)
  }
  const handleDelete = async id => {
    try {
      const resp = await del(`${endpoints.staff}/${id}`).then(() => {
        toast.success("Staff Deleted Successfully", {
          position: "bottom-center",
          theme: "colored",
          transition: Bounce,
        })
        getFetch()
        toggleModal()
      })
    } catch (e) {}
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filter, setFilter] = useState({
    action: "lastMonth",
    text: "Last Month",
  })
  const toggle = () => setDropdownOpen(prevState => !prevState)

  const [startIndex, setStartIndex] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState(0)
  const getFetch = useCallback(async () => {
    const resp = await get(
      `${endpoints.staff}?page=${startIndex}&limit=${itemsPerPage}&pagination=true&search=${searchItem}&filter=${filter?.action}`
    )

    if (resp?.data?.success) {
      setStaff(resp?.data?.data)
      setTotalPages(Math.ceil(resp?.data?.totalPages))
      setCount(Math.ceil(resp?.data?.count))
    }
  }, [startIndex, itemsPerPage, filter, searchItem])
  useEffect(() => {
    setStartIndex(currentPage)
  }, [currentPage, itemsPerPage])

  const debouncedSearch = useCallback(
    debounce(term => {
      setSearchItem(term)

      // Call your search function or API here
    }, 500), // 300ms delay
    []
  )

  const handleChange = e => {
    setSearchTerm(e.target.value)
    debouncedSearch(e.target.value)
  }
  useEffect(() => {
    getFetch()
  }, [startIndex, itemsPerPage, getFetch, filter])

  return (
    <section id="admin-dockies">
      <div>
        <DeleteModal
          show={show}
          onCloseClick={toggleModal}
          onDeleteClick={() => handleDelete(selectedStaff)}
        />
        <h2 className="d-flex flex-column flex-md-row text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase d-flex justify-content-between">
          <div class="d-flex justify-content-start align-items-center mb-3 mb-md-0">
            Staff
            <span className="  p-2   mx-3 filter-btn d-flex justify-content-between align-items-center ">
              <Input
                type="text"
                name="search"
                className="hover-input"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
              <DropdownToggle
                caret
                className="d-flex justify-content-between align-items-center filter-btn"
              >
                <span> {filter.text}</span>
                <img src={CheckImg} className="px-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "lastMonth",
                      text: "Last Month",
                    })
                    setStaff([])
                    setCurrentPage(1)
                  }}
                  value={"Last Month"}
                >
                  Last Month
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "threeMonth",
                      text: "Last Three Month",
                    })
                    setStaff([])
                    setCurrentPage(1)
                  }}
                  value={"Last Three Month"}
                >
                  Last Three Month
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "lastYear",
                      text: "Last Year",
                    })
                    setStaff([])
                    setCurrentPage(1)
                  }}
                  value={"Last Year"}
                >
                  Last Year
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    setFilter({
                      action: "active",
                      text: "Older",
                    })
                    setStaff([])
                    setCurrentPage(1)
                  }}
                  value={"Older"}
                >
                  Older
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Link to="/add-staff" className="btn btn-primary-green ms-3">
              ADD <span className="d-none d-xxl-inline-block me-1">NEW</span>
              <span className="d-none d-md-inline-block">STAFF</span>
            </Link>
          </div>
        </h2>
        <div className="overflow-auto card shadow p-3 rounded-4 mt-3">
          <table class="table">
            <thead>
              <tr className="my-2">
                <th scope="col" className="font-size-14 text-darkcolor">
                  #ID{" "}
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  USER
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  EMAIL
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  ROLE
                </th>
                <th
                  scope="col"
                  className="font-size-14 text-darkcolor text-uppercase"
                >
                  Last Updated on
                </th>
                <th scope="col" className="font-size-14 text-darkcolor">
                  ADDED ON
                </th>
                <th
                  scope="col"
                  className="font-size-14 text-darkcolor d-flex justify-content-center"
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {staff.length > 0 &&
                staff.map((user, index) => (
                  <tr key={index}>
                    <td className="gray-700 font-size-16">
                      {" "}
                      {index + 1 + (currentPage - 1) * itemsPerPage}
                    </td>
                    <td>
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          src={
                            user?.profileImage
                              ? process.env.REACT_APP_S3_BUCKET +
                                user.profileImage
                              : personPlaceholder
                          }
                          alt="person"
                          className="object-fit-cover rounded-3"
                          width={"48px"}
                          height={"48px"}
                        />
                        <span className="gray-700 font-size-16 text-uppercase">
                          {user?.firstName} {user?.lastName}
                        </span>
                      </div>
                    </td>
                    <td className="gray-700 font-size-16">{user.email}</td>
                    <td className="">
                      <span className="text-capitalize light-green-bg  badge p-2 rounded-pill font-size-14 gray-700 fw-light">
                        {user?.additionalInfo?.assignRole}
                      </span>
                    </td>
                    <td className="gray-700 font-size-14">
                      <span className="gray-700 ">
                        {new Date(user.createdAt).toLocaleDateString()}
                      </span>
                    </td>
                    <td className="gray-700 font-size-14">
                      <span className="gray-700 ">
                        {" "}
                        {new Date(user.createdAt).toLocaleDateString()}
                      </span>
                    </td>
                    <td scope="col" className="align-middle">
                      <div className="d-flex gap-2 justify-content-center">
                        <Link to={`/add-staff/${user?._id}`}>
                          <button className="btn btn-link  p-0">
                            <i className="fa-light fa-edit gray-700 font-size-16" />
                          </button>
                        </Link>

                        <button className="btn btn-link  p-0">
                          <i
                            className="fa-light fa-trash gray-700 font-size-16"
                            onClick={e => {
                              toggleModal()
                              setSelectedStaff(user?._id)
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              {staff.length === 0 && (
                <tr>
                  <td colSpan={12} className="text-center">
                    <Alert className="text-center">No Record Found!</Alert>{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <ShowingCount
            currentPage={currentPage}
            count={count}
            itemsPerPage={itemsPerPage}
          />
          <Paginations
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </section>
  )
}
