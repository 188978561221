import React from "react"
import { Container, Row, Col } from "reactstrap"
import pic from "assets/images/home/challenges.png"
import ChallengesCard from "components/Common/Cards/ChallengesCard"

const ListChallenges = () => {
  return (
    <section id="list-challenges" className="page-content">
      <Container fluid>
        <Row className="row-gap-3">
          <Col sm="6">
            <h2 className="fw-semibold font-size-26 text-uppercase">
              Challenges
            </h2>
          </Col>
          <Col sm="6">
            <div className="d-flex justify-content-end align-items-center gap-2">
              <select className="rounded-pill px-4 py-1">
                <option selected disabled>
                  Filter By Date
                </option>
              </select>
              <select className="rounded-pill px-4 py-1">
                <option selected disabled>
                  Filter By Category
                </option>
              </select>
            </div>
          </Col>
          {Array.from({ length: 12 }).map((el, index) => {
            return (
              <Col key={index} md="6" xl="4">
                <ChallengesCard data={{ pic }} showView={true} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default ListChallenges
