import React, { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import CheckImg from "assets/images/check.svg"
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap"
import pic1 from "assets/images/dockie.png"
import play from "assets/images/home/play.svg"
import { Link } from "react-router-dom"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import TimeAgo from "react-timeago"

export default function AllTopics() {
  const [topics, setTopics] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)

  // Persisting the search term and filter using localStorage
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  )
  const [searchItem, setSearchItem] = useState(
    localStorage.getItem("searchTerm") || ""
  )
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem("filter")) || {
      action: "lastMonth",
      text: "Last Month",
    }
  )

  const toggle = () => setDropdownOpen(prevState => !prevState)

  // Fetching topics data based on filter, search item, and page
  const contentHandler = useCallback(async () => {
    const resp = await get(
      `${endpoints.topic}?page=${page}&limit=12&status=true&search=${searchItem}&filter=${filter.action}`
    )
    if (resp?.data?.success) {
      setTopics(prev => [...prev, ...resp?.data?.data])
      setTotalPage(resp?.data?.totalPages)
    }
  }, [page, filter, searchItem])

  const debouncedSearch = useCallback(
    debounce(term => {
      setTopics([]) // Reset topics on new search
      setSearchItem(term)
      setPage(1) // Reset to the first page
      localStorage.setItem("searchTerm", term) // Persist search term
    }, 500),
    []
  )

  const handleTop = () => {
    window.scrollTo(0, 0)
  }

  // Handling the search term change and triggering debounced search
  const handleChange = e => {
    setSearchTerm(e.target.value)
    debouncedSearch(e.target.value)
  }

  // On filter change, reset topics, page, and save filter to localStorage
  const handleFilterChange = (action, text) => {
    setFilter({ action, text })
    setTopics([])
    setPage(1)
    localStorage.setItem("filter", JSON.stringify({ action, text }))
  }

  useEffect(() => {
    setTopics([])
    contentHandler()
  }, [page, filter, searchItem])

  return (
    <section id="user-profile" className="page-content bg-light">
      <Container fluid className="margin-video-vaults">
        <h2 className="d-flex flex-column flex-md-row text-dark font-size-26 font-weight-semibold ff-barlow text-uppercase d-flex justify-content-between">
          <div className="d-flex justify-content-start align-items-center mb-3 mb-md-0">
            header video
            <span className="p-2 mx-3 filter-btn d-flex justify-content-between align-items-center">
              <Input
                type="text"
                name="search"
                className="hover-input"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
              <DropdownToggle
                caret
                className="d-flex justify-content-between align-items-center filter-btn"
              >
                <span>{filter.text}</span>
                <img src={CheckImg} className="px-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => handleFilterChange("lastMonth", "Last Month")}
                >
                  Last Month
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    handleFilterChange("threeMonth", "Last Three Months")
                  }
                >
                  Last Three Months
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleFilterChange("lastYear", "Last Year")}
                >
                  Last Year
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleFilterChange("active", "Older")}
                >
                  Older
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleFilterChange("featured", "Featured")}
                >
                  Featured
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Link to="/add-header-video" className="btn btn-primary-green ms-3">
              ADD <span className="d-none d-md-inline-block">HEADER VIDEO</span>
            </Link>
          </div>
        </h2>
        <Row className="col-sm-center">
          {topics.length > 0 ? (
            topics.map((_, index) => (
              <Col
                key={index}
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="my-2"
                onClick={handleTop}
              >
                <div className="d-flex flex-column gap-2">
                  <Link to={`/header-video-details/${_?._id}`}>
                    <div className="video-container-blog rounded-3 d-flex position-relative">
                      {_?.videoDetail?.thumbnail?.path ? (
                        <img
                          src={`${process.env.REACT_APP_S3_BUCKET}${_?.videoDetail?.thumbnail?.path}`}
                          alt={`topic`}
                          className="rounded w-100 object-fit-cover"
                        />
                      ) : (
                        <video className="rounded w-100 h-100">
                          {/* Replace with your video source */}
                        </video>
                      )}
                      {_?.videoDetail?.video && (
                        <button className="btn position-absolute top-50 start-50 translate-middle">
                          <img src={play} alt="Play" />
                        </button>
                      )}
                    </div>
                  </Link>
                  <div className="d-flex flex-column">
                    <span className="text-uppercase fw-semibold font-size-18 ff-barlow text-darkcolor">
                      {_.title}
                    </span>
                    <span className="font-size-16 fw-normal text-secondary">
                      <TimeAgo date={_?.createdAt} />
                    </span>
                  </div>
                  <span
                    className="text-darkcolor fw-normal font-size-14"
                    dangerouslySetInnerHTML={{ __html: _.content }}
                  />
                  <Link to={`/header-video-details/${_?._id}`}>
                    <span className="cursor-pointer pre-text fw-medium">
                      VIEW DETAILS
                    </span>
                  </Link>
                </div>
              </Col>
            ))
          ) : (
            <p className="text-secondary font-size-14 text-center">
              No More Header Videos!
            </p>
          )}
          {totalPage > page && (
            <div className="text-center mt-5">
              <button
                className="text-uppercase font-size-14 font-weight-400 btn-primary-green btn"
                onClick={() => setPage(prev => prev + 1)}
              >
                Load More
              </button>
            </div>
          )}
        </Row>
      </Container>
    </section>
  )
}
