import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  FormGroup,
  Nav,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changePreloader,
  changeSidebarSize,
  changeSidebarTheme,
  changeTopbarTheme,
} from "../../store/slices/settingsSlice"

const SettingOffcanvas = ({ setShow, show, layoutType }) => {
  const dispatch = useDispatch()
  const settings = useSelector(state => state.settings)

  const handleLayoutChange = layout => {
    dispatch(changeLayout(layout))
  }

  const handleLayoutModeChange = mode => {
    dispatch(changeLayoutMode(mode))
  }

  const handleLayoutWidthChange = width => {
    dispatch(changeLayoutWidth(width))
  }

  const handleSidebarThemeChange = theme => {
    dispatch(changeSidebarTheme(theme))
  }

  const handleTopbarThemeChange = theme => {
    dispatch(changeTopbarTheme(theme))
  }

  const handleSidebarSizeChange = size => {
    dispatch(changeSidebarSize(size))
  }

  const handlePreloaderChange = value => {
    dispatch(changePreloader(value))
  }
  useEffect(() => {
    handleLayoutChange(settings?.layoutType)
    handleLayoutModeChange(settings?.layoutModeType)
    handleLayoutWidthChange(settings?.layoutWidth)
    handleTopbarThemeChange(settings?.topbarTheme)
    handleSidebarThemeChange(settings?.leftSideBarTheme)
    handleSidebarSizeChange(settings?.sidebarSizeType)
    handlePreloaderChange(settings?.isPreloader)
  }, [
    settings?.layoutType,
    settings?.layoutModeType,
    settings?.layoutWidth,
    settings?.topbarTheme,
    settings?.leftSideBarTheme,
    settings?.sidebarSizeType,
    settings?.isPreloader,
  ])
  return (
    <Offcanvas direction="end" isOpen={show}>
      <OffcanvasHeader toggle={() => setShow(false)}>Menu</OffcanvasHeader>
      <OffcanvasBody>
        <Nav className=" flex-column ">
          <div className="p-4">
            <h6 className="mb-3">Layout</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                id="layout-vertical"
                name="layout"
                value={settings.layoutType}
                checked={settings.layoutType === "vertical"}
                onChange={() => handleLayoutChange("vertical")}
              />
              <label className="form-check-label" htmlFor="layout-vertical">
                Vertical
              </label>
            </div>
            {"   "}
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                id="layout-horizontal"
                name="layout"
                value={settings.layoutType}
                checked={settings.layoutType === "horizontal"}
                onChange={() => handleLayoutChange("horizontal")}
              />
              <label className="form-check-label" htmlFor="layout-horizontal">
                Horizontal
              </label>
            </div>

            {/* ================================================================== */}
            <h6 className="mt-4 mb-3 pt-2">Layout Mode</h6>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="layout-mode"
                id="layout-mode-light"
                value={settings.layoutModeType}
                checked={settings.layoutModeType === "light"}
                onChange={() => handleLayoutModeChange("light")}
              />
              <label className="form-check-label" htmlFor="layout-mode-light">
                Light
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="layout-mode"
                id="layout-mode-dark"
                value={settings.layoutModeType}
                checked={settings.layoutModeType === "dark"}
                onChange={() => handleLayoutModeChange("dark")}
              />
              <label className="form-check-label" htmlFor="layout-mode-dark">
                Dark
              </label>
            </div>

            {/* ================================================================== */}
            <h6 className="mt-4 mb-3 pt-2">Layout Width</h6>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                id="layout-width-fuild"
                name="layout-width"
                value={settings.layoutWidth}
                checked={settings.layoutWidth === "lg"}
                onChange={() => handleLayoutWidthChange("lg")}
              />
              <label className="form-check-label" htmlFor="layout-width-fuild">
                Fluid
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input"
                id="layout-width-boxed"
                name="layout-width"
                value={settings.layoutWidth}
                checked={settings.layoutWidth === "boxed"}
                onChange={() => handleLayoutWidthChange("boxed")}
              />
              <label className="form-check-label" htmlFor="layout-width-boxed">
                Boxed
              </label>
            </div>

            {/* ================================================================== */}
            <h6 className="mt-4 mb-3 pt-2">Topbar Color</h6>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="topbar-color"
                id="topbar-color-light"
                value={settings.topbarTheme}
                checked={settings.topbarTheme === "light"}
                onChange={() => handleTopbarThemeChange("light")}
              />
              <label className="form-check-label" htmlFor="topbar-color-light">
                Light
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="topbar-color"
                id="topbar-color-dark"
                value={settings.topbarTheme}
                checked={settings.topbarTheme === "dark"}
                onChange={() => handleTopbarThemeChange("dark")}
              />
              <label className="form-check-label" htmlFor="topbar-color-dark">
                Dark
              </label>
            </div>
            <div className="form-check form-check-inline d-none">
              <input
                className="form-check-input"
                type="radio"
                name="topbar-color"
                id="topbar-color-dark"
                value={settings.topbarTheme}
                checked={settings.topbarTheme === "colored"}
                onChange={() => handleTopbarThemeChange("colored")}
              />
              <label className="form-check-label" htmlFor="topbar-color-dark">
                Colored
              </label>
            </div>

            {/* ================================================================== */}
            {layoutType === "vertical" ? (
              <React.Fragment>
                <h6 className="mt-4 mb-3 pt-2 sidebar-setting">Sidebar Size</h6>{" "}
                <div className="form-check sidebar-setting">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sidebar-size-default"
                    name="sidebar-size"
                    value={settings.sidebarSizeType}
                    checked={settings.sidebarSizeType === "default"}
                    onChange={() => handleSidebarSizeChange("default")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-default"
                  >
                    Default
                  </label>
                </div>
                {"   "}
                <div className="form-check sidebar-setting">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sidebar-size-compact"
                    name="sidebar-size"
                    value={settings.sidebarSizeType}
                    checked={settings.sidebarSizeType === "small"}
                    onChange={() => handleSidebarSizeChange("small")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-compact"
                  >
                    Compact
                  </label>
                </div>
                {"   "}
                <div className="form-check sidebar-setting">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sidebar-size-small"
                    name="sidebar-size"
                    value={settings.sidebarSizeType}
                    checked={settings.sidebarSizeType === "sm"}
                    onChange={() => handleSidebarSizeChange("sm")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-small"
                  >
                    Small (Icon View)
                  </label>
                </div>

                {/* ================================================================== */}
                <h6 className="mt-4 mb-3 pt-2 sidebar-setting">
                  Sidebar Color
                </h6>
                <div className="form-check sidebar-setting">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sidebar-color-light"
                    name="sidebar-color"
                    value={settings.leftSideBarTheme}
                    checked={settings.leftSideBarTheme === "light"}
                    onChange={() => handleSidebarThemeChange("light")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-color-light"
                  >
                    Light
                  </label>
                </div>
                {"   "}
                <div className="form-check sidebar-setting">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sidebar-color-dark"
                    name="sidebar-color"
                    value={settings.leftSideBarTheme}
                    checked={settings.leftSideBarTheme === "dark"}
                    onChange={() => handleSidebarThemeChange("dark")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-color-dark"
                  >
                    Dark
                  </label>
                </div>
                {"   "}
                <div className="form-check sidebar-setting">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="sidebar-color-colored"
                    name="sidebar-color"
                    value={settings.leftSideBarTheme}
                    checked={settings.leftSideBarTheme === "colored"}
                    onChange={() => handleSidebarThemeChange("colored")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-color-colored"
                  >
                    Colored
                  </label>
                </div>
              </React.Fragment>
            ) : null}

            <FormGroup>
              {/* ================================================================== */}
              <span className="mt-4 mb-2 d-block" id="radio-title">
                Preloader
              </span>

              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input checkbox"
                  id="checkbox_1"
                  checked={settings.isPreloader === true}
                  onChange={() => handlePreloaderChange(!settings.isPreloader)}
                />
                <label className="form-check-label" htmlFor="checkbox_1">
                  Preloader
                </label>
              </div>
            </FormGroup>
          </div>
        </Nav>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default SettingOffcanvas
