import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import pic1 from "assets/images/home/hero-event.png"
import "./check.css"
import { get } from "api/axios"
import endpoints from "api/endpoints"
import { useSelector } from "react-redux"

import { AwardDetailsModal } from "pages/Videos/VideoDetail/components/WinnerModal/AwardDetailsmodal"
const AwardReceived = ({ userId }) => {
  const [videoData, setVideoData] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [awardDetails, setAwardDetails] = useState("")
  const { currentUser } = useSelector(state => state.auth)
  const getVideoAward = async (videoId, userId) => {
    const resp = await get(`${endpoints.getVideos}/winner`)
    if (resp?.data?.success) {
    }
  }
  function toggle(details) {
    setIsOpen(!isOpen)
    setAwardDetails(details)
  }
  const getFetch = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}/winner/my/${currentUser?._id}?limit=5`
    )
    if (resp?.data?.success) {
      setVideoData(resp?.data?.data || [])
    }
  }

  useEffect(() => {
    getFetch()
    getVideoAward()
  }, [])
  const handleTop = () => {
    window.scroll(0, 0)
  }
  return (
    <Card className="rounded-4 main-container">
      <AwardDetailsModal
        isOpen={isOpen}
        toggle={toggle}
        awardDetail={awardDetails}
      />

      {videoData?.length > 0 && (
        <CardBody>
          <div className="float-end">
            <Link
              to={`/all-videos/${currentUser?._id}?myaward=true`}
              className="text-uppercase pre-text fw-medium font-size-14 ff-barlow"
              onClick={handleTop}
            >
              view all
            </Link>
          </div>
          <h4 className="fw-medium font-size-26 ff-barlow text-uppercase mb-4">
            award received
          </h4>
          <div className="invisible-scrollbar" autoHide={false}>
            {/* Render the first item separately */}
            {videoData?.length > 0 && (
              <Row className="mb-4 align-items-center">
                <Col md={4} sm={6} lg={5} xl={5} xxl={4}>
                  <img
                    src={
                      videoData[0]?.videoId?.videoDetail?.thumbnail?.path
                        ? `${process.env.REACT_APP_S3_BUCKET}${videoData[0]?.videoId?.videoDetail?.thumbnail?.path}`
                        : pic1
                    }
                    className="object-fit-cover rounded-4 w-100"
                    alt="..."
                    width="180"
                    height="250"
                  />
                </Col>
                <Col md={6} sm={6} lg={7} xl={5} xxl={6}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h2 className="fw-medium  font-size-26 text-uppercase text-darkcolor">
                      {videoData[0]?.videoId?.title}
                    </h2>
                    <div className="d-flex flex-column font-size-14 ">
                      <span className="fw-normal text-darkcolor text-uppercase">
                        {videoData[0]?.awardType}
                      </span>
                      <span className="text-secondary">
                        {" "}
                        {videoData[0]?.award}
                      </span>
                      <div className="d-flex gap-3 d-md-none d-lg-flex d-xl-none mt-2">
                        <Link
                          className="text-muted"
                          to={``}
                          onClick={() => toggle(videoData[0])}
                        >
                          <i
                            className="fa-light fa-eye font-size-18"
                            id="viewtooltip-mobile-0"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="viewtooltip-mobile-0"
                          >
                            View
                          </UncontrolledTooltip>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={2}
                  className="d-none d-md-flex d-lg-none justify-content-end mt-3"
                >
                  <div className="d-flex gap-3">
                    <Link
                      className="text-muted"
                      to={``}
                      onClick={() => toggle(videoData[0])}
                    >
                      <i
                        className="fa-light fa-eye font-size-18"
                        id="viewtooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="viewtooltip-0"
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </Col>
                <Col
                  xl={2}
                  xxl={2}
                  className="d-none d-xl-flex d-lg-none justify-content-end mt-3"
                >
                  <div className="d-flex gap-3">
                    <Link
                      className="text-muted"
                      to={``}
                      onClick={() => toggle(videoData[0])}
                    >
                      <i
                        className="fa-light fa-eye font-size-18"
                        id="viewtooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="viewtooltip-0"
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}

            {/* Render the rest of the items */}
            {videoData?.slice(1)?.map((el, index) => (
              <Row key={index + 1} className="mb-4 align-items-center">
                <Col md={2} sm={2} lg={3} xl={2}>
                  <img
                    src={
                      el?.videoId?.videoDetail?.thumbnail?.path
                        ? `${process.env.REACT_APP_S3_BUCKET}${el?.videoId?.videoDetail?.thumbnail?.path}`
                        : pic1
                    }
                    className="object-fit-cover rounded-4"
                    alt="..."
                    width="60"
                    height="100"
                  />
                </Col>
                <Col md={8} sm={10} lg={6} xl={8}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 className="responsive-font fw-medium ff-barlow font-size-15 text-secondary text-uppercase">
                      {el?.videoId?.title}
                    </h4>
                    <div className="d-flex flex-column fw-normal font-size-14 ff-barlow">
                      <span className="fw-normal text-primary text-uppercase">
                        {el?.awardType}
                      </span>
                      <span className="text-secondary"> {el?.award}</span>
                      <div className="d-flex gap-3 d-md-none  mt-2">
                        <Link
                          className="text-muted"
                          to={``}
                          onClick={() => toggle(el)}
                        >
                          <i
                            className="fa-light fa-eye font-size-18"
                            id={`viewtooltip-mobile-${index + 1}`}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`viewtooltip-mobile-${index + 1}`}
                          >
                            View
                          </UncontrolledTooltip>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={2}
                  lg={3}
                  xl={2}
                  className="d-none d-md-flex justify-content-end"
                >
                  <div className="d-flex gap-3">
                    <Link
                      className="text-muted"
                      to={``}
                      onClick={() => toggle(el)}
                    >
                      <i
                        className="fa-light fa-eye font-size-18"
                        id={`viewtooltip-${index + 1}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`viewtooltip-${index + 1}`}
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </Col>
              </Row>
            ))}
            {videoData?.length <= 0 && (
              <Alert className="text-center">No Record Found!</Alert>
            )}
          </div>
        </CardBody>
      )}
    </Card>
  )
}

export default AwardReceived
