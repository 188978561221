import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import pic1 from "assets/images/delete.svg"
import upload from "assets/images/upload.svg"

import { useFormik } from "formik"
import TagsInput from "react-tagsinput"
import "react-tagsinput/react-tagsinput.css"
import * as Yup from "yup"
import { post } from "api/axios"
import endpoints from "../../../api/endpoints"
import { Bounce, toast } from "react-toastify"
import ShowMoreText from "components/Common/ShowMoreText"

const UploadVideoModal = ({
  isOpen,
  toggle,
  getVideo = () => {},
  topicId,
  refresh = false,
  rules = "",
}) => {
  const [videoUrl, setVideoUrl] = useState(null)
  const [showThankYouModal, setShowThankYouModal] = useState(false)
  const [sRules, setSRules] = useState(rules)

  // State for holding the uploaded data
  const [uploadedData, setUploadedData] = useState({
    title: "",
    description: "",
    videoUrl: null,
  })

  const handleVideoRemove = () => {
    setVideoUrl(null)
  }

  const handleUpload = () => {
    // Handle the upload logic here
    setShowThankYouModal(true)
    toggle() // Close the current modal
  }

  const handleCloseThankYouModal = () => {
    setShowThankYouModal(false)
    getVideo(!refresh)
  }

  const handleUploadAnother = () => {
    setShowThankYouModal(false)
    toggle() // Open the first modal
  }

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      file: null,
      topicId: topicId,
      tags: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Video Title"),
      description: Yup.string()
        .required("Please Enter Description")
        .max(500, "Not more than 500 characters"),
      file: Yup.mixed().required("Video is Required"),
      tags: Yup.array()
        .of(Yup.string().required("Tag cannot be empty"))
        .min(1, "At least one Tag is Required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      const formData = new FormData()
      formData.append("title", values.title)
      formData.append("description", values.description)
      formData.append("topicId", values.topicId)
      formData.append("tags", values.tags)
      if (values.file) {
        formData.append("file", values.file)
      }
      const response = await post(endpoints.getVideos, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (response.status === 200) {
        // Save the uploaded data in the state
        setUploadedData({
          title: values.title,
          description: values.description,
          videoUrl: URL.createObjectURL(values.file),
        })

        handleVideoRemove()
        setShowThankYouModal(true)
        toggle()

        validation.resetForm()
      } else {
        setSubmitting(false)
      }
    },
  })
  const handleDragOver = e => {
    e.preventDefault()
  }

  // Handle the file drop
  const handleDrop = React.useCallback(e => {
    e.preventDefault()

    const file = e.dataTransfer.files[0]
    if (file && file.type.startsWith("video/")) {
      const videoUrl = URL.createObjectURL(file)
      setVideoUrl(videoUrl)
      validation.values.file = file
    }
    if (file && !file.type.startsWith("video/")) {
      toast.error("Upload a Video file", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
    }
  }, [])

  const handleVideoUpload = e => {
    const file = e.target.files[0]
    validation.setFieldValue("file", file)
    if (file) {
      setVideoUrl(URL.createObjectURL(file))
    }
  }

  useEffect(() => {
    setSRules(rules)
  }, [rules])

  return (
    <div className="">
      <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <ModalBody>
            <button
              className="position-absolute top-0 end-0 btn btn-link text-darkcolor mt-2 text-uppercase font-size-14 fw-normal ff-barlow"
              onClick={toggle}
            >
              — Close
            </button>
            <div className="px-4 py-4">
              <h2 className="fw-medium ff-barlow font-size-26 text-darkcolor text-uppercase pt-2 pb-2">
                Upload Your Video
              </h2>

              <FormGroup>
                <Label
                  for="videoUpload"
                  className={`upload-label d-block w-100 text-center ${
                    videoUrl ? "d-none" : ""
                  }`}
                >
                  <div
                    className=" text-align-center"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <img src={upload} alt="." />
                    <h3 className="font-size-18 fw-medium ff-barlow text-darkcolor text-uppercase">
                      Upload or Drop Video Here
                    </h3>
                    <div className="fw-normal font-size-16 text-secondary mt-5">
                      MP4, AVI & H.264 are supported
                      <br />
                      Video should be high quality
                      <br />
                      Not more than 150 MB
                    </div>
                  </div>
                </Label>
                <Input
                  type="file"
                  name="file"
                  id="videoUpload"
                  accept="video/*"
                  className="d-none"
                  onChange={handleVideoUpload}
                />
                {videoUrl && (
                  <div className="d-flex align-items-start position-relative">
                    <video className="uploaded-video rounded-4" controls>
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <Button
                      className="delete-button position-absolute rounded-circle bg-white"
                      onClick={handleVideoRemove}
                    >
                      <img src={pic1} alt="" />
                    </Button>
                  </div>
                )}
              </FormGroup>
              {validation.touched.file && validation.errors.file ? (
                <div className="text-danger">{validation.errors.file}</div>
              ) : null}

              <div className="rules-section">
                <h2 className="fw-medium ff-barlow font-size-26 text-darkcolor text-uppercase mt-5">
                  Rules
                </h2>
                <p
                  className="fw-normal font-size-16 text-secondary"
                  dangerouslySetInnerHTML={{ __html: sRules }}
                ></p>
              </div>
              <FormGroup>
                <h2 className="fw-medium ff-barlow font-size-26 text-darkcolor text-uppercase">
                  Add your description
                </h2>
                <div>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Enter Video Title"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                    className="mb-2"
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
                <div>
                  <TagsInput
                    className={"form-control mb-2"}
                    value={validation.values.tags || []}
                    onChange={e => {
                      validation.setFieldValue("tags", e)
                    }}
                    addOnBlur
                    addKeys={[13, 32]} // 13 is Enter, 32 is Space
                  />
                  {validation.touched.tags && validation.errors.tags ? (
                    <div className="text-danger">{validation.errors.tags}</div>
                  ) : null}
                </div>
                <Input
                  type="textarea"
                  name="description"
                  id="videoDescription"
                  placeholder="Not more than 500 characters"
                  style={{ minHeight: "200px" }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-primary-green"
              type="submit"
              disabled={validation.isSubmitting}
            >
              Upload
            </Button>
            <Button color="btn btn-primary-green-outline" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={showThankYouModal}
        toggle={handleCloseThankYouModal}
        size="lg"
        centered
      >
        <ModalBody className="">
          <button
            className="position-absolute top-0 end-0 btn btn-link  text-uppercase text-decoration-none"
            onClick={handleCloseThankYouModal}
          >
            — Close
          </button>
          <div className="px-0 px-sm-4 py-4">
            <h2 className="fw-medium ff-barlow font-size-26 text-darkcolor text-uppercase">
              Thank You
            </h2>
            <p className="fw-normal font-size-16 text-secondary">
              Your video has been successfully uploaded! It will be reviewed and
              published shortly. You’ll be notified once the process is
              complete. While you wait, explore the Dock Tok world and get ready
              to compete in our monthly joke competitions for a chance to win
              awesome prizes. Don’t forget—winners are featured on{" "}
              <i>@loganlisle</i>
            </p>
            <div className="d-flex flex-column flex-md-row justify-content-center px-0 px-md-5 gap-0 gap-md-2">
              <div className="flex-shrink-0">
                {uploadedData.videoUrl && (
                  <video
                    className="rounded-4 object-fit-cover"
                    controls
                    style={{ maxHeight: "450px", maxWidth: "450px" }}
                  >
                    <source src={uploadedData.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              <div className="flex-grow-1 text-start d-flex align-items-center">
                <div className="ps-2">
                  <h2 className="fw-medium ff-barlow font-size-22 text-uppercase pre-text">
                    <ShowMoreText text={uploadedData.title} lineBreak={true} />
                  </h2>
                  <p className="fw-normal font-size-16 text-secondary">
                    <ShowMoreText
                      text={uploadedData.description}
                      lineBreak={true}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              className="btn btn-primary-green"
              onClick={handleUploadAnother}
            >
              Upload Another
            </Button>{" "}
            <Button
              color="btn btn-primary-green-outline"
              onClick={handleCloseThankYouModal}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default UploadVideoModal
