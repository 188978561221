import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import upload from "assets/images/upload-square.png"
import rec3 from "../../../assets/images/rec1.png"
import rec2 from "../../../assets/images/rec2.png"
import rec1 from "../../../assets/images/rec3.png"
import "./slidernew.css"
import touch from "assets/images/touch.png"
import award from "assets/images/award-01.png"
import ThedockiesImg from "assets/images/dockie-img-end.png"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { toast, Bounce } from "react-toastify"

function CallToAction() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const getLastDayAndThreeDaysBefore = () => {
    const date = new Date()
    const currentMonth = date.getMonth()
    const currentYear = date.getFullYear()

    const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate()
    const threeDaysBefore = lastDay - 3

    return {
      lastDay,
      threeDaysBefore,
      monthName: monthNames[currentMonth],
    }
  }

  const { lastDay, threeDaysBefore, monthName } = getLastDayAndThreeDaysBefore()

  const { isAuth } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const topic = localStorage.getItem("topic")

  const handleSubmitClick = () => {
    if (isAuth) {
      navigate(`/challenge-detail/${topic}`)
    } else {
      toast.error("Please Sign in to Submit Your Video.", {
        position: "bottom-center",
        theme: "colored",
        transition: Bounce,
      })
      navigate("/login")
    }
  }

  const cardData = [
    {
      img: upload,
      title: "Submit",
      description: "Videos By",
      date: `${monthName} ${threeDaysBefore}`,
    },
    {
      img: touch,
      title: "Vote",
      description: "Until",
      date: `${monthName} ${threeDaysBefore}`,
    },
    {
      img: award,
      title: "Winner",
      description: "Announced",
      date: `${monthName} ${lastDay}`,
    },
  ]

  return (
    <section
      id="submit-your-video1"
      className="bg-primary px-3 py-5 mt-5 position-relative"
    >
      <div className="px-0 px-md-5">
        <Row className="align-items-center pb-5">
          <Col md="8" className="text-md-left mb-4 mb-md-0">
            <h2 className="ff-cooper font-size-56 fw-normal black-text-color">
              YOU COULD BE FEATURED <br />
              NEXT MONTH!
            </h2>
            <Row className="mt-4 pb-3">
              {cardData.map((card, index) => (
                <Col
                  xs="6"
                  md="4"
                  className={`text-center ${
                    index === 2 ? "center-on-mobile" : ""
                  }`}
                  key={index}
                >
                  <div className="d-flex flex-column flex-sm-row flex-md-column flex-xl-row align-items-center">
                    <Card className="info-card rounded-4">
                      <CardBody className="d-flex justify-content-center align-items-center">
                        <img
                          src={card.img}
                          alt={card.title}
                          height={45}
                          width={45}
                        />
                      </CardBody>
                    </Card>
                    <p
                      className="text-center text-sm-start ps-2 ff-proxima gray-700 fw-normal font-size-18"
                      style={{ lineHeight: "29px" }}
                    >
                      {card.title}
                      <br /> {card.description}
                      <br />
                      <strong className="ff-proxima black-text-color fw-bold font-size-18 pt-2">
                        {card.date}
                      </strong>
                    </p>
                  </div>
                </Col>
              ))}
            </Row>

            <div className="d-flex justify-content-center align-items-center justify-content-md-start">
              {topic && (
                <button
                  onClick={handleSubmitClick}
                  className="btn btn-primary-yellow ff-proxima font-size-14 fw-bolder text-uppercase"
                >
                  submit{" "}
                  <span className="d-none d-md-inline-block ff-proxima font-size-14 fw-bold">
                    your{" "}
                  </span>{" "}
                  <span className="d-none- d-md-inline-block ff-proxima font-size-14 fw-bold">
                    video
                  </span>{" "}
                  <span className="d-none- d-md-inline-block ff-proxima font-size-14 fw-bold">
                    now
                  </span>{" "}
                </button>
              )}
            </div>
          </Col>
          <Col md="4" className="text-center ">
            {/* <div className="position-relative d-flex justify-content-center gap-1">
              <img
                src={slide}
                alt="Excited Person"
                className="stacked-image position-absolute"
                style={{ zIndex: "3" }}
              />
              <img
                src={slide1}
                alt="Excited Person"
                className="stacked-image"
                style={{ zIndex: "2", marginRight: "-1px" }}
              />
              <img
                src={slide2}
                alt="Excited Person"
                className="stacked-image"
                style={{ zIndex: "1" }}
              />
            </div> */}
            <div className="slider-container  pt-5 mt-md-4  position-relative d-none d-xl-flex">
              <div className="slide">
                <img
                  src={rec1}
                  alt="Image 1"
                  className="img-fluid object-fit-cover"
                />
              </div>
              <div className="slide">
                <img
                  src={rec2}
                  alt="Image 2"
                  className="img-fluid object-fit-cover"
                />
              </div>
              <div className="slide">
                <img
                  src={rec3}
                  alt="Image 3"
                  className="img-fluid object-fit-cover"
                />
              </div>
            </div>
            <div className="d-flex d-xl-none justify-content-center align-items-center ">
              <img
                src={rec2}
                alt="Image 1"
                className="img-fluid object-fit-cover "
              />
            </div>
          </Col>
        </Row>
        <div className="mt-md-5 pt-md-5">
          {" "}
          <img
            src={ThedockiesImg}
            alt="Footer Image"
            className="img-fluid floatingBottomImg1 px-2 px-md-0"
          />
        </div>
      </div>
    </section>
  )
}

export default CallToAction
