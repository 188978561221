import React, { useState } from "react"
import { Container, Row, Col, CardBody, Card, Alert } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { useEffect } from "react"
import { get } from "../../api/axios"
import endpoints from "../../api/endpoints"
import { useSelector } from "react-redux"
import SubscriptionModal from "../../components/Common/SubscriptionModal"

import EventCard from "../../components/Common/Cards/EventCard"
import VideoCard from "components/Common/Cards/VideoCard"
import ChallengesCard from "../../components/Common/Cards/ChallengesCard"
import StatsCard from "../../components/Common/Cards/StatsCard"
import MySubmittedVideos from "./components/MySubmittedVideos"
import AwardReceived from "./components/AwardReceived"
import Hero from "pages/Home/components/Hero"

const Dashboard = () => {
  document.title = " Dashboard | Dock Tok - Admin Dashboard"
  const [videoData, setVideoData] = useState([])
  const [eventData, setEventData] = useState([])
  const [loadMore, setLoadMore] = useState({
    currentPage: 1,
    lastPage: 1,
  })
  const location = useLocation()
  let queryString = location.search
  const params = new URLSearchParams(queryString)
  const { currentUser } = useSelector(state => state.auth)

  const getVideos = async () => {
    const resp = await get(
      `${endpoints.getVideos}?page=1&limit=8&filter=activeTopic&status=active`
    )

    if (resp?.data?.success) {
      setVideoData(resp?.data?.data)
    }
  }
  const getEvents = async () => {
    const resp = await get(
      `${endpoints.getTopics}?page=1&limit=2&featured=true&event=true&winner=false`
    )
    if (resp?.data?.success) {
      setEventData(resp?.data?.data)
    }
  }
  useEffect(() => {
    getVideos()
    getEvents()
  }, [])

  const [userStats, setUserStats] = useState({})
  const getUserStats = async (nextPage = 1) => {
    const resp = await get(`${endpoints.userDetail}/stat/${currentUser?._id}`)
    if (resp?.data?.success) {
      setUserStats(resp?.data)
    }
  }
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  useEffect(() => {
    getUserStats()
    params.get("sociallogin") == "true" && setSubscriptionModal(true)
    params.set("sociallogin", "false")
    queryString = params.toString()
    window.history.pushState({}, "", queryString)
  }, [])
  return (
    <React.Fragment>
      {/* <SubscriptionModal
        show={subscriptionModal}
        onCloseClick={e => {
          setSubscriptionModal(false)
        }}
      /> */}
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="Dock Tok" breadcrumbItem="Dashboard" /> */}
          <Row>
            <Col sm="12">
              <h2 className="fw-medium font-size-26 text-darkcolor text-uppercase mb-3">
                Dashboard
              </h2>
            </Col>
            <Col md="6">
              <StatsCard type={"videos"} count={userStats?.videoCount || 0} />
            </Col>
            <Col md="6">
              <StatsCard type={"awards"} count={userStats?.awardCount || 0} />
            </Col>
          </Row>

          <Card className="rounded-4 p-0">
            <CardBody className="p-0">
              <Row className="px-md-5 px-lg-3 px-xxl-4   px-0 py-0">
                <Hero
                  inSection={false}
                  showHeaderText={false}
                  showButton={true}
                  showChallangeText={false}
                  className="  ps-lg-5 rounded-4 "
                  videoClassName=" m-0 top-0 mt-2 mb-0"
                  sketchClassName=" m-0 top-0 mt-0 mb-0"
                  dashboard={true}
                />
              </Row>
            </CardBody>
          </Card>

          <Row className="mt-4 row-gap-3">
            <Col md="12" lg="6">
              <MySubmittedVideos />
            </Col>
            <Col md="12" lg="6">
              <AwardReceived />
            </Col>
          </Row>
          <Row className="mt-3 row-gap-3">
            <Col sm="6">
              <h2 className="fw-medium ff-barlow font-size-26 text-uppercase text-darkcolor">
                recently posted videos
              </h2>
            </Col>
            <Col sm="6">
              <div className="d-flex justify-content-end align-items-center ">
                <Link to="/videovaults">
                  <button className="btn ff-balow fw-semibold font-size-14 pre-text text-uppercase">
                    View all videos
                  </button>
                </Link>
              </div>
            </Col>

            {videoData.length > 0 ? (
              videoData.map((card, index) => (
                <Col sm={6} lg={4} xxl={3} className="mb-3" key={index}>
                  <VideoCard data={card} />
                </Col>
              ))
            ) : (
              <Alert className="text-center">No Recent Videos Found</Alert>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
