// export default MySubmittedVideos
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import TimeAgo from "react-timeago"
import pic1 from "assets/images/home/hero-event.png"
import "./check.css"
import DeleteModal from "components/Common/DeleteModal"
import { post, get, put, del } from "api/axios"
import endpoints from "api/endpoints"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"

const MySubmittedVideos = ({ userId }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [videoData, setVideoData] = useState([])
  const [selectedVideoId, setSelectedVideoId] = useState(null)

  const { currentUser } = useSelector(state => state.auth)

  const onClickDelete = user => {
    setDeleteModal(true)
  }
  const handleDeleteUser = () => {
    setDeleteModal(false)
  }
  const data = Array.from({ length: 5 })
  const [show, setShow] = React.useState(false)
  const toggleModal = () => {
    setShow(!show)
  }
  const handleDelete = async () => {
    try {
      const resp = await del(`${endpoints.getVideos}/${selectedVideoId}`)
      if (resp?.data?.success) {
        setShow(false)
        getFetch()
        toast("Video Deleted Successfully")
      } else {
        setShow(false)
        setDeleteModal(false)
        toast.error(resp?.data?.message)
      }
    } catch (e) {
      setShow(false)
      toast.error(e.message)
    }
  }
  const getFetch = async (nextPage = 1) => {
    const resp = await get(
      `${endpoints.getVideos}?page=${nextPage}&limit=5&dataId=${currentUser?._id}&filter=user`
    )
    if (resp?.data?.success) {
      setVideoData(resp?.data?.data || [])
    }
  }
  const handleTop = () => {
    window.scroll(0, 0)
  }
  useEffect(() => {
    getFetch()
  }, [])

  return (
    <Card className="rounded-4">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      {videoData.length > 0 && (
        <CardBody>
          <div className="float-end">
            {videoData.length > 0 && (
              <Link
                to={`/all-videos/${currentUser?._id}?myvideos=true`}
                className="text-uppercase pre-text fw-medium font-size-14 ff-barlow"
                onClick={handleTop}
              >
                view all
              </Link>
            )}
          </div>
          <h2 className=" mb-4 fw-medium font-size-26 ff-barlow text-uppercase">
            My Submitted Videos
          </h2>
          <div className="invisible-scrollbar">
            {/* Render the first item separately */}
            {videoData.length > 0 && (
              <Row className="mb-4 align-items-center">
                <Col md={4} sm={6} lg={5} xl={5} xxl={4}>
                  <img
                    src={
                      videoData[0]?.thumbnail
                        ? `${process.env.REACT_APP_S3_BUCKET}${videoData[0]?.thumbnail}`
                        : pic1
                    }
                    className="object-fit-cover rounded-4 w-100"
                    alt="..."
                    width="180"
                    height="250"
                  />
                </Col>
                <Col md={6} sm={6} lg={7} xl={5} xxl={6}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h2
                      className=" fw-medium ff-barlow font-size-26 text-uppercase text-darkcolor"
                      style={{ fontSize: "clamp(18px, 5vw, 20px)" }}
                    >
                      {videoData[0]?.title}
                    </h2>
                    <div className="d-flex flex-column font-size-14 fw-normal ff-barlow">
                      <span className=" text-darkcolor">Submit on:</span>
                      <span className="text-secondary">
                        <TimeAgo date={videoData[0]?.updatedAt} />
                      </span>
                      <div className="d-flex gap-3 d-md-none d-lg-flex d-xl-none mt-2">
                        <Link
                          className="text-muted"
                          to={`/videodetail/${videoData[0]?._id}`}
                        >
                          <i
                            className="fa-light fa-eye font-size-18"
                            id="viewtooltip-mobile-0"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="viewtooltip-mobile-0"
                          >
                            View
                          </UncontrolledTooltip>
                        </Link>
                        <Link
                          to={`/edit-video/${videoData[0]?._id}`}
                          className="text-muted"
                        >
                          <i
                            className="fa-light fa-pen-to-square font-size-18"
                            id="edittooltip-mobile-0"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="edittooltip-mobile-0"
                          >
                            Edit
                          </UncontrolledTooltip>
                        </Link>
                        <Link
                          to="#"
                          className="text-muted"
                          onClick={() => {
                            setSelectedVideoId(videoData[0]?._id)
                            setShow(true)
                          }}
                        >
                          <i
                            className="fa-light fa-trash font-size-18"
                            id="deletetooltip-mobile-0"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="deletetooltip-mobile-0"
                          >
                            Delete
                          </UncontrolledTooltip>
                          <DeleteModal
                            show={show}
                            onCloseClick={toggleModal}
                            onDeleteClick={handleDelete}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={2}
                  className="d-none d-md-flex d-lg-none justify-content-end mt-3"
                >
                  <div className="d-flex gap-3">
                    <Link
                      className="text-muted"
                      to={`/videodetail/${videoData[0]?._id}`}
                    >
                      <i
                        className="fa-light fa-eye font-size-18"
                        id="viewtooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="viewtooltip-0"
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                    <Link
                      to={`/edit-video/${videoData[0]?._id}`}
                      className="text-muted"
                    >
                      <i
                        className="fa-light fa-pen-to-square font-size-18"
                        id="edittooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="edittooltip-0"
                      >
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                    <Link
                      to="#"
                      className="text-muted"
                      onClick={() => {
                        setSelectedVideoId(videoData[0]?._id)
                        setShow(true)
                      }}
                    >
                      <i
                        className="fa-light fa-trash font-size-18"
                        id="deletetooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip-0"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </Col>
                <Col
                  xl={2}
                  xxl={2}
                  className="d-none d-xl-flex d-lg-none justify-content-end mt-3"
                >
                  <div className="d-flex gap-3">
                    <Link
                      className="text-muted"
                      to={`/videodetail/${videoData[0]?._id}`}
                    >
                      <i
                        className="fa-light fa-eye font-size-18"
                        id="viewtooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="viewtooltip-0"
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                    <Link
                      to={`/edit-video/${videoData[0]?._id}`}
                      className="text-muted"
                    >
                      <i
                        className="fa-light fa-pen-to-square font-size-18"
                        id="edittooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="edittooltip-0"
                      >
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                    <Link
                      to="#"
                      className="text-muted"
                      onClick={() => {
                        setSelectedVideoId(videoData[0]?._id)
                        setShow(true)
                      }}
                    >
                      <i
                        className="fa-light fa-trash font-size-18"
                        id="deletetooltip-0"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip-0"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}

            {/* Render the rest of the items */}
            {videoData.slice(1).map((el, index) => (
              <Row key={index + 1} className="mb-4 align-items-center">
                <Col md={2} sm={2} lg={3} xl={2}>
                  <img
                    src={
                      el?.thumbnail
                        ? `${process.env.REACT_APP_S3_BUCKET}${el?.thumbnail}`
                        : pic1
                    }
                    className="object-fit-cover rounded-4"
                    alt="..."
                    width="60"
                    height="100"
                  />
                </Col>
                <Col md={8} sm={10} lg={6} xl={8}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <h4 className="responsive-font fw-medium ff-barlow font-size-15 text-secondary text-uppercase">
                      {el?.title}
                    </h4>
                    <div className="d-flex flex-column fw-normal font-size-14 ff-barlow">
                      <span className="fw-normal text-darkcolor">
                        Submit on:
                      </span>
                      <span className="text-secondary">
                        <TimeAgo date={el?.updatedAt} />
                      </span>
                      <div className="d-flex gap-3 d-md-none  mt-2">
                        <Link
                          className="text-muted"
                          to={`/videodetail/${el?._id}`}
                        >
                          <i
                            className="fa-light fa-eye font-size-18"
                            id={`viewtooltip-mobile-${index + 1}`}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`viewtooltip-mobile-${index + 1}`}
                          >
                            View
                          </UncontrolledTooltip>
                        </Link>
                        <Link
                          to={`/edit-video/${el?._id}`}
                          className="text-muted"
                        >
                          <i
                            className="fa-light fa-pen-to-square font-size-18"
                            id={`edittooltip-mobile-${index + 1}`}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`edittooltip-mobile-${index + 1}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                        </Link>
                        <Link
                          to="#"
                          className="text-muted"
                          onClick={() => {
                            setSelectedVideoId(el?._id)
                            setShow(true)
                          }}
                        >
                          <i
                            className="fa-light fa-trash font-size-18"
                            id={`deletetooltip-mobile-${index + 1}`}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`deletetooltip-mobile-${index + 1}`}
                          >
                            Delete
                          </UncontrolledTooltip>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={2}
                  lg={3}
                  xl={2}
                  className="d-none d-md-flex justify-content-end"
                >
                  <div className="d-flex gap-3">
                    <Link className="text-muted" to={`/videodetail/${el?._id}`}>
                      <i
                        className="fa-light fa-eye font-size-18"
                        id={`viewtooltip-${index + 1}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`viewtooltip-${index + 1}`}
                      >
                        View
                      </UncontrolledTooltip>
                    </Link>
                    <Link to={`/edit-video/${el?._id}`} className="text-muted">
                      <i
                        className="fa-light fa-pen-to-square font-size-18"
                        id={`edittooltip-${index + 1}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`edittooltip-${index + 1}`}
                      >
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                    <Link
                      to="#"
                      className="text-muted"
                      onClick={() => {
                        setSelectedVideoId(el?._id)
                        setShow(true)
                      }}
                    >
                      <i
                        className="fa-light fa-trash font-size-18"
                        id={`deletetooltip-${index + 1}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`deletetooltip-${index + 1}`}
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </Col>
              </Row>
            ))}
            {videoData.length <= 0 && (
              <Alert className="text-center">No Record Found</Alert>
            )}
          </div>
        </CardBody>
      )}
    </Card>
  )
}

export default MySubmittedVideos
